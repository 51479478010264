<app-page-layout [title]="'PASSWORD_RESET.RESET_PASSWORD'" [displayCustomerService]="false">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title class="center">{{'PASSWORD_RESET.RESET_PASSWORD' | translate}}
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="row">
                            <ng-container *ngIf="isLoading; else display">
                                <app-loading></app-loading>
                            </ng-container>
                            <ng-template #display>
                                <ng-container *ngIf="hasBeenReset; else displayForm">
                                    <ng-container *ngTemplateOutlet="successMessage"></ng-container>
                                </ng-container>
                                <ng-template #displayForm>
                                    <ng-container *ngTemplateOutlet="errorMessage"></ng-container>
                                    <form [formGroup]="form">
                                        <mat-form-field class="col-md-12">
                                            <mat-label>{{'COMMON.USER_NAME_PLACEHOLDER' | translate}}</mat-label>
                                            <input matInput name="email" type="text" formControlName="loginID"
                                                class="full-width"
                                                [placeholder]="'COMMON.USER_NAME_PLACEHOLDER' | translate">
                                            <mat-error>
                                                {{'VALIDATION.REQUIRED'|translate}}
                                            </mat-error>
                                        </mat-form-field>
                                        <div class="col-md-12">
                                            <button mat-raised-button color="primary" class="full-width"
                                                [disabled]="isLoading" (click)="submit()">
                                                {{'COMMON.CONFIRM' | translate}}
                                            </button>
                                        </div>
                                        <div class="col-md-12 top15">
                                            <button mat-button color="primary" class="full-width"
                                                [routerLink]="['/login']">
                                                {{'COMMON.BACK' | translate}}
                                            </button>
                                        </div>
                                    </form>
                                </ng-template>
                            </ng-template>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</app-page-layout>
<ng-template #errorMessage>
    <div class="col-md-12" *ngIf="errorMatcher.isError()">
        <div class="alert custom-danger text-left" role="alert">
            <p class="error">
                <mat-icon class="danger">error</mat-icon>
                <ng-container *ngIf="!errorMatcher.isMissingVerification();else link">
                    <span *ngFor="let error of errorMatcher.logError">{{error.message | translate}}</span>
                </ng-container>
                <ng-template #link>
                    <a (click)="verifyAccount()"
                        *ngFor="let error of errorMatcher.logError">{{error.message | translate:{arg:error.arg} }}</a>
                </ng-template>
            </p>
        </div>
    </div>
</ng-template>
<ng-template #successMessage>
    <div class="col-md-12">
        <div class="alert alert-success" role="alert">
            <p>{{ 'PASSWORD_RESET.SUCCESS_TEXT' |translate }}</p>
            <span>{{'PASSWORD_RESET.SUCCESS_TITLE' |translate }}</span>
            <p> {{'COMMON.GO_TO_LOGIN' | translate }}</p>
        </div>
    </div>
</ng-template>
