import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { LoginService } from 'app/services/data/login.service';

@Injectable()
export class AuthGuardMailService implements CanLoad {

    constructor(
        private loginService: LoginService,
        private router: Router,
    ) { }

    canLoad(): boolean {
        if (this.loginService.isConnected()) {
            const params = this.router.getCurrentNavigation().extractedUrl.queryParams;
            const userID = params['helpedID'];
            if (userID) {
                this.router.navigateByUrl(`/content/dashboard?userid=${userID}`);
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
}
/**
 * AuthGuard processing order :
 * 1) canDeactivate
 * 2) canLoad - lazyLoaded modules
 * 3) canActivateChild
 * 4) canActive
 * 5) resolve
 */
