import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

enum ErrorMessages {
    LOGIN_BLOCKED,
    REMAINING_ATTEMPT,
    LOGIN_FAILURE = 'Bad login',
    MISSING_VERIFICATION = 'Non verified user',
    OTP_ERROR = 'otp: incorrect code',
    OTP_MUST_BE_SUPPLIED = 'otp: missing argument',
    PASSWORD_TOO_OLD = 'Password too old',
    WRONG_EMAIL = 'Wrong email',
    WRONG_PHONE_NUMBER = 'Wrong phoneNumber',
    WRONG_VALIDATION_CODE = 'Wrong verification token',
    NO_EXISTING_EMAIL = 'No email found for user',
    WRONG_UPDATE_LINK = 'Wrong update link',
    LINK_EXPIRED = 'Update link too old',
    NON_EXISTENT_USER = 'Non existent user',
    NEW_PASSWORD_NEEDED = 'New password needed'
}
@Injectable({
    providedIn: 'root'
})
export class ErrorMatcher {
    logError: Array<{ message: string, arg?: any }> = [];
    private missingVerification: boolean = false;
    private error400: string = '[400] Bad Request: ';

    constructor() { }

    get errorMessages() { return ErrorMessages; }

    isError(): boolean {
        return this.logError.length > 0;
    }
    isMissingVerification(): boolean {
        return this.missingVerification;
    }
    setError(err: HttpErrorResponse, arg?: any): string {
        this.clear();
        const cleanErrorMessage: string = this.clearPrefix(err.error.errorMessage);
        switch (cleanErrorMessage) {
            case ErrorMessages.WRONG_EMAIL:
                this.logError.push({ message: 'VALIDATION.WRONG_EMAIL' });
                break;
            case ErrorMessages.NON_EXISTENT_USER:
                this.logError.push({ message: 'VALIDATION.NON_EXISTENT_USER' });
                break;
            case ErrorMessages.WRONG_PHONE_NUMBER:
                this.logError.push({ message: 'VALIDATION.WRONG_TEL' });
                break;
            case ErrorMessages.MISSING_VERIFICATION:
                this.missingVerification = true;
                this.logError.push({ message: 'LOGIN.MISSING_VERIFICATION' });
                break;
            case ErrorMessages.OTP_MUST_BE_SUPPLIED:
                this.logError.push({ message: 'LOGIN.OTP_MUST_BE_SUPPLIED' });
                break;
            case ErrorMessages.OTP_ERROR:
                this.logError.push({ message: 'LOGIN.OTP_ERROR' });
                break;
            case ErrorMessages.PASSWORD_TOO_OLD:
                this.logError.push({ message: 'LOGIN.PASSWORD_TOO_OLD' }); // TYPO
                break;
            case ErrorMessages.WRONG_VALIDATION_CODE:
                this.logError.push({ message: 'VALIDATION.VALIDATION_CODE_ERROR' });
                break;
            case ErrorMessages.NO_EXISTING_EMAIL:
                this.logError.push({ message: 'PASSWORD_RESET.EMAIL_ERROR' });
                break;
            case ErrorMessages.WRONG_UPDATE_LINK:
                this.logError.push({ message: 'PASSWORD_RESET.WRONG_LINK_TITLE' });
                break;
            case ErrorMessages.LINK_EXPIRED:
                this.logError.push({ message: 'PASSWORD_RESET.OLD_LINK_TITLE' });
                break;
            case ErrorMessages.NEW_PASSWORD_NEEDED:
                this.logError.push({ message: 'PASSWORD_RESET.NEW_PASSWORD_FOR', arg });
                break;
            case ErrorMessages.LOGIN_FAILURE:
                this.logError.push({ message: 'LOGIN.LOGIN_FAILURE' });
        }
        return cleanErrorMessage;
    }
    setCustomError(err: ErrorMessages, arg: any) {
        switch (err) {
            case ErrorMessages.LOGIN_BLOCKED:
                this.clear();
                this.logError.push({ message: 'LOGIN.LOGIN_BLOCKED', arg });
                break;
            case ErrorMessages.REMAINING_ATTEMPT:
                this.logError.push({ message: arg === 1 ? 'LOGIN.REMAINING_ATTEMPT' : 'LOGIN.REMAINING_ATTEMPTS', arg });
        }
    }
    clear(): void {
        this.logError = [];
        this.missingVerification = false;
    }

    private clearPrefix(errorMessage: string, prefix: string = this.error400) {
        return (errorMessage?.startsWith(prefix)) ? errorMessage.split(prefix)[1] : errorMessage;
    }
}
