import { Injectable } from '@angular/core';
import { CreditCard } from 'app/models/CreditCard';
import { ValidatorFn, AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
@Injectable({
    providedIn: 'root'
})
export class PaymentMethodValidatorService {
    private _defaultCardMask = '0000 0000 0000 0000 000';
    private _defaultIbanMask = 'SS00 AAAA AAAA AAAA AAAA AAAA AAAA AAAA AA';
    private _allowCardTypes = {
        visa: {
            productID: 1,
            binLength: 19,
            regex: /^4\d{12,18}$/,
            cvcLength: 3
        },
        mastercard: {
            productID: 3,
            binLength: 16,
            regex: /^5[1-5]\d{14}$/,
            cvcLength: 3
        },
        maestro: {
            productID: 117,
            binLength: 19,
            regex: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d{8,15}$/,
            cvcLength: 3
        }
    };

    constructor() { }

    getIbanMask(): string {
        return this._defaultIbanMask;
    }
    getCardMask(): string {
        return this._defaultCardMask;
    }

    /**
     * Set and return if a productID were found for this cardNumber
     * productID is an Ingenico code to design mains credit card type
     * If a productID is found, assignation to card is made and a true return statement is return
     * Otherwise we just return false
     */
    getCorrectProductID(cardNumber: number): number | null {
        for (let key in this._allowCardTypes) {
            if (this._allowCardTypes[key].regex.test(cardNumber)) {
                return this._allowCardTypes[key].productID;
            }
        }
        return null;
    }
    /**
     * return the creditCard type from the cardNumber
     * @param {number, string} cardNumber, [checkCardType]: creditCard form the form
     * @returns {{number, string} || boolean} if checkCardType : check if two type match
     * else return the determined cardType from the cardNumber
     */
    detectCardType(cardNumber: number, checkCardType: string = null): { productID: number, cardBrand: string } | boolean {
        for (let key in this._allowCardTypes) {
            if (this._allowCardTypes[key].regex.test(cardNumber)) {
                return (checkCardType) ? key === checkCardType : { productID: this._allowCardTypes[key].productID, cardBrand: key };
            }
        }
    }
    detectCardTypeByPaymentProductId(paymentProductId: number, checkCardType: string = null)
        : { productID: number, cardBrand: string } | boolean {
        for (let key in this._allowCardTypes) {
            if (this._allowCardTypes[key].productID === paymentProductId) {
                return (checkCardType) ? key === checkCardType : { productID: this._allowCardTypes[key].productID, cardBrand: key };
            }
        }
    }

    cardNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const invalid = control.value && !this.valid_credit_card(control.value.replace(/\D/g, ''));
            return invalid ? { 'invalidCard': { value: control.value } } : null;
        };
    }

    expiryDateValidator(): ValidatorFn {
        return (control: UntypedFormGroup): ValidationErrors | null => {
            const expiryMonthField = control.get('expiryMonth');
            const expiryYearField = control.get('expiryYear');
            const invalid = expiryMonthField.value && expiryYearField.value && !this.validateCBDateExpiry(expiryMonthField.value, expiryYearField.value);
            const error = invalid ? { 'invalidExpiryDate': true } : null;
            [expiryMonthField, expiryYearField].forEach(field => {
                let errors = field.errors || {};
                delete errors.invalidExpiryDate;
                errors = Object.assign({}, errors, error);
                field.setErrors(Object.entries(errors).length === 0 ? null : errors);
            });
            return error;
        };
    }

    cvcValidator(): ValidatorFn {
        return (control: UntypedFormGroup): ValidationErrors | null => {
            const cvcField = control.get('cvc');
            const invalid = cvcField.value && !this.validateCvc(control.value);
            const error = invalid ? { 'invalidCvc': true } : null;
            const errors = Object.assign({}, cvcField.errors, error);
            cvcField.setErrors(Object.entries(errors).length === 0 ? null : errors);
            return error;
        };
    }

    ibanValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const invalid = control.value && !this.validateIBAN(control.value.replace(/[^A-Z0-9]/g, ''));
            return invalid ? { 'invalidIban': { value: control.value } } : null;
        };
    }

    /**
     * Depending on the creditCard Type/productID we could know the cvc length of this card
     * @param {CreditCard} card : creditCard form the form
     * @returns {boolean} is the cvc valid (same length expected)
     */
    private validateCvc(card: CreditCard) {
        for (let key in this._allowCardTypes) {
            if (this._allowCardTypes[key].productID === card.productID && card.cvc !== null) {
                return new RegExp(`^[0-9]{${this._allowCardTypes[key].cvcLength}}$`).test(card.cvc);
            }
        }
        return false;
    }

    /**
     * From an IBAN returns if the iban is valid
     * @param {string} ibanValue : iban value from the form
     * @returns {boolean} is the iban valid
     */
    private validateIBAN(ibanValue: string): boolean {
        const CODE_LENGTHS = {
            AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
            CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
            FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
            HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
            LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
            MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
            RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
        };
        const iban: string = ibanValue.toUpperCase().replace(/[^A-Z0-9]/g, '');
        const code: Array<any> = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
        let digits: number;
        if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
            return false;
        }
        digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
            return letter.charCodeAt(0) - 55;
        });
        return this.mod97(digits) === 1;
    }
    /**
     * Is the expiry date valid ?
     * A credit card is valid until the end of the expiry month
     * @param {string} expiryMonth
     * @param {string} expiryYear
     * @returns {boolean} is the date valid
     */
    private validateCBDateExpiry(expiryMonth: string, expiryYear: string): boolean {
        const date: Date = new Date();
        if (+expiryYear === date.getFullYear()) {
            return +expiryMonth >= (date.getMonth() + 1);
        } else if (+expiryYear > date.getFullYear() && +expiryMonth > 0) {
            return true;
        } else {
            return false;
        }
    }
    /**
     * Luhn algorithm
     * Used to check cardNumber validity
     */
    private valid_credit_card(cardValue: number): boolean {
        if (!cardValue) {
            return false;
        }
        let nCheck = 0, bEven = false;
        for (let n = cardValue.toString().length - 1; n >= 0; n--) {
            let cDigit = cardValue.toString().charAt(n),
                nDigit = parseInt(cDigit, 10);
            if (bEven) {
                if ((nDigit *= 2) > 9) { nDigit -= 9; }
            }
            nCheck += nDigit;
            bEven = !bEven;
        }
        return (nCheck % 10) === 0;
    }
    private mod97(string) {
        let checksum = string.slice(0, 2), fragment;
        for (let offset = 2; offset < string.length; offset += 7) {
            fragment = checksum + string.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }
        return checksum;
    }
}
