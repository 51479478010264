import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PageStatus } from '../util/page-status';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorMatcher } from '../login/error-matcher';
import { ConfirmValidParentMatcher, errorMessages, CustomValidators } from 'app/util/custom-validation';
import { AuthenticationService } from 'app/services/data/authentication.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    isLoading: boolean = false;
    firstTime: boolean = true;
    resetPassword: boolean = true;
    oldLink: boolean = false;
    askDifferentPassword: boolean = false;
    authetic: boolean = false;
    conform: string = '';
    form: UntypedFormGroup;
    second: number = 4;
    hasBeenReset: boolean = false;
    hide: boolean = true;
    loginID: string;
    verificationToken;
    timeMs: number;
    matcher = new ConfirmValidParentMatcher();
    errors: any = errorMessages; // Error List

    constructor(
        public pageStatus: PageStatus,
        public errorMatcher: ErrorMatcher,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private fb: UntypedFormBuilder
    ) {
        this.errorMatcher.clear();
    }

    get wrongLink(): boolean {
        return !this.loginID && !this.verificationToken;
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.loginID = params['loginID'];
            this.verificationToken = params['verificationToken'];
            this.timeMs = params['timeMS'];
            this.checkTokenDate();
        });
        this.form = this.fb.group({
            loginID: [{ value: this.loginID, disabled: true }, [Validators.required]],
            passwordGroup: this.fb.group({
                password: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
                confirmPassword: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]]
            }, { validators: [CustomValidators.childrenEqual] })
        });
    }

    submit() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.isLoading = true;
            this.authenticationService.resetPassword({
                loginID: this.loginID,
                lostToken: this.verificationToken,
                newPassword: this.form.get('passwordGroup').get('password').value
            })
                .subscribe(
                    () => {
                        this.isLoading = false;
                        this.hasBeenReset = true;
                        this.redirect();
                    },
                    err => {
                        this.isLoading = false;
                        const errorMessage: string = this.errorMatcher.setError(err);
                        switch (errorMessage) {
                            case this.errorMatcher.errorMessages.WRONG_UPDATE_LINK:
                                this.resetPassword = false;
                                break;
                            case this.errorMatcher.errorMessages.LINK_EXPIRED:
                                this.oldLink = true;
                                this.resetPassword = false;
                                break;
                            case this.errorMatcher.errorMessages.NEW_PASSWORD_NEEDED:
                                this.askDifferentPassword = true;
                                this.resetPassword = true;
                        }
                    }
                );
        }
    }

    getPasswordError(): any {
        if (this.form.get(['passwordGroup', 'password']).errors) {
            if (this.form.get(['passwordGroup', 'password']).errors.hasOwnProperty('minlength')) {
                return this.errors.passwordLength;
            } else {
                return this.errors.passwordRequired;
            }
        }
    }

    private redirect(): void {
        let id = setInterval(() => {
            if (this.second === 0) {
                clearInterval(id);
                this.router.navigate(['login']);
            } else {
                this.second--;
            }
        }, 1000);
    }

    private checkTokenDate() {
        this.isLoading = true;
        const aDayInMs = 86400000;
        const isTooOld = (Date.now() - this.timeMs) < 0 || (Date.now() - this.timeMs) > aDayInMs;
        if (isTooOld) {
            this.oldLink = true;
            this.resetPassword = false;
        }
        this.isLoading = false;
    }
}
