import { Injectable } from '@angular/core';
import { MethodApi } from '../../api/api-request';
import { Event, ActionSet } from '@co-assist/library';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceType } from '@co-assist/library/lib/constants/entity.const';


@Injectable({
    providedIn: 'root'
})
export class ActionSetService {
    constructor(private api: MethodApi) { }

    addActionSet(event: Event.User.AddActionSetEvent): Observable<Event.User.AddActionSetResponse> {
        return this.api.postUserRequest('addActionSet', event);
    }
    addActionSets({ id, actionSets }: {
        id: Event.User.AddActionSetEvent['id'], actionSets:
        Array<Event.User.AddActionSetEvent['actionSet']>
    }): Observable<Array<Event.User.AddActionSetResponse>> {
        return combineLatest(actionSets.map(actionSet => {
            return this.addActionSet({ id, actionSet });
        }));
    }

    updateActionSet(event: Event.User.UpdateActionSetEvent): Observable<Event.User.UpdateActionSetResponse> {
        return this.api.postUserRequest('updateActionSet', event);
    }
    updateActionSets({ id, actionSets }: { id: Event.User.UpdateActionSetEvent['id'], actionSets: Array<Event.User.UpdateActionSetEvent['actionSet']> }):
        Observable<Array<Event.User.UpdateActionSetResponse>> {
        return combineLatest(actionSets.map(actionSet => {
            return this.updateActionSet({ id, actionSet });
        }));
    }
    getActionSets(event: Event.User.GetActionSetsEvent): Observable<Array<ActionSet>> {
        return this.api.postUserRequest('getActionSets', event).pipe(
            map(actionSets => actionSets.map(a => new ActionSet(a)))
        );
    }
    deleteBatchActionSet({ id, actionSets }: { id: Event.User.DeleteActionSetEvent['id'], actionSets: Array<Event.User.UpdateActionSetEvent['actionSet']> })
        : Observable<Array<void>> {
        return combineLatest(actionSets.map(actionSet => {
            return this.deleteActionSet({ id, actionSetID: actionSet.actionSetID, moduleID: actionSet.moduleID, alertType: actionSet.moduleID });
        }));
    }
    deleteActionSet(event: Event.User.DeleteActionSetEvent): Observable<void> {
        return this.api.postUserRequest('deleteActionSet', event);
    }
    deleteActionSets(event: { id: string, moduleID: string }): Observable<void> {
        return this.api.postUserRequest('deleteActionSets', event);
    }
    /**
     *
     * @param id userID | groupID
     * @param groupSettingsList
     * @param concernedUsers
     */
    copySettings(id: string, deviceTypes: Array<DeviceType>, groupSettingsList: string[], concernedUsers: Array<string>, moduleIDs?: Array<string>, moduleIDSource?: string): Observable<void> {
        return this.api.postUserRequest('copySettings', { id, deviceTypes, groupSettingsList, concernedUsers, moduleIDs, moduleIDSource });
    }
}
