import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MethodApi } from 'app/api/api-request';
import { IGeneralUser, IGeneralGroup, GeneralUser, Event, GeneralModule, Store } from '@co-assist/library';
import { UpdateGeneralUserEvent, UpdateGeneralUserResponse, UpdateGroupEvent, UpdateGroupResponse } from '@co-assist/library/lib/event/user';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

export type TemperatureRecord = { moduleID: GeneralModule['moduleID'], value: number, timeMs: number };
@Injectable({
    providedIn: 'root'
})
export class EntityService {

    constructor(
        private api: MethodApi,
        private authenticationService: AuthenticationService
    ) { }

    getTemperatureHistory(event: Event.User.GetTemperatureHistoryEvent): Observable<Map<GeneralModule['moduleID'], Array<TemperatureRecord>>> {
        return this.api.postUserRequest('getTemperatureHistory', event).pipe(
            map((records: Event.User.GetTemperatureHistoryResponse) => {
                const temperatureByModuleID: Map<GeneralModule['moduleID'], Array<TemperatureRecord>> = new Map<GeneralModule['moduleID'], Array<TemperatureRecord>>();
                records.forEach(record => {
                    if (!temperatureByModuleID.has(record.moduleID)) {
                        temperatureByModuleID.set(record.moduleID, []);
                    }
                    const updatedRecords = temperatureByModuleID.get(record.moduleID);
                    updatedRecords.push(record);
                    temperatureByModuleID.set(record.moduleID, updatedRecords);
                });
                return temperatureByModuleID;
            })
        );
    }
    getUser(userID: GeneralUser['userID']): Observable<GeneralUser> {
        return this.api.postUserRequest('getUser', { userID }).pipe(
            map((user: IGeneralUser) => new GeneralUser(user))
        );
    }
    getHelpedsOf(id: Event.User.GetHelpedsEvent): Observable<Array<IGeneralUser>> {
        return this.api.postUserRequest('getHelpeds', id);
    }
    getHelpersOf(id: Event.User.GetHelpersEvent): Observable<Array<IGeneralUser>> {
        return this.api.postUserRequest('getHelpers', id);
    }
    getAdminsOf(event: Event.User.GetHelpersEvent): Observable<Array<IGeneralUser>> {
        return this.api.postUserRequest('getHelpers', { ...event, userType: 'admin' });
    }
    updateGeneralUser(user: UpdateGeneralUserEvent): Observable<UpdateGeneralUserResponse> {
        Object.keys(user).forEach(key => {
            if (user[key] && typeof user[key] === 'string') {
                user[key] = user[key].replace('\t', ' ');
            }
        });
        return this.api.postUserRequest('updateGeneralUser', user) as Observable<UpdateGeneralUserResponse>;
    }
    updateGroup(event: UpdateGroupEvent): Observable<UpdateGroupResponse> {
        return this.api.postGroupRequest('updateGroup', event);
    }
    getGroup(event: Event.User.GetGroupEvent): Observable<Event.User.GetGroupResponse> {
        return this.api.postGroupRequest('getGroup', event);
    }
    sendUserRequest(event: Event.User.SendUserRequestEvent): Observable<Event.User.SendUserRequestResponse> {
        return this.api.postUserRequest('sendUserRequest', event);
    }
    addGroupHelped(user: Event.AuthenticationRegistration.CreateGroupHelpedEvent): Observable<Event.AuthenticationRegistration.CreateGroupHelpedResponse> {
        return this.authenticationService.addGroupHelped(user);
    }
    addUserHelper(data: Event.AuthenticationRegistration.AddHelperEvent): Observable<GeneralUser> {
        return this.authenticationService.addUserHelper(data).pipe(
            map(user => new GeneralUser(user))
        );
    }
    addGroupHelper(data: Event.AuthenticationRegistration.CreateGroupHelperEvent): Observable<GeneralUser> {
        return this.authenticationService.addGroupHelper(data).pipe(
            map(user => new GeneralUser(user))
        );
    }
    deleteGroupUser(userID: string): Observable<void> {
        return this.authenticationService.deleteGroupUser({ userID });
    }
    deleteUser(userID: string): Observable<void> {
        return this.api.deleteUser({ userID });
    }
    getDoorStateHistory(event: Event.User.GetDoorStateHistoryEvent): Observable<Map<GeneralModule['moduleID'], Array<Store.DoorState>>> {
        return this.api.postUserRequest('getDoorStateHistory', event).pipe(
            map((records: Event.User.GetDoorStateHistoryResponse) => {
                const doorStatesByModuleID = new Map<GeneralModule['moduleID'], Array<Store.DoorState>>();
                records.forEach(record => {
                    doorStatesByModuleID.set(record.moduleID, [...doorStatesByModuleID.get(record.moduleID) || [], record]);
                });
                return doorStatesByModuleID;
            })
        );
    }
}
