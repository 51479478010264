import { Injectable } from '@angular/core';
import { MethodApi } from 'app/api/api-request';
import { Observable } from 'rxjs';
import { Alert, Constants, Event, GeneralModule, GeneralUser, Store } from '@co-assist/library';
import { map } from 'rxjs/operators';
import { ModuleStoreService } from '../store/module-store.service';

export type AlertHistory = {
    alertID: Store.Alert['alertID'];
    userID: GeneralUser['userID'];
    deviceType: GeneralModule['deviceType']
    moduleID: Store.Alert['moduleID'];
    alertType: Store.Alert['alertType'];
    start: Store.Alert['startTimeMs'];
    end: Store.Alert['startTimeMs'];
    status: Store.Alert['status'];
    lastUpdate: Store.Alert['timeMs'];
    allStatus: Array<Store.Alert>;
    details?: AlertDetails;
};

export class AlertDetails {
    qualification: Constants.Alert.Qualification;
    comment: string;
    constructor(obj: any) {
        this.qualification = obj?.qualification;
        this.comment = obj?.comment;
    }
}

export type ActiveAlert = {
    alertID: Store.Alert['alertID'],
    userID: GeneralUser['userID'],
    moduleID: Store.Alert['moduleID'],
    deviceType: GeneralModule['deviceType'],
    alertType: Store.Alert['alertType'],
    start: Store.Alert['startTimeMs'],
    end: Store.Alert['startTimeMs'],
    status: Store.Alert['status'],
    lastUpdate: Store.Alert['timeMs'],
};

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    // For alert in one of these status, do not display
    private alertsNotDisplayable: Array<Constants.Alert.Status> = [
        Constants.Alert.Status.INTERRUPTED,
        Constants.Alert.Status.ACQUITTED,
        Constants.Alert.Status.EXPIRED,
        Constants.Alert.Status.CLOSED
    ];
    constructor(
        private api: MethodApi,
        private moduleStoreService: ModuleStoreService
    ) { }

    updateAlertDetails(event: Event.Alert.UpdateAlertDetailsEvent): Observable<Event.Alert.UpdateAlertDetailsResponse> {
        return this.api.postAlertRequest('updateAlertDetails', event);
    }

    handleAlert(event: Event.Alert.HandleAlertEvent): Observable<Event.Alert.HandleAlertResponse> {
        return this.api.postAlertRequest('handleAlert', event);
    }

    acquitAlert(event: Event.Alert.AcquitAlertEvent): Observable<Event.Alert.AcquitAlertResponse> {
        return this.api.postAlertRequest('acquitAlert', event);
    }

    /**
     * Return active alerts
     * @returns a list of alerts sorted by startTimeMs, from the newest to the older
     */
    getActiveAlerts(event: Event.Alert.GetActiveAlertsEvent): Observable<Event.Alert.GetActiveAlertsResponse> {
        return this.api.postAlertRequest('getActiveAlerts', event).pipe(
            map(alerts => alerts
                .sort((a, b) => b.startTimeMs - a.startTimeMs)
                .filter(alert => !this.alertsNotDisplayable.includes(alert.status)))
        );
    }

    getAlert(event: Event.Alert.GetAlertEvent): Observable<Event.Alert.GetAlertResponse> {
        return this.api.postAlertRequest('getAlert', event);
    }

    /**
     * Return all alerts lines for a given time range
     * @param userID & time range
     * @returns all alerts and its different states
     */
    getAlertHistory(event: Event.Alert.GetAlertHistoryEvent): Observable<Array<AlertHistory>> {
        return this.api.postAlertRequest('getAlertHistory', event).pipe(
            map((alertHistory: Event.Alert.GetAlertHistoryResponse) => {
                return this.aggregateIntoAlertHistory(alertHistory.alerts, alertHistory.alertDetails);
            })
        );
    }
    /**
     * Return all alerts lines for a given time range
     * @param userID & time range
     * @returns all alerts and its different states
     */
    getSingleAlertHistory(event: { userID: string, alertID: string }): Observable<AlertHistory> {
        return this.api.postAlertRequest('getAlertHistory', event).pipe(
            map((alertHistory: Event.Alert.GetAlertHistoryResponse) => {
                return this.aggregateIntoAlertHistory(alertHistory.alerts, alertHistory.alertDetails).find(a => a.alertID === event.alertID);
            })
        );
    }

    /**
     * Return all alerts lines for a given time range
     * @param userIDs & time range
     * @returns all alerts and its different states
     */
    getAlertsHistory(event: Event.Alert.GetAlertsHistoryEvent): Observable<Map<GeneralUser['userID'], Array<AlertHistory>>> {
        return this.api.postAlertRequest('getAlertsHistory', event).pipe(
            map((alertsHistoryByUser: Event.Alert.GetAlertsHistoryResponse) => {
                const userIDs: Map<GeneralUser['userID'], Array<AlertHistory>> = new Map();
                Object.keys(alertsHistoryByUser).forEach(userID => userIDs.set(userID, this.aggregateIntoAlertHistory(alertsHistoryByUser[userID].alerts, alertsHistoryByUser[userID].alertDetails)));
                return userIDs;
            })
        );
    }
    private aggregateIntoAlertHistory(alerts: Array<Store.Alert>, alertDetails: Array<Store.AlertDetails>): Array<AlertHistory> {
        const alertIDs = [...alerts, ...alertDetails].reduce((array, item) => {
            if (!array.includes(item.alertID)) {
                array.push(item.alertID);
            }
            return array;
        }, []);
        const formattedAlerts: Array<AlertHistory> = [];
        alertIDs.forEach(alertID => {
            const actions: Array<Store.Alert> = alerts
                .filter(alert => alert.alertID === alertID)
                .sort((a, b) => a.timeMs - b.timeMs)
                .map(alert => new Store.Alert(alert));
            const details: AlertDetails = new AlertDetails(alertDetails.find(alert => alert.alertID === alertID));
            if (actions.length) {
                const startAction = actions.find(alert => alert.status === Constants.Alert.Status.STARTED);
                const closeAction = actions.find(alert => alert.status === Constants.Alert.Status.CLOSED);
                const lastUpdate = actions[actions.length - 1];
                // Getting all alertHistory does not mean we have the start status, in that case we do not display this alert
                if (startAction) {
                    formattedAlerts.push({
                        alertID: startAction.alertID,
                        userID: startAction.userID,
                        moduleID: startAction.moduleID,
                        deviceType: this.moduleStoreService.getModule(startAction.moduleID)?.getDeviceType(),
                        alertType: startAction.alertType,
                        start: startAction.startTimeMs,
                        end: closeAction?.timeMs,
                        status: lastUpdate.status,
                        lastUpdate: lastUpdate.timeMs,
                        allStatus: actions,
                        details: details
                    });
                }
            }
        });
        return formattedAlerts.sort((a, b) => b.start - a.start);
    }
}
