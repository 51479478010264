import { Directive, ElementRef, HostListener } from '@angular/core';
import { environment } from 'environments/environment';

@Directive({
    selector: '[appUserID]'
})
export class UserIDDirective {

    constructor(private el: ElementRef) { }

    @HostListener('mouseenter') onMouseEnter() {
        this.el.nativeElement.style.cursor = 'pointer';
    }

    @HostListener('click', ['$event']) onClick() {
        let userID: string;
        if (this.el.nativeElement.innerText) {
            userID = this.el.nativeElement.innerText;
        } else if (this.el.nativeElement.value) {
            userID = this.el.nativeElement.value;
        }
        if (userID) {
            this.redirectToManageModule(userID);
        }
    }

    private redirectToManageModule(userID: string) {
        const url = `${environment.ERP_URL}content/manageUsers/${userID}`;
        window.open(url, '_blank');
    }
}
