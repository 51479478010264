<div (window:resize)="backgroundAutoSize()" [style.width]="width + 'px'">
    <div mat-dialog-title class="toolbar toolbar-row">
        <span name="dialogConfirmationTitle">{{titleDialog}}</span>
    </div>
    <div mat-dialog-content>
        <p [innerHTML]="textDialog" [ngClass]="{'isJustified': isJustified}"></p>
    </div>
    <div mat-dialog-actions align="end" *ngIf="isConfirmation">
        <button mat-raised-button id="app-dialog-confirmation-cancel-button" name="cancel"
            [mat-dialog-close]="false">{{'COMMON.CANCEL' | translate}}</button>
        <button mat-raised-button [mat-dialog-close]="true" color="accent">{{'COMMON.CONFIRM' | translate}}</button>
    </div>
    <div mat-dialog-actions align="end" *ngIf="isQuestion">
        <button mat-raised-button [mat-dialog-close]="false">{{'COMMON.NO' | translate}}</button>
        <button mat-raised-button [mat-dialog-close]="true" color="accent">{{'COMMON.YES' | translate}}</button>
    </div>
    <div mat-dialog-actions align="end" *ngIf="isConfirmation === false">
        <button mat-raised-button [mat-dialog-close]="true" color="accent">OK</button>
    </div>
</div>
