<div class="general-container">
    <mat-toolbar>
        <mat-toolbar-row class="mainRow">
            <a id="hrefCoassist" href="https://www.co-assist.fr">
                <img class="logo-header" src="/assets/images/logos/co-assist/Co-assist-logo-2.png"
                    srcset="/assets/images/logos/co-assist/Co-assist-vector.svg" alt="Logo co-assist">
            </a>
            <ng-container *ngIf="displayCustomerService">
                <span class="example-spacer"></span>
                <div class="hidden-xs hidden-sm">
                    <span>{{'REGISTRATION.CUSTOMER_SERVICE' | translate}}</span>&nbsp;<span
                        class="service-number">{{'REGISTRATION.CUSTOMER_SERVICE_NUMBER' | translate}}</span>
                </div>
            </ng-container>

            <span class="example-spacer"></span>
            <app-multilingual class="multilingual-placement"></app-multilingual>
            <div>
                <ng-container *ngIf="isSubscription;else displayBooklet">
                    <button type="button" mat-raised-button color="primary" class="mat-small"
                        (click)="downloadBooklet();">
                        {{'REGISTRATION.GET_BOOKLET'|translate}}
                    </button>
                </ng-container>
                <ng-template #displayBooklet>
                    <button id="hrefSubscription" (click)="goToSubscription()" mat-raised-button color="primary"
                        id="subscribe">
                        {{'LOGIN.SUBSCRIBE' | translate}}
                    </button>
                </ng-template>
            </div>
        </mat-toolbar-row>
        <mat-toolbar-row class="mobileRow visible-xs-block visible-sm-block">
            <span>{{'REGISTRATION.CUSTOMER_SERVICE' | translate}}</span>&nbsp;<span
                class="service-number">{{'REGISTRATION.CUSTOMER_SERVICE_NUMBER' | translate}}</span>
        </mat-toolbar-row>
    </mat-toolbar>
    <ng-content></ng-content>
</div>
