import { Injectable } from '@angular/core';
import { ActivatedRoute, Resolve } from '@angular/router';
import { GeneralService } from 'app/services/data/general.service';
import { Observable } from 'rxjs';

@Injectable()

export class ContentResolver implements Resolve<boolean> {
    constructor(
        private generalService: GeneralService,
        private activatedRoute: ActivatedRoute
    ) { }
    resolve(): Observable<boolean> {
        return this.generalService.fetchData$();
    }
}
