<div *ngIf="_accessDenied; else loading" class="accessDenied"> {{ 'COMMON.TOAST.ACCESS_DENIED' | translate }}</div>

<ng-template #loading>
    <table class="page-table">
        <tr>
            <td class="page-td">
                <div id="fountainG">
                    <div id="fountainG_1" class="fountainG"></div>
                    <div id="fountainG_2" class="fountainG"></div>
                    <div id="fountainG_3" class="fountainG"></div>
                    <div id="fountainG_4" class="fountainG"></div>
                    <div id="fountainG_5" class="fountainG"></div>
                    <div id="fountainG_6" class="fountainG"></div>
                    <div id="fountainG_7" class="fountainG"></div>
                    <div id="fountainG_8" class="fountainG"></div>
                </div>
            </td>
        </tr>
    </table>
</ng-template>
