import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggedUserStoreService } from 'app/services/store/logged-user-store.service';
import { HelpedStoreService } from 'app/services/store/helped-store.service';
import { ActionSetStoreService } from 'app/services/store/action-set-store.service';
import { ModuleStoreService } from 'app/services/store/module-store.service';
import { CookieService } from '../utilities/cookie.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CookieKey, ManageAccess } from '../../util/manage-access';
import { LanguageService } from '../utilities/language.service';
import { AuthenticationRegistration } from '@co-assist/library/lib/event';
import { AuthenticationService } from './authentication.service';
import { HelperStoreService } from '../store/helper-store.service';
import { AlertStoreService } from '../store/alert-store.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private cookie: CookieService,
        private loggedUserStoreService: LoggedUserStoreService,
        private helpedStoreService: HelpedStoreService,
        private alertStoreService: AlertStoreService,
        private helperStoreService: HelperStoreService,
        private moduleStoreService: ModuleStoreService,
        private actionSetStoreService: ActionSetStoreService,
        private manageAccess: ManageAccess,
        private languageService: LanguageService
    ) { }

    signIn(event: AuthenticationRegistration.LoginPasswordEvent): Observable<void> {
        this.cleanServices();
        return this.connect(event);
    }

    reConnect(): Observable<void> {
        const loginID = this.cookie.get('loginID');
        const userID = this.cookie.get('userID');
        const permanentToken = this.manageAccess.getUserToken();
        // handle old mobile apps login behavior with userID
        const event = loginID ? { loginID, permanentToken } : { userID, permanentToken };
        // When connected as superAdmin, the expiration of the sessionToken leads to the reconnection mecanism and the update of the loggedUser
        // with the wrong user ( superAdmin vs selectedUser)
        const bypassLoggedUser: boolean = !!this.loggedUserStoreService.getLoggedUser() && !!this.loggedUserStoreService.getLoggedGroup();
        return this.connect(event, bypassLoggedUser);
    }

    logOut(): void {
        this.router.navigate(['/login']).then(_ => {
            this.cookie.delete('timezone');
            this.cookie.delete('language');
            this.cookie.delete('registerState');
            this.cookie.delete('loginID');
            this.cookie.delete('userID');
            this.cookie.delete('permanentToken');
            this.cookie.delete('sessionToken');
            this.cookie.delete(CookieKey.advancedUser);
            this.manageAccess.resetAdvanceUser();
            this.cleanServices();
        });
    }

    isConnected(): boolean {
        return this.cookie.get('sessionToken') !== '' || this.cookie.get('privateToken') !== '';
    }

    private connect(event: AuthenticationRegistration.LoginEvent, bypassLoggedUser: boolean = false): Observable<void> {
        return this.authenticationService.logIn(event).pipe(
            map(authenticationData => {
                if (!bypassLoggedUser) {
                    this.loggedUserStoreService.setLoggedUser(authenticationData, authenticationData.status);
                }
                this.loggedUserStoreService.setCoAssistAdmin(authenticationData, authenticationData.status);
                this.manageAccess.saveUserAccess(event.loginID, authenticationData.userID, authenticationData.authentication);
                const _loggedUser = this.loggedUserStoreService.getLoggedUser();
                this.cookie.set('timezone', _loggedUser.timeZone, 365);
                this.cookie.delete('otpSession');
                this.languageService.setLanguage(_loggedUser.language);
            })
        );
    }

    /**
     * Because of the lifecycle of a service we must erase all the data into used services
     */
    private cleanServices(): void {
        this.actionSetStoreService.clean();
        this.alertStoreService.clean();
        this.helpedStoreService.clean();
        this.helperStoreService.clean();
        this.loggedUserStoreService.clean();
        this.moduleStoreService.clean();
    }
}
