import { AuthenticationService } from 'app/services/data/authentication.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MethodApi } from '../api/api-request';


@Component({
    selector: 'app-email-checker',
    templateUrl: './email-checker.component.html',
    styleUrls: ['./email-checker.component.scss']
})
export class EmailCheckerComponent implements OnInit {
    isSuccess: boolean;
    isLoading: boolean = true;
    private second: number = 3;
    constructor(
        private route: ActivatedRoute,
        private api: MethodApi,
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.verifyEmail(params['userID'], params['verificationToken']);
        });
    }

    verifyEmail(userID: any, verificationToken: any) {
        this.authenticationService.verifyEmail({ userID: userID, token: verificationToken })
            .subscribe(
                data => {
                    this.isSuccess = data.verified;
                    this.isLoading = false;
                    if (this.isSuccess) {
                        this.redirect();
                    }
                },
                () => {
                    this.isLoading = false;
                    this.isSuccess = false;
                }
            );
    }

    private redirect(): void {
        let id = setInterval(() => {
            if (this.second === 0) {
                clearInterval(id);
                this.router.navigate(['login']);
            } else {
                this.second--;
            }
        }, 1000);
    }
}
