import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-page-404',
    templateUrl: 'not-found.component.html',
    styleUrls: ['not-found.component.scss']
})
export class NotFoundComponent {
    constructor(
        private router: Router
    ) { }

    goToLogin() {
        this.router.navigate(['login']);
    }
}
