import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

interface GoogleTagManagerConfig {
    id: string | null;
    gtm_auth?: string;
    gtm_preview?: string;
    [key: string]: string | null | undefined;
}

@Injectable({
    providedIn: 'root'
})
export class GoogleTagManagerService {
    private isLoaded = false;
    private browserGlobals = {
        windowRef(): any {
            return window;
        },
        documentRef(): any {
            return document;
        },
    };

    private config: GoogleTagManagerConfig = { id: null };
    constructor() { this.config.id = environment.GTAG; }

    getDataLayer(): any[] {
        const window = this.browserGlobals.windowRef();
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer;
    }
    addGtmToDom(): Promise<boolean> {
        if (this.config.id) {
            return new Promise((resolve, reject) => {
                if (this.isLoaded) {
                    return resolve(this.isLoaded);
                }
                const doc = this.browserGlobals.documentRef();
                this.pushOnDataLayer({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js',
                });
                const gtmScript = doc.createElement('script');
                gtmScript.id = 'GTMscript';
                gtmScript.async = true;
                gtmScript.src = this.applyGtmQueryParams('https://www.googletagmanager.com/gtm.js');
                gtmScript.addEventListener('load', () => {
                    return resolve(this.isLoaded = true);
                });
                gtmScript.addEventListener('error', () => {
                    return reject(false);
                });
                doc.head.insertBefore(gtmScript, doc.head.firstChild);
            });
        }
    }
    private applyGtmQueryParams(url: string): string {
        if (url.indexOf('?') === -1) {
            url += '?';
        }
        return (
            url +
            Object.keys(this.config)
                .filter((k) => this.config[k])
                .map((k) => `${k}=${this.config[k]}`)
                .join('&')
        );
    }
    private pushOnDataLayer(obj: object): void {
        const dataLayer = this.getDataLayer();
        dataLayer.push(obj);
    }
}
