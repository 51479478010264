import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FullScreenLoaderComponent } from "app/sharedComponent/main-component/full-screen-loader/full-screen-loader.component";

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    private overlayRef: OverlayRef = null;

    constructor(
        private overlay: Overlay,
        private translate: TranslateService
    ) { }

    public show(message?: string, args?: object) {
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true
            });
        }

        // Create ComponentPortal that can be attached to a PortalHost
        const spinnerOverlayPortal = new ComponentPortal(FullScreenLoaderComponent);
        const component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
        component.instance.message = message ? this.translate.instant(message, args) : undefined;
    }
    public showRaw(message = '') {
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true
            });
        }

        // Create ComponentPortal that can be attached to a PortalHost
        const spinnerOverlayPortal = new ComponentPortal(FullScreenLoaderComponent);
        const component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
        component.instance.message = message;
    }


    public hide() {
        if (!!this.overlayRef) {
            this.overlayRef.detach();
        }
    }
}
