<app-page-layout [title]="" [displayCustomerService]="false">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <mat-card>
                    <mat-card-content>
                        <div class="row">
                            <ng-container *ngIf="isLoading; else cardContent">
                                <app-loading></app-loading>
                            </ng-container>
                            <ng-template #cardContent>
                                <ng-container *ngIf="isSuccess;else isError">
                                    <ng-container *ngTemplateOutlet="successMessage"></ng-container>
                                </ng-container>
                                <ng-template #isError>
                                    <ng-container *ngTemplateOutlet="errorMessage"></ng-container>
                                </ng-template>
                            </ng-template>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</app-page-layout>
<ng-template #errorMessage>
    <div class="col-md-12">
        <div class="alert custom-danger text-left" role="alert">
            <p class="error">
                <mat-icon class="danger">error</mat-icon>
                <span>{{'COMMON.TOAST.ERROR_OCCURED' | translate}}</span>
            </p>
        </div>
    </div>
</ng-template>
<ng-template #successMessage let-redirect="redirect">
    <div class="col-md-12">
        <div class="alert alert-success" role="alert">
            <span>{{ ('EMAIL_CHECKER.ACCOUNT_ACTIVATED') |translate }}</span>
            <p> {{'COMMON.GO_TO_LOGIN' | translate }}</p>
        </div>
    </div>
</ng-template>
