import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActionSet, Event } from '@co-assist/library';
import { Observable } from 'rxjs';
import { ActionSetService } from 'app/services/data/action-set.service';
import { HelpedStoreService } from '../services/store/helped-store.service';

@Injectable()

export class ActionSetResolver implements Resolve<Array<ActionSet>> {

    constructor(
        private actionSet: ActionSetService,
        private helpedStoreService: HelpedStoreService) { }

    resolve(): Observable<Array<ActionSet>> {
        const event: Event.User.GetActionSetsEvent = {
            id: this.helpedStoreService.getCurrentHelpedID()
        };
        // if (route.data['fromAdmin']) {
        return this.actionSet.getActionSets(event);
    }
}
