import { MethodApi } from 'app/api/api-request';
import { AuthenticationService } from 'app/services/data/authentication.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { EntityService } from '../data/entity.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { GeneralUser, IGeneralUser, Event, GeneralGroup } from '@co-assist/library';
import { AccessStatus, LoggedUserStoreService } from './logged-user-store.service';

enum LocalStorageKeys {
    helpers = 'helpers',
    admins = 'admins'
}
@Injectable({
    providedIn: 'root'
})
export class HelperStoreService {
    readonly admins$: Observable<Array<GeneralUser>>;
    private readonly _admins = new BehaviorSubject<Array<GeneralUser>>([]);

    readonly helpers$: Observable<Array<GeneralUser>>;
    private readonly _helpers = new BehaviorSubject<Array<GeneralUser>>([]);

    constructor(
        private entityService: EntityService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
        private api: MethodApi,
        private loggedUserStoreService: LoggedUserStoreService
    ) {
        this.helpers$ = this._helpers.asObservable();
        this.admins$ = this._admins.asObservable();
    }

    getHelper(userID: GeneralUser['userID']): GeneralUser | undefined {
        return this.getHelpers().find(u => u.userID === userID);
    }
    getHelpers(): Array<GeneralUser> {
        return this._helpers.getValue();
    }
    getAdmins(): Array<GeneralUser> {
        return this._admins.getValue();
    }
    getHelpersObs(): Observable<Array<GeneralUser>> {
        return this.helpers$;
    }
    getHelpersByField(field?: string): Array<GeneralUser> {
        return field ? this.getHelpers().filter(h => h[field]) : this.getHelpers();
    }
    getHelpersAccordingToStatus(): Array<GeneralUser> {
        if ([AccessStatus.group_helper, AccessStatus.help].includes(this.loggedUserStoreService.getLoggedUserStatus())) {
            const currentHelped = this.loggedUserStoreService.getLoggedUser();
            return this.getHelpers().filter(u => u.getUserID() === currentHelped.getUserID());
        }
        return this.getHelpers();
    }
    getHelpersAccordingToStatus$(): Observable<Array<GeneralUser>> {
        if ([AccessStatus.group_helper, AccessStatus.help].includes(this.loggedUserStoreService.getLoggedUserStatus())) {
            const currentHelped = this.loggedUserStoreService.getLoggedUser();
            return this.getHelpersObs().pipe(
                map(users => {
                    return users.filter(u => u.getUserID() === currentHelped.getUserID())
                })
            )
        }
        return this.getHelpersObs();
    }
    getHelpersWithPhonenumber(): Observable<Array<GeneralUser>> {
        return this.helpers$.pipe(
            map(helpers => helpers.filter(h => h.phoneNumber))
        )
    }
    getAdminsWithPhonenumber(): Observable<Array<GeneralUser>> {
        return this.admins$.pipe(
            map(admins => admins.filter(a => a.phoneNumber))
        )
    }
    getHelpersAndAdminsWithPhonenumber(): Observable<Array<GeneralUser>> {
        return this.getHelpersWithPhonenumber().pipe(
            mergeMap(helpers => this.getAdminsWithPhonenumber().pipe(
                map(admins => helpers.concat(admins))
            )
            )
        );
    }
    getHelpersWithWhatsAppPhonenumber(): Observable<Array<GeneralUser>> {
        return this.helpers$.pipe(
            map(helpers => helpers.filter(h => h.whatsAppNumber))
        )
    }
    getHelpersWithNotificationEndpoint(): Observable<Array<GeneralUser>> {
        return this.helpers$.pipe(
            map(helpers => helpers.filter(h => h.notificationEndpoints?.length))
        )
    }
    addGroupHelper(user: IGeneralUser & Required<Pick<IGeneralUser, 'group' | 'phoneNumber'>>): Observable<GeneralUser> {
        return this.entityService.addGroupHelper(user).pipe(
            map(returnUser => {
                this._addHelper(returnUser);
                this.toastr.success(this.translate.instant('COMMON.TOAST.ADD_USER_SUCCESS'));
                return returnUser;
            }));
    }
    addUserHelper(data: Event.AuthenticationRegistration.AddHelperEvent): Observable<GeneralUser> {
        return this.entityService.addUserHelper(data).pipe(
            map(returnUser => {
                this._addHelper(returnUser);
                this.toastr.success(this.translate.instant('COMMON.TOAST.ADD_USER_SUCCESS'));
                return returnUser;
            }));
    }
    setHelpers(users: Array<IGeneralUser>) {
        this._helpers.next(users.map(u => new GeneralUser(u)).sort(function (a, b) {
            let textA = a.getFullName().toUpperCase();
            let textB = b.getFullName().toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }));
        localStorage.setItem(LocalStorageKeys.helpers, JSON.stringify(this._helpers.getValue()));
    }
    fetchHelpers(event: { groupID: string } | { userID: string }) {
        return this.entityService.getHelpersOf(event).pipe(
            map(helpers => {
                this.setHelpers(helpers)
            })
        );
    }
    fetchHelper(userID: string) {
        return this.entityService.getUser(userID).pipe(
            map(helper => {
                this.setHelpers([helper])
            })
        );
    }
    fetchAdmins(groupID: GeneralGroup['groupID']) {
        this.entityService.getAdminsOf({ groupID }).subscribe(
            helpers => {
                const admins = helpers.map(u => new GeneralUser(u)).sort(function (a, b) {
                    let textA = a.getFullName().toUpperCase();
                    let textB = b.getFullName().toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                this._admins.next(admins);
            })
    }

    setAdmins(users: Array<IGeneralUser>) {
        this._admins.next(users.map(u => new GeneralUser(u)).sort(function (a, b) {
            let textA = a.getFullName().toUpperCase();
            let textB = b.getFullName().toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }));
        localStorage.setItem(LocalStorageKeys.admins, JSON.stringify(this._admins.getValue()));
    }
    fetchAdmins$(groupID: GeneralGroup['groupID']): Observable<void> {
        return this.entityService.getAdminsOf({ groupID }).pipe(
            map(helpers => {
                const admins = helpers.map(u => new GeneralUser(u)).sort(function (a, b) {
                    let textA = a.getFullName().toUpperCase();
                    let textB = b.getFullName().toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                this._admins.next(admins);
            })
        )
    }
    deleteGroupHelper(userID: string) {
        return this.entityService.deleteGroupUser(userID).pipe(
            map(() => {
                this._deleteHelper(userID);
                this.toastr.success(this.translate.instant('COMMON.TOAST.DELETE_USER_SUCCESS'));
            })
        )
    }
    deleteUserHelper(userID: string, relation: string): Observable<void> {
        return this.authenticationService.deleteRelation({ user: userID, relation }).pipe(
            switchMap(() => {
                return this.getRelationTree(userID);
            }),
            switchMap(data => {
                if (data.helpedList.length === 0 && data.helperList.length === 0) {
                    return this.entityService.deleteUser(userID);
                }
                return of(false);
            }), map((result: boolean | undefined) => {
                if (result !== false) {
                    this._deleteHelper(userID);
                    this.toastr.success(this.translate.instant('COMMON.TOAST.DELETE_USER_SUCCESS'));
                }
            })
        )
    }
    updateHelper(user: Partial<GeneralUser> & Pick<GeneralUser, 'userID'>): Observable<void> {
        return this.entityService.updateGeneralUser(user).pipe(
            map(() => {
                this._updateHelper(user);
                this.toastr.success(this.translate.instant('COMMON.TOAST.UPDATE_USER_SUCCESS'),);
            })
        );
    }
    clean() {
        this._resetHelpers();
        this._resetAdmins();
    }
    private _resetHelpers() {
        this._helpers.next([]);
        localStorage.removeItem(LocalStorageKeys.helpers);
    }
    private _resetAdmins() {
        this._admins.next([]);
        localStorage.removeItem(LocalStorageKeys.admins);
    }
    private _addHelper(user: GeneralUser) {
        const helpers = this.getHelpers();
        this.setHelpers([...helpers, user]);
    }
    private _updateHelper(user: Partial<GeneralUser>) {
        const helpers = this.getHelpers().map(h => {
            if (h.userID === user.userID) {
                Object.keys(user).forEach(key => {
                    if (key !== 'userID') {
                        h[key] = user[key];
                    }
                });
            }
            return h;
        });
        this.setHelpers(helpers);
    }
    private _deleteHelper(userID: string) {
        const filteredList = this.getHelpers().filter(u => u.getUserID() !== userID);
        this._helpers.next(filteredList);
    }

    private getRelationTree(requestedUserID: string): Observable<any> {
        const resultData = { userID: requestedUserID, helperList: [], helpedList: [] };
        return this.api.getRelations({ userID: requestedUserID }).pipe(
            switchMap(data => {
                resultData.helpedList = data
                    .filter(item => item.userID !== requestedUserID)
                    .map(item => ({ userID: item.userID }));
                return this.api.getHelpers({ userID: requestedUserID });
            }),
            concatMap(data => {
                resultData.helperList = data.map(item => ({ userID: item.userID }));
                return of(resultData);
            })
        );
    }
}
