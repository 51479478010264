
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MethodApi } from 'app/api/api-request';
import { Router } from '@angular/router';
import { LoginService } from 'app/services/data/login.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    private refreshTokenInProgress: boolean = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(
        private translate: TranslateService,
        private toastr: ToastrService,
        private loginService: LoginService,
        private router: Router,
        private api: MethodApi
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<void | never>> {
        return next.handle(req).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                if (errorResponse.status === 400) {
                    this.showErrorToast();
                } else if (errorResponse.status === 401) {
                    return this.handleUnauthorizedResponse(req, next);
                } else if (errorResponse.status === 403) {
                    this.toastr.error(
                        this.translate.instant('COMMON.TOAST.ACCESS_DENIED'),
                        this.translate.instant('COMMON.TOAST.ERROR_TITLE')
                    );
                } else if (errorResponse.status === 429) {
                    this.toastr.error(
                        this.translate.instant('COMMON.TOAST.API_REQUEST_EXCEEDED'),
                        this.translate.instant('COMMON.TOAST.ERROR_TITLE')
                    );
                } else {
                    this.showErrorToast();
                }
                return throwError(errorResponse);
            })
        );
    }
    private showErrorToast() {
        this.toastr.error(
            this.translate.instant('COMMON.TOAST.ERROR_API_TEXT'),
            this.translate.instant('COMMON.TOAST.ERROR_TITLE')
        );
    }

    private handleUnauthorizedResponse(req: HttpRequest<any>, next: HttpHandler) {
        // 401 errors are most likely going to be because we have an expired token that we need to refresh.
        if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject.pipe(
                filter(result => result !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(req)))
            );
        } else {
            this.refreshTokenInProgress = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);
            return this.loginService.reConnect().pipe(
                switchMap(() => {
                    this.refreshTokenSubject.next(true);
                    return next.handle(this.addAuthenticationToken(req));
                }),
                catchError(() => {
                    this.router.navigateByUrl('/login');
                    return of(undefined);
                }),
                // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                // for the next time the token needs to be refreshed
                finalize(() => this.refreshTokenInProgress = false)
            );
        }
    }

    private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            headers: request.headers.set('authorization', this.api.setAuthorization())
        });
    }
}
