import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { GeneralService } from 'app/services/data/general.service';
import { Observable } from 'rxjs';

@Injectable()

export class TelemetryResolver implements Resolve<boolean> {
    constructor(
        private generalService: GeneralService
    ) { }
    resolve(): Observable<boolean> {
        return this.generalService.fetchCommonData$();
    }
}

// export const TelemetryResolver: ResolveFn<boolean> = (
//     _: ActivatedRouteSnapshot,
//     __: RouterStateSnapshot
// ): Observable<boolean> => { return inject(GeneralService).fetchCommonData() };
