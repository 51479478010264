import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Resolve } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AutoCompletionService } from 'app/util/id-autocompletion.service';
import { LoginService } from 'app/services/data/login.service';

@Injectable()
export class AuthGuardService implements CanLoad, CanActivate, Resolve<any> {

    constructor(
        private loginService: LoginService,
        private autocompletion: AutoCompletionService
    ) { }

    canLoad(): boolean {
        return this.loginService.isConnected();
    }

    canActivate(): Observable<any> {
        return this.loginService.reConnect().pipe(map(
            () => true
            ,
            catchError(() => of(false))
        ));
    }

    resolve() {
        return this.autocompletion.initAutocompletionFile();
    }
}
/**
 * AuthGuard processing order :
 * 1) canDeactivate
 * 2) canLoad - lazyLoaded modules
 * 3) canActivateChild
 * 4) canActive
 * 5) resolve
 */
