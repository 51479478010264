import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, forkJoin, of, combineLatest, EMPTY } from 'rxjs';
import { GeneralUser, GeneralGroup, ActionSet, GeneralModule, Constants, ALERT, TimeRange, ZoneExitType, Rules, IRules } from '@co-assist/library';
import { map, switchMap } from 'rxjs/operators';
import { ActionSetService } from 'app/services/data/action-set.service';
import { DeviceType } from '@co-assist/library/lib/constants/entity.const';
import { HelperStoreService } from './helper-store.service';
import { M } from '@angular/cdk/keycodes';

type EntityID = GeneralUser['userID'] | GeneralGroup['groupID'];
type ActionSetMapping = Map<GeneralUser['userID'] | GeneralGroup['groupID'], Array<ActionSet>>;
export const DEFAULT = 'default';
@Injectable({
    providedIn: 'root'
})
export class ActionSetStoreService {
    readonly actionSets$: Observable<ActionSetMapping>;
    private readonly _actionSets = new BehaviorSubject<ActionSetMapping>(new Map());
    constructor(
        private actionSetService: ActionSetService,
        private helperStoreService: HelperStoreService
    ) {
        this.actionSets$ = this._actionSets.asObservable();
    }

    /**
     * Fetch data from server
     * @param id od the user or group
     */
    fetchActionSets(id: GeneralUser['userID'] | GeneralGroup['groupID']): Observable<void> {
        return this.actionSetService.getActionSets({ id })
            .pipe(map(actionSets => {
                this.setActionSets(id, actionSets);
            }));
    }
    /**
     * Add to the Map[id] a new actionSet (store & DB)
     * @param id of the entity
     * @param actionSet fresh version of the actionSet to add
     */
    addActionSet(id: EntityID, actionSet: ActionSet): Observable<void> {
        return this.actionSetService.addActionSet({ id, actionSet }).pipe(
            map(_ => {
                const actionSetMap = this._actionSets.getValue();
                const actionSets: Array<ActionSet> = this._getActionSets(id);
                actionSets.push(actionSet);
                actionSetMap.set(id, actionSets);
                this._actionSets.next(actionSetMap);
            }));
    }

    /**
     * Update a determined actionSet (store & DB)
     * @param id of the entity
     * @param actionSet fresh version of the actionSet to update
     */
    updateActionSet(id: EntityID, actionSet: ActionSet): Observable<void> {
        return this.actionSetService.updateActionSet({ id, actionSet })
            .pipe(
                map(_ => {
                    const actionSetMap = this._actionSets.getValue();
                    const actionSets: Array<ActionSet> = this._getActionSets(id)
                        .map(as =>
                            as.actionSetID === actionSet.actionSetID ? actionSet : as
                        );
                    actionSetMap.set(id, actionSets);
                    this._actionSets.next(actionSetMap);
                })
            );
    }

    /**
     * Delete a determined actionSet (store & DB)
     * @warning Lors de la suppression d'un actionSet sur un alertType donné, le store regénère un actionSet vide, pouvant entrainer des problèmes d'affichage. (affichage de 2 actionSet alors qu'un seul existant)
     * @param id id of the entity
     * @param actionSetID id of the actionSet to delete
     */
    deleteActionSet(id: EntityID, actionSet: ActionSet): Observable<void> {
        return this.actionSetService.deleteActionSet({ id, actionSetID: actionSet.actionSetID, alertType: actionSet.alertType, moduleID: actionSet.moduleID })
            .pipe(
                map(_ => {
                    const actionSetMap = this._actionSets.getValue();
                    const actionSetsFiltered: Array<ActionSet> = this._getActionSets(id).filter(as => as.actionSetID !== actionSet.actionSetID);
                    actionSetMap.set(id, actionSetsFiltered);
                    this._actionSets.next(actionSetMap);
                })
            );
    }

    /**
     * Delete a batch of as
     * @param id id of the entity
     * @param moduleID of the actionSet to delete
     */
    deleteActionSetsByModuleID(id: EntityID, moduleID: ActionSet['moduleID']): Observable<void> {
        const actionSetMap = this._actionSets.getValue();
        const requests$ = actionSetMap.get(id)
            .filter(as => as.moduleID === moduleID)
            .map(a => this.deleteActionSet(id, a))
        return !requests$.length ? of(undefined) : combineLatest(requests$)
            .pipe(
                map(_ => {
                    const actionSetMap = this._actionSets.getValue();
                    const actionSetsFiltered: Array<ActionSet> = this._getActionSets(id).filter(as => as.moduleID !== moduleID);
                    actionSetMap.set(id, actionSetsFiltered);
                    this._actionSets.next(actionSetMap);
                })
            );
    }

    /**
     * Replace the whole list of actionSets, without any filter
     * @param id id of the entity
     * @param actionSets whole list of actionSet assocaited to id
     */
    setActionSets(id: EntityID, actionSets: Array<ActionSet>) {
        const actionSetMap = this._actionSets.getValue();
        // prendre en charge le update/add/delete

        const helperIDs = this.helperStoreService.getHelpers().map(u => u.getUserID());
        const filteredUserIDActionSet: Array<ActionSet> = actionSets
            .map(actionSet => {
                if (actionSet.whatsApps) {
                    actionSet.whatsApps.forEach(wa => {
                        wa.userIDs = wa.userIDs.filter(userID => helperIDs.includes(userID))
                    })
                }
                return actionSet;
            });
        const filteredWhatsAppActionSet: Array<ActionSet> = filteredUserIDActionSet
            .map(actionSet => {
                if (actionSet.whatsApps) {
                    actionSet.whatsApps = actionSet.whatsApps.filter(wa => wa.userIDs.length > 0)
                }
                return actionSet;
            })

        actionSetMap.set(id, filteredWhatsAppActionSet);
        this._actionSets.next(actionSetMap);
    }

    /**
     * Retrieve from the store the actionSets
     * @param id [optional] user or group id
     */
    getActionSets$(id?: EntityID): Observable<Array<ActionSet>> {
        return this.actionSets$.pipe(
            map(actionSets => id ? actionSets.get(id) ?? [] : this.iterateOverMap(actionSets))
        );
    }
    getActionSets(id?: EntityID): Array<ActionSet> {
        if (id) {
            return this._actionSets.getValue().get(id) ?? [];
        } else {
            return this.iterateOverMap(this._actionSets.getValue());
        }
    }
    getActionSetsByAlertType(id: EntityID, moduleID: string, alertType: ActionSet['alertType']): Observable<Array<ActionSet>> {
        return this.getActionSets$(id).pipe(
            map(actionSets => actionSets.filter(as => as.alertType === alertType && as.moduleID === moduleID))
        );
    }

    backupActionSetsRemovingOlders(id: string, moduleID: GeneralModule['moduleID']): Observable<unknown> { // Attention !
        return this.actionSetService.deleteActionSets({ id: id, moduleID: DEFAULT }).pipe(
            switchMap(_ => this.fetchActionSets(id)),
            switchMap(_ => {
                const actionSets = this._getActionSets(id).filter(as => as.moduleID === moduleID);
                const defaultAS: Observable<void>[] = actionSets.filter(as => !as.isEmpty()).map(as => {
                    const actionSet = new ActionSet({ ...as, actionSetID: ActionSet.getUniqueId(), moduleID: DEFAULT });
                    return this.addActionSet(id, actionSet);
                });
                return defaultAS.length ? forkJoin(defaultAS) : of([]);
            })
        );
    }

    copySettings(id: string, deviceTypes: Array<DeviceType>, groupSettingsList: string[], concernedUsers: Array<string>, moduleIDs?: Array<string>, moduleIDSource?: string): Observable<void> {
        return this.actionSetService.copySettings(id, deviceTypes, groupSettingsList, concernedUsers, moduleIDs, moduleIDSource);
    }
    replaceBy(sourceActionsSets: Array<ActionSet>, id: string, destinationModuleIDs: Array<GeneralModule['moduleID']>): Observable<unknown> {
        const alertTypes = sourceActionsSets.reduce((acc: Array<Constants.Alert.AlertType>, val: ActionSet) => {
            if (!acc.includes(val.alertType)) {
                acc.push(val.alertType);
            }
            return acc;
        }, []);
        const actions$ = this._getActionSets(id)
            .filter(as => alertTypes.includes(as.alertType) && destinationModuleIDs.includes(as.moduleID))
            .map(as => this.deleteActionSet(id, as));
        sourceActionsSets.forEach(as => {
            destinationModuleIDs.forEach(moduleID => {
                const newAs = new ActionSet({ ...as, actionSetID: ActionSet.getUniqueId(), moduleID });
                actions$.push(this.addActionSet(id, newAs));
            })
        })
        return forkJoin(actions$);
    }
    updateRules(rules: IRules, id: string, moduleIDs?: Array<string>) {
        const actions$: Array<Observable<unknown>> = this._getActionSets(id)
            .filter(as => as.alertType === ALERT.AlertType.ZONE_EXIT && moduleIDs.includes(as.moduleID))
            .map(as => {
                as.rules = rules;
                return this.updateActionSet(id, as)
            });
        return forkJoin(actions$);
    }
    generateNewActionSet(moduleID: string, alertType: ALERT.AlertType): ActionSet {
        const tr = new TimeRange({ from: 0, to: 10080 });
        return new ActionSet({
            moduleID,
            alertType,
            name: '',
            active: false,
            actionSetID: ActionSet.getUniqueId(),
            timeRanges: [tr],
            rules: new Rules({ zone_exit_type: ZoneExitType.ROOM } as any)
        });
    }
    clean() {
        this._reset();
    }
    private _getActionSets(id?: EntityID) {
        return id ? (this._actionSets.getValue().get(id) ?? []) : this.iterateOverMap(this._actionSets.getValue());
    }
    private _reset() {
        this._actionSets.next(new Map());
    }

    private iterateOverMap(actionSetMap: ActionSetMapping): Array<ActionSet> {
        let actionSets: Array<ActionSet> = [];
        for (let values of actionSetMap.values()) {
            actionSets = actionSets.concat(values);
        }
        return actionSets;
    }
}
