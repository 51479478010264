import { Injectable } from '@angular/core';
import { MethodApi } from '../../api/api-request';
import { Observable } from 'rxjs/internal/Observable';
import { Event } from '@co-assist/library';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    constructor(
        private api: MethodApi
    ) { }

    logIn(data: Event.AuthenticationRegistration.LoginEvent): Observable<Event.AuthenticationRegistration.LoginResponse> {
        return this.api.postAuthenticationRequest('login', data);
    }
    verifyEmail(data: Event.AuthenticationRegistration.VerifyEvent): Observable<Event.AuthenticationRegistration.VerifyResponse> {
        return this.api.postAuthenticationRequest('verify', data);
    }
    askResetPassword(data: Event.AuthenticationRegistration.AskResetPasswordEvent): Observable<Event.AuthenticationRegistration.AskResetPasswordResponse> {
        return this.api.postAuthenticationRequest('askResetPassword', data);
    }
    resetPassword(data: Event.AuthenticationRegistration.ResetPasswordEvent): Observable<Event.AuthenticationRegistration.ResetPasswordResponse> {
        return this.api.postAuthenticationRequest('resetPassword', data);
    }
    deleteRelation(data: Event.AuthenticationRegistration.DeleteRelationEvent): Observable<Event.AuthenticationRegistration.DeleteRelationResponse> {
        return this.api.postAuthenticationRequest('deleteRelation', data);
    }
    verifyAccount(data: Event.AuthenticationRegistration.VerifyAccountEvent): Observable<Event.AuthenticationRegistration.VerifyAccountResponse> {
        return this.api.postAuthenticationRequest('verifyAccount', data);
    }
    verifyCodeValue(data: Event.AuthenticationRegistration.VerifyCodeValueEvent): Observable<Event.AuthenticationRegistration.VerifyResponse> {
        return this.api.postAuthenticationRequest('verifyCodeValue', data);
    }
    deleteGroupUser(data: Event.AuthenticationRegistration.DeleteGroupUserEvent): Observable<Event.AuthenticationRegistration.DeleteGroupUserResponse> {
        return this.api.postAuthenticationRequest('deleteGroupUser', data);
    }
    addUserHelper(data: Event.AuthenticationRegistration.AddHelperEvent): Observable<Event.AuthenticationRegistration.AddHelperResponse> {
        return this.api.postAuthenticationRequest('addHelper', data);
    }
    addGroupHelper(data: Event.AuthenticationRegistration.CreateGroupHelperEvent): Observable<Event.AuthenticationRegistration.CreateGroupHelpedResponse> {
        return this.api.postAuthenticationRequest('createGroupHelper', data);
    }
    addGroupHelped(data: Event.AuthenticationRegistration.CreateGroupHelpedEvent): Observable<Event.AuthenticationRegistration.CreateGroupHelpedResponse> {
        return this.api.postAuthenticationRequest('createGroupHelped', data);
    }
    updateLoginID(data: Omit<Event.AuthenticationRegistration.updateLoginIDEvent, 'action'>): Observable<Event.AuthenticationRegistration.ManageLoginIDResponse> {
        return this.api.postAuthenticationRequest('manageLoginID', { ...data, action: Event.AuthenticationRegistration.ManageLoginIDEAction.update });
    }
    getLoginID(data: Omit<Event.AuthenticationRegistration.getLoginIDEvent, 'action'>): Observable<Event.AuthenticationRegistration.ManageLoginIDResponse> {
        return this.api.postAuthenticationRequest('manageLoginID', { ...data, action: Event.AuthenticationRegistration.ManageLoginIDEAction.get });
    }
}
