import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from 'environments/environment';

export class TranslateStaticLoader implements TranslateLoader {
    constructor(private http: HttpClient, private prefix: string = 'i18n', private suffix: string = '.json') {
    }

    /**
     * Gets the translations from the server
     * @param lang
     * @returns {any}
     */
    public getTranslation(lang: string): Observable<any> {
        return this.http.get(`${this.prefix}/${lang}${this.suffix}?v=${environment.VERSION}`);
    }
}
