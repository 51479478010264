import { Injectable } from '@angular/core';
import { MethodApi } from '../../api/api-request';
import { Event } from '@co-assist/library';
import { Observable } from 'rxjs';
import { Module } from '../../models/Module';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ModuleService {

    constructor(private api: MethodApi) { }

    releaseModule(event: Event.Module.ReleaseModuleEvent): Observable<Event.Module.ReleaseModuleResponse> {
        return this.api.postModuleRequest('releaseModule', event);
    }
    assignModule(event: Event.Module.AssignModuleToUserEvent): Observable<Event.Module.AssignModuleToUserResponse> {
        return this.api.postModuleRequest('assignModuleToUser', event);
    }
    getModulesByWearer(event: Event.Module.GetModulesByWearerEvent): Observable<Array<Module>> {
        return this.api.postModuleRequest('getModulesByWearer', event).pipe(
            map((response: Event.Module.GetModulesByWearerResponse) => response.map(module => new Module(module)))
        );
    }
    getModulesByGroupID(event: Event.Module.GetModulesByGroupIDEvent): Observable<Array<Module>> {
        return this.api.postModuleRequest('getModulesByGroupID', event).pipe(
            map((response: Event.Module.GetModulesByGroupIDResponse) => response.map(module => new Module(module)))
        );
    }
    getModuleState(data: Event.Module.ModuleStateEvent): Observable<Event.Module.ModuleStateResponse> {
        return this.api.postModuleRequest('moduleState', data);
    }
    updateGeneralModule(data: Event.Module.UpdateGeneralModuleEvent): Observable<Event.Module.UpdateGeneralModuleResponse> {
        return this.api.postModuleRequest('updateGeneralModule', data);
    }
    updateModuleSetting(data: Event.Module.UpdateModuleSettingEvent): Observable<Event.Module.UpdateModuleSettingResponse> {
        return this.api.postModuleRequest('updateModuleSetting', data);
    }
    findModulesAssociatedToUser(userID: string, modules: Array<Module>): Array<Module> {
        return modules.filter(m => m.getWearer() === userID);
    }
}

