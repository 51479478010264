<app-page-layout [displayCustomerService]="true">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <mat-card>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-12">
                                <h1>{{'ERROR.404.TITLE' | translate}}</h1>
                                <h3>{{'ERROR.404.BODY' | translate}}</h3>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button color="primary" class="full-width" (click)="goToLogin()">
                            {{'COMMON.GO_TO_LOGIN' | translate}}
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</app-page-layout>
