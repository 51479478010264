import { Injectable } from '@angular/core';
import { MethodApi } from '../../api/api-request';
import { Event, AlertProcess } from '@co-assist/library';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProcessService {
    constructor(private api: MethodApi) { }

    addProcess(event: Event.User.AddProcessEvent): Observable<AlertProcess> {
        return this.api.postUserRequest('addProcess', event);
    }
    updateProcess(event: Event.User.UpdateProcessEvent): Observable<AlertProcess> {
        return this.api.postUserRequest('updateProcess', event);
    }
    deleteProcess(event: Event.User.DeleteProcessEvent): Observable<void> {
        return this.api.postUserRequest('deleteProcess', event);
    }
    getProcesses(event: Event.User.GetProcessesEvent): Observable<Array<AlertProcess>> {
        return this.api.postUserRequest('getProcesses', event);
    }

    retrieveProcesses(userID: string, groupID: string): Observable<Array<AlertProcess>> {
        return forkJoin([
            userID ? this.getProcesses({ id: userID }) : of([]),
            groupID ? this.getProcesses({ id: groupID }) : of([])
        ]).pipe(
            map(processes => {
                return processes[0].concat(processes[1]);
            })
        );
    }
}
