import { Injectable } from '@angular/core';
import { CookieService } from 'app/services/utilities/cookie.service';
import * as moment from 'moment-timezone';

@Injectable()
export class DateTimeService {
    private defaultTimezone = 'Europe/Paris';
    private enSizeFormat: Map<string, string> = new Map(
        [
            ['time', 'hh:mm:ss'],
            ['short', 'DD/MM/YYYY'],
            ['medium', 'DD/MM/YYYY - hh:mm a'],
            ['long', 'dddd, D MMMM [at] H:mm:ss']
        ]);
    private frSizeFormat: Map<string, string> = new Map(
        [
            ['time', 'HH:mm:ss'],
            ['short', 'DD/MM/YYYY'],
            ['medium', 'DD/MM/YYYY - HH:mm'],
            ['long', 'dddd D MMMM à HH:mm:ss']
        ]);
    constructor(private cookie: CookieService) { }

    toString(timeStamp: number, timeZone?: string, locale?: string, format?: string): string {
        if (this.isValid(timeStamp)) {
            let localTZ;
            let localLanguage;
            let localFormat;
            let localSizeFormat;
            let finalDate;
            let localTS: number = Math.round(timeStamp);
            // length of the timestamp must be 10
            ((Number(localTS) > Number(9999999999))) ?
                localTS = Math.round(localTS / 1000) : localTS = localTS;

            (format === 'time' || format === 'short' || format === 'medium' || format === 'long') ?
                localSizeFormat = format : localSizeFormat = 'long';
            (locale && (locale === 'fr' || locale === 'en')) ?
                localLanguage = locale : localLanguage = this.getCookieLanguage();
            (localLanguage && localLanguage === 'en') ?
                localFormat = this.enSizeFormat.get(localSizeFormat) : localFormat = this.frSizeFormat.get(localSizeFormat);

            (timeZone && timeZone != null) ?
                localTZ = timeZone : localTZ = this.getCookieTimeZone();
            finalDate = moment.unix(localTS).locale(localLanguage).tz(localTZ).format(localFormat);
            // french days and months name are not uppercased usually
            return (finalDate.charAt(0).toUpperCase() + finalDate.slice(1));
        } else {
            return 'Une erreur est survenue';
        }
    }

    private isValid(timeStamp: number): boolean {
        return (timeStamp >= 0 && timeStamp <= 9999999999999);
    }

    private getCookieLanguage(): string {
        let language = this.cookie.get('language');
        (language === '' || language === 'fr') ? language = 'fr' : language = 'en';
        return language;
    }

    private getCookieTimeZone(): string {
        let timeZone = this.cookie.get('timezone');
        ((moment.tz.names()).indexOf(timeZone) !== -1) ? timeZone = timeZone : timeZone = this.defaultTimezone;
        return timeZone;
    }
}
