<app-page-layout [title]="'PASSWORD_RESET.RESET_PASSWORD'" [displayCustomerService]="false">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title class="center" id="app-login-title">{{'LOGIN.FORM_TITLE' | translate}}
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div *ngIf="errorMatcher.isError()" class="alert custom-danger text-left" role="alert">
                            <p id="app-login-errors" class="error">
                                <mat-icon class="danger">error</mat-icon>
                                <ng-container *ngIf="!errorMatcher.isMissingVerification();else link">
                                    <span *ngFor="let error of errorMatcher.logError">{{error.message |
                                        translate:{arg:error.arg} }}</span>
                                </ng-container>
                                <ng-template #link>
                                    <a (click)="verifyAccount()"
                                        *ngFor="let error of errorMatcher.logError">{{error.message |
                                        translate:{arg:error.arg} }}</a>
                                </ng-template>
                            </p>
                        </div>
                        <form [formGroup]="form" class="row">
                            <div class="col-md-12">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>{{'COMMON.USER_NAME_PLACEHOLDER' | translate}}</mat-label>
                                    <input matInput formControlName="loginID" type="text"
                                        [placeholder]="'COMMON.USER_NAME_PLACEHOLDER' | translate"
                                        id="app-login-login-input" name="first">
                                    <mat-error>{{'VALIDATION.REQUIRED'|translate}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>{{'LOGIN.PASSWORD_PLACEHOLDER' | translate}}</mat-label>
                                    <input matInput formControlName="password"
                                        [placeholder]="'LOGIN.PASSWORD_PLACEHOLDER' | translate"
                                        id="app-login-password-input" name="password"
                                        [type]="hide ? 'password' : 'text'">
                                    <mat-icon matSuffix (click)="hide = !hide">
                                        {{hide ? 'visibility' : 'visibility_off'}}
                                    </mat-icon>
                                    <mat-error>{{'VALIDATION.REQUIRED'|translate}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div *ngIf="isSuperAdmin" class="col-md-12">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>{{'LOGIN.OTP_PASSWORD' | translate}}
                                    </mat-label>
                                    <input matInput formControlName="oneTimePassword" type="text"
                                        [placeholder]="'LOGIN.OTP_PASSWORD' | translate" name="oneTimePassword">
                                    <mat-error>
                                        {{'VALIDATION.REQUIRED'|translate}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 spacer">
                                <button mat-raised-button color="primary" [disabled]="isLoading" class="full-width"
                                    (click)="login()" name="loginButton" id="app-login-submit-button">
                                    {{'LOGIN.LOGIN_BUTTON' | translate}}
                                </button>
                            </div>
                            <div class="col-md-12 center">
                                <button id="hrefAskResetPassword" mat-button color="primary"
                                    (click)="forgottenPassword()">
                                    {{'LOGIN.FORGOT_PASSWORD_LINK' | translate}}</button>
                            </div>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</app-page-layout>