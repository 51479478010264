import '@angular/compiler';
import './polyfills.ts';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";

if (environment.PRODUCTION && !environment.LOCAL) {
    enableProdMode();
}
if (!environment.LOCAL) {
    Sentry.init({
        dsn: "https://3f850bb620cb48cc8a8e0cd8c266e93a@o25812.ingest.sentry.io/1494891",
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
            // Registers the Replay integration,
            // which automatically captures Session Replays
            new Sentry.Replay(),
        ],
        environment: environment.STAGE,
        release: environment.VERSION,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.3,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0, // capture 100% of sessions with an error
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(ref => {
        if (window['ngRef']) { // Ensure Angular destroys itself on hot reloads.
            window['ngRef'].destroy();
        }
        window['ngRef'] = ref;
    })
    .catch(err => {
        console.error('Handle bootstrap level error', err);
        window.location.reload();
    });
