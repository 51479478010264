import { Injectable } from '@angular/core';
import { GeneralUser, IGeneralGroup, IGeneralUser } from '@co-assist/library';
import { combineLatest, EMPTY, firstValueFrom, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HelpedStoreService } from 'app/services/store/helped-store.service';
import { LoggedUserStoreService } from 'app/services/store/logged-user-store.service';
import { ModuleStoreService } from 'app/services/store/module-store.service';
import { EntityService } from 'app/services/data/entity.service';
import { HelperStoreService } from '../store/helper-store.service';

@Injectable({
    providedIn: 'root'
})
export class GeneralService {
    constructor(
        private loggedUserStoreService: LoggedUserStoreService,
        private moduleStoreService: ModuleStoreService,
        private helpedStoreService: HelpedStoreService,
        private helperStoreService: HelperStoreService,
        private entityService: EntityService
    ) { }

    fetchData$(user?: GeneralUser): Observable<boolean> {
        return this.fetchCommonData$(user).pipe(
            switchMap(_ => {
                return this.fetchAlertRelatedData$(user)
            })
        )
    }
    fetchCommonData$(user?: GeneralUser): Observable<boolean> {
        const _loggedUser = user || this.loggedUserStoreService.getLoggedUser();
        return this.helpedStoreService.fetchHelpeds().pipe(
            switchMap(_ => {
                const request$: Array<Observable<unknown>> = [];
                if (_loggedUser.isB2B() &&
                    (this.helpedStoreService.getHelpeds().find(h => h.getUserID() === _loggedUser.getUserID())) === undefined) { // B2B + aidant
                    request$.push(this.moduleStoreService.fetchModulesByGroupID$(_loggedUser.getGroupID()));
                } else { // B2C ou B2B + aidé
                    this.helpedStoreService.getHelpeds().map(h => request$.push(this.moduleStoreService.fetchModulesByUserID$(h.userID)));
                }
                return forkJoin(request$).pipe(
                    map(_ => true)
                )
            })
        );
    }

    fetchAlertRelatedData$(user?: GeneralUser): Observable<boolean> {
        const _loggedUser = user || this.loggedUserStoreService.getLoggedUser();
        const isGroup: boolean = _loggedUser.isB2B();
        return forkJoin([
            this.entityService.getHelpersOf(isGroup ? { groupID: _loggedUser.getGroupID() } : { userID: this.helpedStoreService.getCurrentHelpedID() }),
            isGroup ? this.loggedUserStoreService.fetchGroup$(_loggedUser.group) : of(undefined)
        ]).pipe(
            map(([helpers = [], group = undefined]: [Array<IGeneralUser>, IGeneralGroup]) => {
                this.helperStoreService.setHelpers(helpers);
                if (group) { return this.helperStoreService.fetchAdmins(group.groupID); }
                return EMPTY;
            }),
            map(_ => {
                return true;
            })
        )
    }


    async fetchAlertRelatedData(user?: GeneralUser): Promise<boolean> {
        const _loggedUser = user || this.loggedUserStoreService.getLoggedUser();
        const isGroup: boolean = _loggedUser.isB2B();
        return firstValueFrom(forkJoin([
            this.entityService.getHelpersOf(isGroup ? { groupID: _loggedUser.getGroupID() } : { userID: this.helpedStoreService.getCurrentHelpedID() }),
            isGroup ? this.loggedUserStoreService.fetchGroup$(_loggedUser.group) : of(undefined)
        ]).pipe(
            map(([helpers = [], group = undefined]: [Array<IGeneralUser>, IGeneralGroup]) => {
                this.loggedUserStoreService.setLoggedGroup(group);
                if (group) { this.helperStoreService.fetchAdmins(group.groupID); }
                this.helperStoreService.setHelpers(helpers);
                return true;
            })
        ))
    }

    async _fetchData(user?: GeneralUser): Promise<boolean> {
        const _loggedUser = user || this.loggedUserStoreService.getLoggedUser();
        const isGroup: boolean = _loggedUser.isB2B();
        await firstValueFrom(this.helpedStoreService.fetchHelpeds().pipe(
            switchMap((_) => {
                return combineLatest([
                    this.helperStoreService.fetchHelpers(isGroup ? { groupID: _loggedUser.getGroupID() } : { userID: this.helpedStoreService.getCurrentHelpedID() }),
                    isGroup ? this.helperStoreService.fetchAdmins$(_loggedUser.getGroupID()) : of(undefined),
                    isGroup ? this.loggedUserStoreService.fetchGroup$(_loggedUser.group) : of(undefined)
                ]).pipe(
                    switchMap(([_, __, group]: [void, void, IGeneralGroup]) => {
                        if (group) { this.helperStoreService.fetchAdmins(group.groupID); }
                        return combineLatest(isGroup ?
                            [this.moduleStoreService.fetchModulesByGroupID(_loggedUser.getGroupID())] :
                            this.helpedStoreService.getHelpeds().map(h => this.moduleStoreService.fetchModulesByUserID(h.userID))
                        );
                    })
                )
            })
        ))
        return true;
    }
}
