import { FileService } from './../services/data/file.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Event } from '@co-assist/library';

export enum AutoCompletionType {
    USER = 'userList',
    GROUP = 'groupList'
}

@Injectable()
export class AutoCompletionService {
    private valuesArray: IValuesArray = { userList: '', groupList: '' };

    constructor(
        private fileService: FileService,
        private translate: TranslateService,
        private toastr: ToastrService
    ) { }

    /**
     * update localstorage value for userList and groupList if remote file is different
     */
    initAutocompletionFile() {
        const apiRequest: Event.File.GetAutoCompletionFileEvent[] = [
            { fileName: AutoCompletionType.USER, lastTag: '' },
            { fileName: AutoCompletionType.GROUP, lastTag: '' }
        ];
        apiRequest.forEach(request => {
            request.lastTag = localStorage.getItem(request.fileName)?.split(';').pop().split(':')[1] ?? '-1';
            this.fileService.getAutoCompletionFile(request).subscribe({
                next: data => {
                    if (data) {
                        localStorage.setItem(request.fileName, data);
                    }
                    this.valuesArray[request.fileName] = localStorage.getItem(request.fileName)?.split('tag:')[0] ?? '';
                },
                error: () => {
                    this.toastr.warning(this.translate.instant('AUTOCOMPLETION.ERROR_API'));
                }
            });
        });
    }

    /**
     * Clear the autocompletion entries in localStorage and reset the autocompletion list
     */
    clearData() {
        const clearList = Object.values(AutoCompletionType);
        clearList.forEach(value => {
            this.valuesArray[value] = '';
            localStorage.removeItem(value);
        });
    }

    /**
     * return a filtered autocompletion list
     * @param filterValue
     * @return filtered autocompletion list
     */
    getAutoCompletionArray(autocomplete: AutoCompletionType, filterValue: string): string[] {
        const reg = new RegExp(`([^;]*${this.escapeRegExp(filterValue)}[^;]*)(?=;)`, 'gi');
        return (filterValue.length > 0 && this.valuesArray[autocomplete]?.match(reg)?.splice(0, 100)) || [];
    }

    /**
     * escape regex expression from value
     */
    private escapeRegExp(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
}

/**
 * Extract userID from an autocomplete result
 */
export function getUserID(res: string) {
    return res.substring(res.indexOf('(') + 1, res.indexOf(')'));
}

interface IValuesArray {
    userList: string;
    groupList: string;
}
