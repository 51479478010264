import { AuthenticationService } from 'app/services/data/authentication.service';
import { Component, OnInit } from '@angular/core';
import { MethodApi } from '../api/api-request';
import { ErrorMatcher } from 'app/login/error-matcher';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-ask-reset-password',
    templateUrl: './ask-reset-password.component.html',
    styleUrls: ['./ask-reset-password.component.scss']
})
export class AskResetPasswordComponent implements OnInit {
    isLoading: boolean = false;
    hasBeenReset: boolean = false;
    form: UntypedFormGroup;
    second: number = 4;

    constructor(
        public errorMatcher: ErrorMatcher,
        private activatedRoute: ActivatedRoute,
        private api: MethodApi,
        private fb: UntypedFormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.errorMatcher.clear();
    }

    get loginID(): string {
        return this.activatedRoute.snapshot.params.loginID;
    }

    ngOnInit() {
        this.form = this.fb.group({
            loginID: [this.loginID, [Validators.required]]
        });
    }

    submit() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.isLoading = true;
            this.authenticationService.askResetPassword({ loginID: this.form.get('loginID').value })
                .subscribe(
                    () => {
                        this.redirect();
                        this.hasBeenReset = true;
                        this.isLoading = false;
                    },
                    err => {
                        this.errorMatcher.setError(err);
                        this.isLoading = false;
                    }
                );
        }
    }
    redirect(): void {
        let id = setInterval(() => {
            if (this.second === 0) {
                clearInterval(id);
                this.router.navigate(['login']);
            } else {
                this.second--;
            }
        }, 1000);
    }
    verifyAccount() {
        this.router.navigate(['/re-verify', this.form.get('loginID').value]);
    }
}
