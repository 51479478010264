import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoadingComponent } from './sharedComponent/main-component/loading/loading.component';
import { NgModule } from '@angular/core';
import { AuthGuardService } from './router/auth-guard.service';
import { ContentResolver } from './resolvers/content.resolver';
import { AuthGuardMailService } from './router/auth-guard-mail.service';
import { TelemetryResolver } from './resolvers/telemetry.resolver';

const routes: Routes = [
    {
        path: 'content', loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
        resolve: [ContentResolver], //fetch
        canLoad: [AuthGuardService],  // cookie
        canActivate: [AuthGuardService],
        data: { preload: true }
    },
    {
        path: 'telemetry', loadChildren: () => import('./telemetry/telemetry-routing').then(m => m.TelemetryModule),
        resolve: [TelemetryResolver],
        canLoad: [AuthGuardService],
        canActivate: [AuthGuardService]
    },
    {
        path: 'administration', loadChildren: () => import('./user-overview/user-overview.module').then(m => m.UserOverviewModule),
        canActivate: [AuthGuardService],
        resolve: [AuthGuardService]
    },
    { path: 'hub', loadComponent: () => import('./hub/hub.component').then(m => m.HubComponent) },
    { path: 'login', component: LoginComponent },
    { path: 'handle-alert', loadChildren: () => import('./sharedComponent/sharedActivesAlerts/sharedActivesAlerts.module').then(m => m.SharedActivesAlertsModule), canLoad: [AuthGuardMailService] },
    { path: 'subscription', loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule) },
    { path: 'catalog', loadChildren: () => import('./catalog/catalog.module').then(m => m.CatalogModule) },
    { path: 'ask-reset-password/:loginID', loadChildren: () => import('./ask-reset-password/ask-reset-password.module').then(m => m.AskResetPasswordModule) },
    { path: 'ask-reset-password', loadChildren: () => import('./ask-reset-password/ask-reset-password.module').then(m => m.AskResetPasswordModule) },
    { path: 'reset-password', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
    { path: 'verify', loadChildren: () => import('./email-checker/email-checker.module').then(m => m.EmailCheckerModule) },
    { path: 're-verify/:loginID', loadChildren: () => import('./verify-account/verify-account.module').then(m => m.VerifyAccountModule) },
    { path: 'payment-status/:success', loadChildren: () => import('./sharedComponent/main-component/payment-status/payment-status.module').then(m => m.PaymentStatusModule) },
    { path: 'payment-success', redirectTo: '/payment-status/true' },
    { path: 'payment-failure', redirectTo: '/payment-status/false' },
    { path: '404', component: NotFoundComponent },
    { path: 'refresh', component: LoadingComponent },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: '/404' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { enableTracing: false, preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule { }
