import env from '../../env.json';
/**
 * ng build / serve--configuration = production
    * @type { { STAGE: string, PRODUCTION: boolean, LOCAL: boolean } }
 */
export const environment = {
    STAGE: 'prod',
    ERP_URL: 'https://erp.co-assist.fr/#/',
    PRODUCTION: true,
    LOCAL: false,
    VERSION: env.version,
    GTAG: 'GTM-WQDD5G',
    API_KEY: '1IBxV4k8qs2VhQqvtzzIn3dMK47Lr5Wk6gvhmcck',
    GMAP_API_KEY: 'AIzaSyBUx9N08Eab7jbYHNcNPErOR4oo86sdnng',
};
