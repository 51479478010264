import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { MethodApi } from '../../api/api-request';
import { Observable } from 'rxjs';
import { Event, StockItem } from '@co-assist/library';
@Injectable({
    providedIn: 'root'
})
export class FileService {
    constructor(
        private api: MethodApi
    ) { }

    getUserFile(data: Event.File.GetUserFileEvent): Observable<Event.File.GetUserFileResponse> {
        return this.api.postFileRequest('getUserFile', data);
    }
    getContractFile(event: Event.File.GetContractFileEvent): Observable<Event.File.GetContractFileResponse> {
        return this.api.postFileRequest('getContractFile', event);
    }
    getInvoiceFile(event: Event.File.GetInvoiceEvent): Observable<Event.File.GetInvoiceResponse> {
        return this.api.postFileRequest('getInvoiceFile', event);
    }
    getAutoCompletionFile(event: Event.File.GetAutoCompletionFileEvent): Observable<Event.File.GetAutoCompletionFileResponse> {
        return this.api.postFileRequest('getAutoCompletionFile', event);
    }
    groupProfilePictureManagement(event: Event.File.GroupProfilePictureManagementEvent): Observable<Event.File.GroupProfilePictureManagementResponse> {
        return this.api.postFileRequest('groupProfilePictureManagement', event);
    }
}
