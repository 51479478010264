import { UntypedFormGroup, UntypedFormControl, FormGroupDirective, NgForm, ValidatorFn, UntypedFormArray, ValidationErrors, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Custom validator functions for reactive form validation
 */
export class CustomValidators {

    /**
    * Validates that child controls in the form group are equal
    */
    static childrenEqual: ValidatorFn = (formGroup: UntypedFormGroup) => {
        const [firstControlName, ...otherControlNames] = Object.keys(formGroup.controls || {});
        const isValid = otherControlNames.every(controlName => formGroup.get(controlName).value === formGroup.get(firstControlName).value);
        return isValid ? null : { childrenNotEqual: true };
    }
    /**
    * Validates that the range is valid
    */
    static rangeValidator: ValidatorFn = (formGroup: UntypedFormGroup) => {
        {
            const start = formGroup.get('from').value;
            const end = formGroup.get('to').value;
            return start !== null && end !== null && start < end
                ? null : { range: true };
        }
    }

    /**
    * Validates that child controles in the formArray don't overlaps
    */
    static overlapsValidator: ValidatorFn = (formArray: UntypedFormArray) => {
        let isValid: boolean = true;
        for (let i = 0; i < formArray.length; i++) {
            const fromInitial: number | null = formArray.at(i).get('from').value;
            const toInitial: number | null = formArray.at(i).get('to').value;
            for (let j = i + 1; j < formArray.length; j++) {
                const from: number | null = formArray.at(j).get('from').value;
                const to: number | null = formArray.at(j).get('to').value;
                if ((from !== null || to !== null) && (
                    (fromInitial <= from && toInitial >= to) ||
                    (fromInitial >= from && toInitial > from && toInitial >= to) ||
                    (fromInitial <= from && from < toInitial && toInitial <= to) ||
                    (fromInitial > from && toInitial < to))) {
                    isValid = false;
                }
            }
        }
        return isValid ? null : { error: 'overlaps' };

    }

    /**
    * Prevent user from tiping malicious code in the text-area
    */
    static jsonValidator: ValidatorFn = (formControl: UntypedFormControl) => {
        const value = formControl.value;
        try {
            JSON.parse(value);
        } catch (e) {
            return { 'maliciousCode': true };
        }
        return null;
    };

    static getUrlQueryReady: ValidatorFn = (formControl: UntypedFormControl) => {
        const value = formControl.value;
        let result = null;
        value.forEach(({ keyUrl, valueUrl }) => {
            if (!regExps.urlReady.test(keyUrl)) {
                result = { 'urlReady ': true }
            }
            if (!regExps.urlReady.test(valueUrl)) {
                result = { 'urlReady ': true }
            }
        })
        return result
    }
}

/**
 * Custom ErrorStateMatcher which returns true (error exists) when the parent form group is invalid and the control has been touched
 */
export class ConfirmValidParentMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, _form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
        return (invalidCtrl || invalidParent);
    }
}

/**
* Collection of reusable RegExps
*/
export const regExps: { [key: string]: RegExp } = {
    password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
    urlReady: /^[^\s%]+[^%]*$|^[^%]*[^\s%]+$|^[^\s%]{1,2}$/ // Don't start or finish w/ a space, no % caracter
};

export const errorMessages: { [key: string]: string } = {
    civility: 'REGISTRATION.FORM.CIVILITY',
    name: 'REGISTRATION.FORM.NAME',
    lastName: 'REGISTRATION.FORM.LAST_NAME',
    firstName: 'REGISTRATION.FORM.FIRST_NAME',
    email: 'REGISTRATION.FORM.EMAIL',
    passwordRequired: 'REGISTRATION.FORM.PASSWORD',
    passwordLength: 'REGISTRATION.FORM.PASSWORD_LENGTH',
    confirmPassword: 'REGISTRATION.FORM.CONFIRM_PASSWORD',
    phoneNumber: 'REGISTRATION.FORM.PHONE_NUMBER',
    phoneNumberFormat: 'REGISTRATION.FORM.PHONE_NUMBER_FORMAT',
    address: 'REGISTRATION.FORM.ADDRESS',
    timeZone: 'REGISTRATION.FORM.TIMEZONE',
    language: 'REGISTRATION.FORM.LANGUAGE',
    addressRadio: 'REGISTRATION.FORM.ADDRESS_RADIO'
};

export class FileValidator {

    static fileMaxSize(maxSize: number): ValidatorFn {
        const validatorFn = (file: File) => {
            if (file instanceof File && file.size > maxSize) {
                return { fileMinSize: { requiredSize: maxSize, actualSize: file.size, file } };
            }
        };
        return FileValidator.fileValidation(validatorFn);
    }

    static fileMinSize(minSize: number): ValidatorFn {
        const validatorFn = (file: File) => {
            if (file instanceof File && file.size < minSize) {
                return { fileMinSize: { requiredSize: minSize, actualSize: file.size, file } };
            }
        };
        return FileValidator.fileValidation(validatorFn);
    }

    /**
     * extensions must not contain dot
     */
    static fileExtensions(allowedExtensions: Array<string>): ValidatorFn {
        const validatorFn = (file: File) => {
            if (allowedExtensions.length === 0) {
                return null;
            }

            if (file instanceof File) {
                const ext = FileValidator.getExtension(file.name);
                if (allowedExtensions.indexOf(ext) === -1) {
                    return { fileExtension: { allowedExtensions: allowedExtensions, actualExtension: file.type, file } };
                }
            }
        };
        return FileValidator.fileValidation(validatorFn);
    }

    private static getExtension(filename: string): null | string {
        if (filename.indexOf('.') === -1) {
            return null;
        }
        return filename.split('.').pop();
    }

    private static fileValidation(validatorFn: (File) => null | object): ValidatorFn {
        return (formControl: AbstractControl): ValidationErrors | null => {
            if (!formControl.value) {
                return null;
            }

            const files: File[] = [];
            const isMultiple = Array.isArray(formControl.value);
            isMultiple
                ? formControl.value.forEach((file: File) => files.push(file))
                : files.push(formControl.value);

            for (const file of files) {
                return validatorFn(file);
            }

            return null;
        };
    }

}
