import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from 'app/services/utilities/language.service';

@Component({
    standalone: true,
    selector: 'app-multilingual',
    templateUrl: './multilingual.component.html',
    styleUrls: ['./multilingual.component.scss'],
    imports: [TranslateModule, MatButtonModule, MatMenuModule, MatIconModule, NgIf, NgFor]
})
export class MultilingualComponent implements OnInit {
    allLanguages = [];
    currentLanguage: string;

    constructor(
        private _languageService: LanguageService
    ) { }

    ngOnInit() {
        this.allLanguages = this._languageService.getLanguageList();
        this.currentLanguage = this._languageService.getLanguage();
    }

    setChangedLanguage(language) {
        this.currentLanguage = language;
        this._languageService.setLanguage(language);
    }
}
