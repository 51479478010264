import { ActionSet } from '@co-assist/library';

export enum TIME_MODE {
    ANALOG = 'analog',
    DIGITAL = 'digital',
    DIGITAL_DDMM = 'digital_ddmm'
}

export function getTimeMode(clockModeValue: number, dateModeValue: number = 0): TIME_MODE {
    if (clockModeValue === 1) {
        if (dateModeValue && dateModeValue === 1) {
            return TIME_MODE.DIGITAL_DDMM;
        }
        return TIME_MODE.DIGITAL;
    }
    return TIME_MODE.ANALOG;
}

export type ManageDeviceGroup = { groupID: string, actionsSets: Array<ActionSet>, realTimeTracker: boolean };
