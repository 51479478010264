import { NgModule } from '@angular/core';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CommonModule } from '@angular/common';
import { TimeZoneComponent } from './time-zone/time-zone.component';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { MainComponentModule } from '../main-component/mainComponent.module';
import { DisplayCardTypeComponent } from '../../dumb-components/display-card-type/display-card-type.component';
import { PaymentMethodFormComponent } from '../main-component/payment-method-form/payment-method-form.component';
import { ShoppingCartComponent } from '../main-component/shopping-cart/shopping-cart.component';
import { ItemInventoryComponent } from '../main-component/item-inventory/item-inventory.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { KeyDownDirective } from '../../directives/keyDown.directive';
import { InputUpperCaseDirective } from '../../directives/input-to-upper-case.directive';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { PageLayoutComponent } from '../../base-component/page-layout/page-layout.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { FullScreenLoaderComponent } from '../main-component/full-screen-loader/full-screen-loader.component';
import { NgxMaskModule } from 'ngx-mask';
import { MultilingualComponent } from '../main-component/multilingual/multilingual.component';

const angularMaterialModule = [
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonModule,
    MatToolbarModule,
    MatTabsModule,
    MatCardModule,
    MatInputModule,
    MatSliderModule,
    MatSnackBarModule,
    MatDialogModule,
    MatChipsModule,
    MatToolbarModule
];
const directives = [
    KeyDownDirective,
    InputUpperCaseDirective
];

registerLocaleData(localeFr, 'fr');
@NgModule({
    imports: [
        MultilingualComponent,
        NgxMaskModule.forRoot({
            validation: false,
            showMaskTyped: true
        }),
        MainComponentModule,
        GoogleMapsModule,
        angularMaterialModule,
        TranslateModule,
        HttpClientModule,
        HttpClientJsonpModule,
        PageLayoutComponent
    ],
    declarations: [
        InfoModalComponent,
        TimeZoneComponent,
        DisplayCardTypeComponent,
        PaymentMethodFormComponent,
        ShoppingCartComponent,
        ItemInventoryComponent,
        FullScreenLoaderComponent,
        directives
    ],
    exports: [
        MultilingualComponent,
        NgxMaskModule,
        InfoModalComponent,
        TimeZoneComponent,
        GoogleMapsModule,
        TranslateModule,
        DisplayCardTypeComponent,
        PaymentMethodFormComponent,
        ShoppingCartComponent,
        ItemInventoryComponent,
        FullScreenLoaderComponent,
        angularMaterialModule,
        HttpClientModule,
        HttpClientJsonpModule,
        CommonModule,
        PageLayoutComponent
    ]
})
export class SharedComponentModule { }
