import { ConstantsService } from './services/utilities/constants.service';
import { ErrorHandlerInterceptor } from './util/error-handler-interceptor';
import { MainComponentModule } from './sharedComponent/main-component/mainComponent.module';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import {
    APP_BASE_HREF,
    LocationStrategy,
    HashLocationStrategy,
    CommonModule
} from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { MethodApi } from './api/api-request';
import { EmailCheckerComponent } from './email-checker/email-checker.component';
import { AskResetPasswordComponent } from './ask-reset-password/ask-reset-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateStaticLoader } from './util/translate-loader';
import { DialogConfirmationComponent } from './dialog-confirmation/dialog-confirmation.component';
import { NotFoundComponent } from './not-found/not-found.component';
import 'moment/min/locales';
import { AutoCompletionService } from './util/id-autocompletion.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserOverviewComponent } from 'app/user-overview/user-overview.component';
import { AuthGuardService } from './router/auth-guard.service';
import { ErrorHandler } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { PaymentStatusComponent } from './sharedComponent/main-component/payment-status/payment-status.component';
import { ManageAccess } from './util/manage-access';
import { PageStatus } from './util/page-status';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { UserIDDirective } from './directives/userID.directive';
import { SharedComponentModule } from './sharedComponent/register-content/sharedComponent.module';
import { ErrorMatcher } from './login/error-matcher';
import { AutocompleteFormFieldComponent } from './custom-component/autocomplete-form-field/autocomplete-form-field.component';
import { LanguageService } from './services/utilities/language.service';
import { PaymentMethodValidatorService } from './services/utilities/payment-method-validator.service';
import { UpdateService } from './services/utilities/update.service';
import { ActionSetResolver } from './resolvers/action-set.resolver';
import { ContentResolver } from './resolvers/content.resolver';
import { ProcessResolver } from './resolvers/process.resolver';
import { CatalogComponent } from './catalog/catalog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DialogUpdateProfilePictureComponent } from './dialog/dialog-update-profile-picture/dialog-update-profile-picture.component';
import { FilePickerDirective } from './directives/file-picker.directive';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';
import { AuthGuardMailService } from './router/auth-guard-mail.service';
import { TelemetryResolver } from './resolvers/telemetry.resolver';
import { DateTimeService } from './services/utilities/date-time.service';
import { A11yModule } from '@angular/cdk/a11y';
import { NewProfileDialogComponent } from './content/manage-devices/alerts-by-profile.component/new-profile-dialog/new-profile-dialog.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

let usefulDeclarations: any = [DialogConfirmationComponent];

let componentWithoutLogin: any = [
    LoginComponent,
    EmailCheckerComponent,
    AskResetPasswordComponent,
    ResetPasswordComponent
];

let angularMaterial: any = [
    MatToolbarModule,
    MatTabsModule,
    MatCardModule,
    MatRadioModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    DragDropModule,
    A11yModule,
    MatSliderModule,
    MatSnackBarModule,
    MatDialogModule,
    MatNativeDateModule
];

let directives: any = [UserIDDirective];

const resolvers = [
    ProcessResolver,
    TelemetryResolver,
    ContentResolver,
    ActionSetResolver];
@NgModule({
    declarations: [
        AppComponent,
        usefulDeclarations,
        componentWithoutLogin,
        NotFoundComponent,
        UserOverviewComponent,
        VerifyAccountComponent,
        PaymentStatusComponent,
        directives,
        AutocompleteFormFieldComponent,
        CatalogComponent,
        DialogUpdateProfilePictureComponent,
        NewProfileDialogComponent,
        FilePickerDirective
    ],
    imports: [
        MainComponentModule,
        SharedComponentModule,
        AppRoutingModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        angularMaterial,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('./ngsw-worker.js', {
            // enabled: !environment.LOCAL,
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    exports: [],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        MethodApi,
        ManageAccess,
        ErrorMatcher,
        PageStatus,
        AuthGuardService,
        AuthGuardMailService,
        AutoCompletionService,
        ConstantsService,
        UpdateService,
        PaymentMethodValidatorService,
        LanguageService,
        DateTimeService,
        resolvers,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        translate: TranslateService,
        languageService: LanguageService,
        dateAdapter: DateAdapter<any>
    ) {
        translate.setDefaultLang(languageService.defaultLanguage);
        translate.use(languageService.getLanguage());
        dateAdapter.setLocale(languageService.getLanguage());
    }
}
