import { Constants, Event, GeneralCaw, ModuleGeneral, ModuleSetting, Entity, GeneralLHT65NLR, GeneralZEECDMINOLR } from '@co-assist/library';

export interface IModule {
    general: ModuleGeneral;
    states?: ModuleStatesInfo;
    settings?: Array<ModuleSetting>;
}

export class Module implements IModule {
    general: ModuleGeneral;
    states?: ModuleStatesInfo;
    settings?: Array<ModuleSetting>;

    constructor(object: IModule) {
        this.general = object.general;
        this.states = object.states;
        this.settings = object.settings;
    }

    getWearer(): string | undefined {
        return this.general.userID;
    }

    getModuleID(): string {
        return this.general.moduleID;
    }

    getDeviceType(): Constants.Entity.DeviceType {
        return this.general.deviceType;
    }

    getSettingValue(name: string): any | undefined {
        const temp = this.settings && this.settings.find(setting =>
            setting.name === name
        );
        return temp && temp.value ? temp.value : 0;
    }

    getSetting(name: string): ModuleSetting | undefined {
        return this.settings?.find(setting => setting.name === name);
    }

    /**
     * If the module is associated and has a module state, return the serviceState value
     * @returns module
     */
    getServiceStateValue(): Constants.Module.ModuleState | undefined {
        return this.getWearer() && this.states?.serviceState?.moduleState;
    }
    getConnectivityValue(): Constants.Module.Connectivity {
        return this.states?.connectivityState?.value;
    }
    getServiceState() {
        return this.states?.serviceState;
    }
    getConnectivityState() {
        return this.states?.connectivityState;
    }
    getExternalProbePresence() {
        if (this.general.deviceType === Constants.Entity.DeviceType.LHT65NLR)
            return this.general.onExternalProbe;
    }
    getWaterTemperature() {
        return (this.general as GeneralZEECDMINOLR)?.waterTemperature;
    }
    getBatteryState() {
        return this.states?.batteryLevel;
    }
    areSettingsUpToDate(): boolean {
        if (ModuleUtils.isCAW(this.general)) {
            return this.settings?.find(s => s.updatedToModule === false) === undefined;
        }
        return true;
    }
    unsyncSettings(): Array<string> {
        const toDisplay = ['clock_mode_analogDigit', 'real_time_tracking', 'utc_time_zone', 'button_long_pressure', 'fall']
        return this.settings.filter(s => s.updatedToModule === false && toDisplay.includes(s.name)).map(s => `CAW_SETTINGS.${s.name}`);
    }
    isFunctionnal(): boolean {
        return this.states?.serviceState?.moduleState === Constants.Module.ModuleState.Ok;
    }
    hasKnownBatteryLevel(): boolean {
        return ((this.states?.batteryLevel?.value as number) > -1 || this.states.batteryLevel.value !== Constants.Module.BatteryLevel.Unknown) ?? false;
    }
    getBatteryLevel(): number | Constants.Module.BatteryLevel | '?' {
        return this.hasKnownBatteryLevel() ? this.states.batteryLevel.value : '?';
    }
    updateGeneral(event: any) {
        Object.keys(event).forEach(key => {
            this.general[key] = event[key];
        });
    }
    release(): void {
        this.general.userID = null;
    }
    isInactive(): boolean {
        return this.general.inactive;
    }
    activate(): void {
        this.general.inactive = false;
    }
    assign(userID: string): void {
        this.general.userID = userID;
    }
    setStates(states: ModuleStatesInfo) {
        this.states = states;
    }
}

export type ModuleStatesInfo = Omit<Event.Module.ModuleStateResponse, 'moduleState'>;

export namespace ModuleUtils {
    export function isCAW(generalModule: ModuleGeneral): generalModule is GeneralCaw {
        return generalModule.deviceType === Entity.DeviceType.CAW;
    }
    export const doorSensorDeviceTypes = [Entity.DeviceType.CALDS, Entity.DeviceType.SP4MAG, Entity.DeviceType.LDS02DS];
    export function getBatteryLevelAdvice(value: number | Constants.Module.BatteryLevel) {
        if ((value as number) > 70 || value === Constants.Module.BatteryLevel.High) {
            return 'BATTERY.THRESHOLD_HIGH.SUBTITLE';
        } else if ((value as number) <= 70 && (value as number) > 25 || value === Constants.Module.BatteryLevel.Medium) {
            return 'BATTERY.THRESHOLD_MID.SUBTITLE';
        } else if ((value as number) <= 25 && (value as number) >= 0 || value === Constants.Module.BatteryLevel.Low) {
            return 'BATTERY.THRESHOLD_LOW.SUBTITLE';
        } else {
            return 'BATTERY.UNKNOWN_BATTERY';
        }
    }
    export function getConnectivityAdvice(value: Constants.Module.Connectivity | undefined) {
        switch (value) {
            case Constants.Module.Connectivity.Connected:
                return 'COMMON.CONNECTED';
            case Constants.Module.Connectivity.Disconnected:
                return 'COMMON.DISCONNECTED';
            case Constants.Module.Connectivity.Unknown:
            default:
                return '';
        }
    }
}
