import { Injectable } from '@angular/core';
import { Subscription, Entity, Constants } from '@co-assist/library';
import { TranslateService } from '@ngx-translate/core';
// TODO: Supprimer ça
export enum RedirectLink {
    FIRST_LOGIN = 'firstLogin',
    LOGIN = 'login',
    ADMIN = 'admin',
    FROM_ADMIN = 'fromAdmin',
    REFRESH = 'refresh',
    TELEMETRY = 'telemetry',
    HUB = 'hub'
}

const subscriptionList = [
    new Subscription({
        name: 'economy',
        alreadyOwnWatch: false,
        watchPrice: 249.99,
        servicePrice: 15,
        debitingOffset: 30,
        firstDebitingOffset: 180
    }),
    new Subscription({
        name: 'economy2',
        alreadyOwnWatch: false,
        watchPrice: 249.99,
        servicePrice: 15,
        debitingOffset: 30,
        firstDebitingOffset: 30
    }),
    new Subscription({
        name: 'economy3',
        alreadyOwnWatch: false,
        watchPrice: 269.99,
        servicePrice: 29.99,
        debitingOffset: 30,
        firstDebitingOffset: 30
    }),
    new Subscription({
        name: 'liberty',
        alreadyOwnWatch: false,
        watchPrice: 0.0,
        servicePrice: 25,
        debitingOffset: 30,
        firstDebitingOffset: 0
    }),
    new Subscription({
        name: 'liberty2',
        alreadyOwnWatch: false,
        watchPrice: 0.0,
        servicePrice: 44.99,
        debitingOffset: 30,
        firstDebitingOffset: 0
    }),
    new Subscription({
        name: 'saved',
        alreadyOwnWatch: true,
        watchPrice: 0.0,
        servicePrice: 15,
        debitingOffset: 30,
        firstDebitingOffset: 180
    }),
    new Subscription({
        name: 'NONE', alreadyOwnWatch: true,
        watchPrice: 0.0,
        servicePrice: 0.0,
        debitingOffset: -1,
        firstDebitingOffset: -1
    })
];

const googleAPIKeys = {
    frontGeocodingAPIKey: 'AIzaSyAqbAMFu404Y_7-NK3p1mkwapSD_WTRGRU',
    mapsJavascriptAPIKey: 'AIzaSyCp0qkCC_at_pLebsDCrWAdoclRdta4GMA',
    mapsEmbedAPIKey: 'AIzaSyAiZJo8kp9BXAg2VYCfehc33XoCWsO86y0',
    placesAPIKey: 'AIzaSyBUx9N08Eab7jbYHNcNPErOR4oo86sdnng'
};

const googleUrls = {
    embedMap: 'https://www.google.com/maps/embed/v1/place',
    geocode: 'https://maps.googleapis.com/maps/api/geocode/json?'
};

// level class names
export enum Level {
    GOOD = 'ok',
    AVERAGE = 'warning',
    BAD = 'issue'
}
@Injectable()
export class ConstantsService {

    constructor(
        private translate: TranslateService
    ) { }
    // Todo : remove this constants.service file
    static getAllAlertTypes() {
        return Constants.Module.getAlertTypes(Entity.DeviceType.CAW);
    }
    get allAlertTypes() {
        return Constants.Module.getAlertTypes(Entity.DeviceType.CAW);
    }

    get redirectLink() {
        return RedirectLink;
    }

    get subscriptionList() {
        return subscriptionList;
    }

    get googleAPIKeys() {
        return googleAPIKeys;
    }

    get googleUrls() {
        return googleUrls;
    }
}
