import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UpdateService } from 'app/services/utilities/update.service';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from './services/utilities/google-tag-manager.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    public onlineEvent: Observable<Event>;
    public offlineEvent: Observable<Event>;
    public subscriptions: Subscription[] = [];

    constructor(
        public update: UpdateService,
        public toastr: ToastrService,
        private translate: TranslateService,
        private gtmService: GoogleTagManagerService
    ) {
    }

    ngOnInit(): void {
        this.connectionStatusInit();
        this.gtmService.addGtmToDom();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    private connectionStatusInit() {
        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');
        this.subscriptions.push(this.onlineEvent.subscribe(() => {
            this.toastr.success(this.translate.instant('COMMON.TOAST.INTERNET_RENEW'));
        }));
        this.subscriptions.push(this.offlineEvent.subscribe(() => {
            this.toastr.warning(this.translate.instant('COMMON.TOAST.INTERNET_LOST'));
        }));
    }
}
