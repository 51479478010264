import { Component, OnInit } from '@angular/core';
import { ErrorMatcher } from './error-matcher';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggedUserStoreService } from '../services/store/logged-user-store.service';
import { ManageAccess } from 'app/util/manage-access';
import { LoginService } from 'app/services/data/login.service';
import { GeneralService } from 'app/services/data/general.service';
import { map, switchMap } from 'rxjs';

export interface LoginForm {
    loginID: string;
    password?: string;
    permanentToken?: string;
}
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    isLoading: boolean = false;
    form: UntypedFormGroup;
    isSuperAdmin: boolean = false;
    blockingTimeBase: number = 2; // blocking time base (in minutes)
    timeToRelease: number;
    hide: boolean = true;
    private forbiddenLogin: boolean = false;
    private currentAttempt: number = 0;
    private forbiddenTTL: number;
    private blockingLimit: number = 5; // 5 attempts, the user awaits this.blockingTimeBase min
    private superAdminRegex: string = 'super.admin.';
    private admincaPattern: string = 'adminca.';

    constructor(
        public errorMatcher: ErrorMatcher,
        private loginService: LoginService,
        private loggedUserStoreService: LoggedUserStoreService,
        private manageAccess: ManageAccess,
        private fb: UntypedFormBuilder,
        private router: Router,
        private generalService: GeneralService,
    ) { }

    get remainingAttempts(): number {
        return this.currentAttempt <= this.blockingLimit ? this.blockingLimit - this.currentAttempt : 0;
    }

    ngOnInit() {
        this.errorMatcher.clear();
        this.initForm();
        this.manageLoginSpammer();
    }

    initForm() {
        this.form = this.fb.group({
            loginID: [null, [Validators.required]],
            password: [null, [Validators.required]]
        });
        this.form.get('loginID').valueChanges.subscribe(loginID => {
            if (loginID) {
                const needMFA: boolean = this.needMFA(loginID);
                if (!(this.isSuperAdmin && needMFA)) {
                    this.updateForm(needMFA);
                    this.isSuperAdmin = needMFA;
                }
            }
        });
    }
    updateForm(admin: boolean) {
        if (admin) {
            this.form.addControl('oneTimePassword', this.fb.control(null, Validators.required));
        } else {
            this.form.removeControl('oneTimePassword');
        }
    }
    /**
     * Retrieve local storage based informations
     */
    initLocalStorage(): void {
        this.currentAttempt = (localStorage.getItem('CoassistAttempt')) ? parseInt(localStorage.getItem('CoassistAttempt'), 10) : 0;
        this.forbiddenTTL = (localStorage.getItem('CoassistLoginTTL')) ? parseInt(localStorage.getItem('CoassistLoginTTL'), 10) : 0;
        this.forbiddenLogin = (this.remainingAttempts === 0 && this.isInForbiddenPeriod());
    }
    /**
     * Each time someone try to connect we save this attempt in cookie
     */
    manageLoginSpammer(): void {
        const now = new Date().getTime();
        if (this.remainingAttempts === 0) {
            if (this.forbiddenTTL > now) {
                const timeToRelease = Math.ceil(((this.forbiddenTTL - now) / 60) / 1000);
                this.errorMatcher.setCustomError(this.errorMatcher.errorMessages.LOGIN_BLOCKED, timeToRelease);
                this.forbiddenLogin = true;
            } else if (this.forbiddenTTL === 0) {
                const ttl = new Date().getTime() + ((this.blockingTimeBase * 60) * 1000);
                this.forbiddenTTL = ttl;
                localStorage.setItem('CoassistLoginTTL', ttl + '');
                const timeToRelease = Math.ceil(((this.forbiddenTTL - now) / 60) / 1000);
                this.errorMatcher.setCustomError(this.errorMatcher.errorMessages.LOGIN_BLOCKED, timeToRelease);
                this.forbiddenLogin = true;
            } else {
                this.resetLoginTTL();
                this.forbiddenLogin = false;
            }
        }
    }
    /**
     * Update value and localStorage Item
     */
    updateAttempt() {
        this.currentAttempt++;
        localStorage.setItem('CoassistAttempt', this.currentAttempt + '');
        if (this.remainingAttempts > 0) {
            this.errorMatcher.setCustomError(this.errorMatcher.errorMessages.REMAINING_ATTEMPT, this.remainingAttempts);
        }
    }
    /**
     * Clear localStorage value for CoassistLoginTTL && forbiddenTTL
     * When the penality times is elapsed, we restore a 0 value
     */
    resetLoginTTL() {
        this.forbiddenTTL = 0;
        localStorage.setItem('CoassistLoginTTL', 0 + '');
    }
    /**
     * Delete all localStorage on successful login
     */
    clearLocalStorage(): void {
        localStorage.removeItem('CoassistLoginTTL');
        localStorage.removeItem('CoassistAttempt');
        this.currentAttempt = 0;
        this.forbiddenTTL = 0;
    }
    /**
     * Test if the forbiddenLogin time is already active
     */
    isInForbiddenPeriod(): boolean {
        return this.forbiddenLogin ? new Date().getTime() < this.forbiddenTTL : false;
    }

    login(): void {
        this.form.markAllAsTouched();
        if (this.form.valid && !this.isInForbiddenPeriod()) {
            this.isLoading = true;
            this.loginService.signIn(this.form.value).pipe(
                switchMap(_ => {
                    this.clearLocalStorage();
                    const isCoAssistAdmin = !!this.loggedUserStoreService.getCoAssistAdmin();
                    if (isCoAssistAdmin) {
                        return this.manageAccess.redirectUser(this.loggedUserStoreService.getAdministrationRoute())
                    }
                    return this.generalService.fetchCommonData$().pipe(
                        map(_ => this.manageAccess.dispatch())
                    );
                })
            ).subscribe({
                next: _ => { },
                error: (err: HttpErrorResponse) => {
                    this.errorMatcher.setError(err);
                    this.updateAttempt();
                    this.manageLoginSpammer();
                    this.isLoading = false;
                    return false;
                }
            });
        }
    }
    forgottenPassword() {
        const route: Array<any> = ['ask-reset-password'];
        if (this.form.get('loginID').value) {
            route.push(this.form.get('loginID').value);
        }
        this.router.navigate(route);
    }
    verifyAccount() {
        this.router.navigate(['/re-verify', this.form.get('loginID').value]);
    }
    private needMFA(loginID: string): boolean {
        const matchRegex: boolean = loginID.startsWith(this.superAdminRegex);
        const matchAdminca: boolean = loginID.startsWith(this.admincaPattern);
        return matchRegex || matchAdminca;
    }
}
