import { Injectable } from '@angular/core';
enum STATUS {
    LOADING = 0,
    LOADING_DONE = 1,
    SUCCESS = 2,
    ERROR = 3,
    MISSING_ARG = 4,
    MISSING_VERIFICATION = 5
}
@Injectable()
export class PageStatus {
    status: number;

    constructor() {
        this.status = STATUS.LOADING;
    }

    setLoading() {
        this.status = STATUS.LOADING;
    }

    setLoading_done() {
        this.status = STATUS.LOADING_DONE;
    }

    setSuccess() {
        this.status = STATUS.SUCCESS;
    }

    setError() {
        this.status = STATUS.ERROR;
    }

    setMissingVerification() {
        this.status = STATUS.MISSING_VERIFICATION;
    }

    setMissing_arg() {
        this.status = STATUS.MISSING_ARG;
    }

    isLoading() {
        return (this.status === STATUS.LOADING);
    }

    isDone() {
        return (this.status === STATUS.LOADING_DONE);
    }

    isSuccess() {
        return (this.status === STATUS.SUCCESS);
    }

    isError() {
        return (this.status === STATUS.ERROR);
    }

    isMissingVerification() {
        return (this.status === STATUS.MISSING_VERIFICATION);
    }

    isMissing_arg() {
        return (this.status === STATUS.MISSING_ARG);
    }
}
