<button mat-icon-button class="icon-dark-gray" [matMenuTriggerFor]="language" id="app-language-button"
    aria-label="More">
    <mat-icon>language</mat-icon>
</button>
<mat-menu #language="matMenu">
    <button mat-menu-item [id]="'app-language-'+language.value" *ngFor="let language of allLanguages"
        (click)="setChangedLanguage(language.value);">
        <mat-icon class="icon-dark-gray">{{currentLanguage === language.value ? 'radio_button_checked':
            'radio_button_unchecked'}}</mat-icon>
        <span>{{ language.viewValue }}</span>
    </button>
</mat-menu>