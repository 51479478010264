import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as model from 'app/api/api-interface';
import { SubscriptionBundle } from 'app/models/SubscriptionBundle';
import { ConstantsService } from 'app/services/utilities/constants.service';
import { CookieService } from 'app/services/utilities/cookie.service';
import { User, Payment, Administration, Module } from '@co-assist/library/lib/event';
@Injectable({
    providedIn: 'root'
})
export class MethodApi {
    private apiUrls = {
        ADMINISTRATION_URL: `https://api.co-assist.fr/${environment.STAGE}-administration/`,
        AUTHENTICATION_URL: `https://api.co-assist.fr/${environment.STAGE}-authentication/`,
        ALERT_URL: `https://api.co-assist.fr/${environment.STAGE}-alert/`,
        FILE_URL: `https://api.co-assist.fr/${environment.STAGE}-file/`,
        USER_URL: `https://api.co-assist.fr/${environment.STAGE}-user/`,
        MODULE_URL: `https://api.co-assist.fr/${environment.STAGE}-module/`,
        PAYMENT_URL: `https://api.co-assist.fr/${environment.STAGE}-payment/`,
    };
    constructor(
        private http: HttpClient,
        private constants: ConstantsService,
        private cookie: CookieService
    ) { }

    /**
     * Cette fonction recupère une adresse a partir de coordinées GPS
     * @param lat: number
     * @param lng: number
     * @returns {Observable<R>}
     */
    getAddressToGps(lat: number, lng: number) {
        return this.http.get(`${this.constants.googleUrls.geocode}&key=${this.constants.googleAPIKeys.frontGeocodingAPIKey}&latlng=${lat},${lng}&sensor=true`);
    }
    /**
     * Convert address into GPS coordinates
     * @param dataToSend
     * @returns {Observable<Response>}
     */
    getGpsCoordinates(address: string): any {
        return this.http.get(`${this.constants.googleUrls.geocode}&key=${this.constants.googleAPIKeys.frontGeocodingAPIKey}&address=${address}`);
    }

    /**
     * Observable based methods
     */
    post(root: string, method: string, data: any): any {
        let Authorization = this.setAuthorization();
        let httpOptions;
        if (Authorization) {
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': Authorization,
                    'x-api-key': environment.API_KEY
                })
            };
        } else {
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'x-api-key': environment.API_KEY
                })
            };
        }
        return this.http.post(root + method, data, httpOptions);
    }

    postAdministrationRequest(method: string, data: any): Observable<any> {
        return this.post(this.apiUrls.ADMINISTRATION_URL, method, data);
    }
    postModuleRequest(method: string, data: any): Observable<any> {
        return this.post(this.apiUrls.MODULE_URL, method, data);
    }
    postAlertRequest(method: string, data: any): Observable<any> {
        return this.post(this.apiUrls.ALERT_URL, method, data);
    }
    postAuthenticationRequest(method: string, data: any): Observable<any> {
        return this.post(this.apiUrls.AUTHENTICATION_URL, method, data);
    }
    postFileRequest(method: string, data: any): Observable<any> {
        return this.post(this.apiUrls.FILE_URL, method, data);
    }
    postUserRequest(method: string, data: any): Observable<any> {
        return this.post(this.apiUrls.USER_URL, method, data);
    }
    postGroupRequest(method: string, data: any): Observable<any> {
        return this.post(this.apiUrls.USER_URL, method, data);
    }
    postPaymentRequest(method: string, data: any): Observable<any> {
        return this.post(this.apiUrls.PAYMENT_URL, method, data);
    }

    /** administration **/
    checkNetwork(data: Administration.SigfoxCheckCoverageEvent): Observable<Administration.SigfoxCheckCoverageResponse> {
        return this.postAdministrationRequest('sigfoxCheckCoverage', data);
    }

    /** Modules **/
    getModulesByWearer(data: model.IUserID): Observable<any> { // checker
        return this.postModuleRequest('getModulesByWearer', data);
    }
    getModulesByGroupID(data: model.IGroupID): Observable<any> { // checker
        return this.postModuleRequest('getModulesByGroupID', data);
    }
    getModuleAvailability(data: Module.GetModuleAvailabilityEvent): Observable<Module.GetModuleAvailabilityResponse> {
        return this.postModuleRequest('getModuleAvailability', data);
    }
    getHelpers(data: User.GetHelpersEvent): Observable<User.GetHelpersResponse> {
        return this.postUserRequest('getHelpers', data);
    }

    /** user **/
    deleteUser(data: User.DeleteUserEvent): Observable<User.DeleteUserResponse> {
        return this.postUserRequest('deleteUser', data);
    }
    updateUserConfidentialInformation(event: User.UpdateUserConfidentialInformationEvent): Observable<User.UpdateUserConfidentialInformationResponse> {
        return this.postUserRequest('updateUserConfidentialInformation', event);
    }
    getUser(data: User.GetGeneralUserEvent): Observable<User.GetGeneralUserResponse> {
        return this.postUserRequest('getUser', data);
    }
    getUserConfidentialInformation(data: User.GetUserConfidentialInformationEvent): Observable<User.GetUserConfidentialInformationResponse> {
        return this.postUserRequest('getUserConfidentialInformation', data);
    }
    getRelations(data: User.GetRelationsEvent): Observable<User.GetRelationsResponse> {
        return this.postUserRequest('getRelations', data);
    }
    getPositionHistory(data: User.GetPositionHistoryEvent): Observable<User.GetPositionHistoryResponse> {
        return this.postUserRequest('getPositionHistory', data);
    }
    getRelevantPosition(data: User.GetRelevantPositionEvent): Observable<User.GetRelevantPositionResponse> {
        return this.postUserRequest('getRelevantPosition', data);
    }
    getActivityHistory(data: User.GetActivityHistoryEvent): Observable<User.GetActivityHistoryResponse> {
        return this.postUserRequest('getActivityHistory', data);
    }
    getInvoices(event: User.GetInvoicesEvent): Observable<User.GetInvoicesResponse> {
        return this.postUserRequest('getInvoices', event);
    }

    /** PAYMENT */
    subscriptionRequest(data: SubscriptionBundle): Observable<any> {
        return this.postPaymentRequest('handleSubscription', data);
    }
    getAllSubscriptions(data: Payment.GetAllSubscriptionsEvent): Observable<Payment.GetAllSubscriptionsResponse> {
        return this.postPaymentRequest('getAllSubscriptions', data);
    }
    getAllOptions(data: Payment.GetAllOptionsEvent): Observable<Payment.GetAllOptionsResponse> {
        return this.postPaymentRequest('getAllOptions', data);
    }
    getDiscountCodeValidity(data: Payment.GetDiscountEvent): Observable<Payment.GetDiscountResponse> {
        return this.postPaymentRequest('getDiscount', data);
    }
    getPaymentInformation(data: Payment.GetPaymentInformationEvent): Observable<Payment.GetPaymentInformationResponse> {
        return this.postPaymentRequest('getPaymentInformation', data);
    }
    updatePaymentInformation(data: Payment.UpdatePaymentInformationEvent): Observable<Payment.UpdatePaymentInformationResponse> {
        return this.postPaymentRequest('updatePaymentInformation', data);
    }
    setAuthorization(): string | undefined {
        const sessionToken = this.cookie.get('sessionToken');
        const otpSession = this.cookie.get('otpSession');
        const userID = this.cookie.get('userID');
        if (sessionToken !== '') {
            return userID + ':' + sessionToken;
        } else if (otpSession !== '') {
            return otpSession;
        } else {
            return undefined;
        }
    }
}
