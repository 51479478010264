import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AlertProcess, Event } from '@co-assist/library';
import { ProcessService } from 'app/services/data/process.service';
import { Observable } from 'rxjs';
import { HelpedStoreService } from 'app/services/store/helped-store.service';

@Injectable()

export class ProcessResolver implements Resolve<Array<AlertProcess>> {

    constructor(
        private processService: ProcessService,
        private helpedStoreService: HelpedStoreService) { }

    resolve(): Observable<Array<AlertProcess>> {
        const event: Event.User.GetProcessesEvent = {
            id: this.helpedStoreService.getCurrentHelpedID()
        };
        return this.processService.getProcesses(event);
    }
}
