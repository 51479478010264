import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MultilingualComponent } from 'app/sharedComponent/main-component/multilingual/multilingual.component';

@Component({
    standalone: true,
    selector: 'app-page-layout',
    templateUrl: './page-layout.component.html',
    styleUrls: ['./page-layout.component.scss'],
    imports: [
        MatToolbarModule,
        MultilingualComponent,
        NgIf,
        MatButtonModule,
        TranslateModule
    ]
})
export class PageLayoutComponent {
    @Input()
    title: string;
    @Input()
    displayCustomerService: boolean = true;
    @Input()
    isSubscription: boolean = false;
    @Input()
    isLoading: boolean = false;
    constructor(
        private router: Router
    ) { }
    goToSubscription() {
        this.router.navigate(['subscription']);
    }
    downloadBooklet(): void {
        let brochureUrl = 'https://www.co-assist.fr/contact';
        window.open(brochureUrl, '_blank');
    }
}
