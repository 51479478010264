import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    standalone: true,
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    imports: [NgIf, TranslateModule]
})
export class LoadingComponent {
    _accessDenied: boolean = false;

    constructor(public translate: TranslateService) { };

    @Input() set accessDenied(access: boolean) {
        this._accessDenied = access;
    }
}
