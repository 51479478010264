import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from 'app/services/data/authentication.service';
import { inject, Injectable } from '@angular/core';
import { CookieService } from 'app/services/utilities/cookie.service';
import { ConstantsService } from 'app/services/utilities/constants.service';
import { Router } from '@angular/router';
import { Constants, IAuthentication } from '@co-assist/library';
import { map } from 'rxjs/operators';
import { ManageLoginIDResponse } from '@co-assist/library/lib/event/authentication-registration';
import { LoggedUserStoreService } from 'app/services/store/logged-user-store.service';
import { ModuleStoreService } from 'app/services/store/module-store.service';
import { LoadingService } from 'app/services/utilities/loading.service';
import { TranslateService } from '@ngx-translate/core';

export enum CookieKey {
    advancedUser = 'advancedUser',
    isTelemetryServiceAvailable = 'isTelemetryServiceAvailable'
}
@Injectable()
export class ManageAccess {

    readonly isAdvancedUser$: Observable<boolean>;
    private readonly _isAdvancedUser = new BehaviorSubject<boolean>(undefined);
    readonly telemetryServiceavailable$: Observable<boolean>;
    private readonly _telemetryServiceavailable = new BehaviorSubject<boolean>(false);
    private _loadingService = inject(LoadingService);
    private _translateService = inject(TranslateService)
    constructor(
        private cookie: CookieService,
        private router: Router,
        private constants: ConstantsService,
        private authenticationService: AuthenticationService,
        private loggedUserStoreService: LoggedUserStoreService,
        private moduleStoreService: ModuleStoreService
    ) {
        this.isAdvancedUser$ = this._isAdvancedUser.asObservable();
        this._isAdvancedUser.next(this.cookie.get(CookieKey.advancedUser) === 'true');
        this.telemetryServiceavailable$ = this._telemetryServiceavailable.asObservable();
        this._telemetryServiceavailable.next(this.cookie.get(CookieKey.isTelemetryServiceAvailable) === 'true');
    }

    /**
     * Get the most relevant user token
     */
    getUserToken(): string {
        const privateToken = this.cookie.get('privateToken');
        const permanentToken = this.cookie.get('permanentToken');
        return permanentToken === '' ? privateToken : permanentToken;
    }

    /**
     * Save or update existing cookies
     * @param loginID
     * @param authentication
     */
    saveUserAccess(loginID: string, userID: string, authentication: IAuthentication): boolean {
        // Old mobile version log in through userID, avoid the storage of an undefined value for loginID
        if (loginID) {
            // loginID allows to identify during login
            this.cookie.set('loginID', loginID, 365);
        }
        // userID allows to identify during api requests
        this.cookie.set('userID', userID, 365);

        // save the permanent token during 30 days
        this.cookie.set('permanentToken', authentication.permanentToken, 30);

        // The sessionToken cookie defines the maximum duration of a connection to the website (usually 30 minutes)
        // Its expiration date is sent back by the login request
        // When it expires and if the user is still on the website, the sessionToken is renewed using the user token (private or permanent)
        if (authentication.tokenExpirationDate > Date.now()) {
            const expirationDate = new Date(authentication.tokenExpirationDate);
            this.cookie.set('sessionToken', authentication.sessionToken, expirationDate);
        }
        return true;
    }

    /**
     * return the stored loginID of the logged user
     */
    getLoginID(): string {
        return this.cookie.get('loginID');
    }
    /**
     * return the stored loginID of the logged user
     */
    getLoginID$(userID: string): Observable<ManageLoginIDResponse> {
        return this.authenticationService.getLoginID({ userID });
    }
    /**
     * Update the stored loginID of the logged user
     */
    updateLoginID(newLoginID: string): Observable<string> {
        return this.authenticationService.updateLoginID({ newLoginID, userID: this.cookie.get('userID') }).pipe(
            map(_ => {
                this.cookie.set('loginID', newLoginID, 365);
                return newLoginID;
            })
        );
    }

    redirectUser(redirectTo: string) {
        switch (redirectTo) {
            case (this.constants.redirectLink.LOGIN):
                return this.router.navigate(['/login']);
            case (this.constants.redirectLink.FIRST_LOGIN):
                return this.router.navigate(['/content']);
            case (this.constants.redirectLink.FROM_ADMIN):
                return this.router.navigate(['/administration/content']);
            case (this.constants.redirectLink.ADMIN):
                return this.router.navigate(['/administration']);
            case (this.constants.redirectLink.TELEMETRY):
                return this.router.navigate(['/telemetry']);
            case (this.constants.redirectLink.HUB):
                return this.router.navigate(['/hub']);
        }
    }

    hubChoice(redirectTo: Service) {
        this._loadingService.show('COMMON.LOADING.HUB_SERVICE', { service: this._translateService.instant(redirectTo === Service.telemetry ? 'HUB.TELEMETRY' : 'HUB.ALERT') });
        return this.redirectUser(redirectTo === 0 ?
            this.loggedUserStoreService.getTelemetryRoute() :
            this.loggedUserStoreService.getContentRoute()
        ).then(_ => {
            this._loadingService.hide();
        });
    }
    getCurrentService(): 0 | 1 {
        return this.router.url.includes('/telemetry') ? 0 : 1
    }

    dispatch() {
        const modules = this.moduleStoreService.getAllModules();
        const waterMeters = modules.filter(module => module.getDeviceType() === Constants.Entity.DeviceType.ZEECDMINOLR);
        this.setisTelemetryServiceAvailable(waterMeters.length);
        if (waterMeters.length && waterMeters.length === modules.length) {
            this.redirectUser(this.loggedUserStoreService.getTelemetryRoute())
        } else if (waterMeters.length === 0) {
            this.redirectUser(this.loggedUserStoreService.getContentRoute())
        } else {
            this.redirectUser(this.loggedUserStoreService.getHubRoute())
        }
    }
    setisTelemetryServiceAvailable(waterMetersNumber: number): void {
        this._telemetryServiceavailable.next(waterMetersNumber > 0);
        this.cookie.set(CookieKey.isTelemetryServiceAvailable, String((waterMetersNumber > 0)));

    }

    resetAdvanceUser(): void {
        this.cookie.delete(CookieKey.advancedUser);
        this._isAdvancedUser.next(false);
    }
    setAdvanceUser(isAdvancedUser: boolean): void {
        if (isAdvancedUser) {
            const in15Min = new Date(Date.now() + 15 * 60 * 1000);
            this.cookie.set(CookieKey.advancedUser, isAdvancedUser.toString(), in15Min);
            this._isAdvancedUser.next(true);
        } else { this.resetAdvanceUser() }
    }

    isAdvancedUser(): boolean {
        return this._isAdvancedUser.getValue();
    }
}

export enum Service {
    telemetry,
    alert
}
