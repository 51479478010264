import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/min/locales';
import { CookieService } from 'app/services/utilities/cookie.service';
import { Language } from '@co-assist/library';

const SupportedLanguages = {
    EN: { value: Language.English, viewValue: 'English' },
    FR: { value: Language.Francais, viewValue: 'Français' }
};

export interface ILanguage {
    value: Language;
    viewValue: string;
}

@Injectable()
export class LanguageService {
    private _defaultLanguage: ILanguage = SupportedLanguages.FR;

    constructor(
        private translate: TranslateService,
        private cookie: CookieService
    ) { }

    get defaultLanguage(): Language {
        return this._defaultLanguage.value;
    }

    getLanguageList(): ILanguage[] {
        return Object.values(SupportedLanguages);
    }

    getLanguage(user?: any): Language {
        const langFromCookie: string = this.cookie.get('language');
        const langFromBrowser: string = this.translate.getBrowserLang();
        if (user && this.isSupported(user.language)) {
            return user.language.toLowerCase();
        } else if (this.isSupported(langFromCookie)) {
            return langFromCookie.toLowerCase() as Language;
        } else if (this.isSupported(langFromBrowser)) {
            return langFromBrowser.toLowerCase() as Language;
        }
        return this._defaultLanguage.value;
    }

    getDefaultTimeZone() {
        if (this.cookie.get('timezone')) {
            return this.cookie.get('timezone');
        } else {
            return 'Europe/Paris';
        }
    }

    setLanguage(language: string) {
        if (!this.isSupported(language)) {
            language = this._defaultLanguage.value;
        }
        moment.locale(language);
        this.translate.use(language);
        this.cookie.set('language', language, 356);
    }

    private isSupported(language: string): boolean {
        return language && Object.keys(SupportedLanguages).includes(language.toUpperCase());
    }
}
