import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EntityService } from '../data/entity.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { GeneralUser, IGeneralUser } from '@co-assist/library';
import { ModuleStoreService } from './module-store.service';
import { LoggedUserStoreService } from './logged-user-store.service';

enum LocalStorageKeys {
    currentHelped = 'currentHelped',
    helpeds = 'helpeds'
}
@Injectable({
    providedIn: 'root'
})
export class HelpedStoreService {
    readonly currentHelped$: Observable<GeneralUser>;
    readonly helpeds$: Observable<Array<GeneralUser>>;
    private readonly _currentHelped = new BehaviorSubject<GeneralUser>(undefined);
    private readonly _helpeds = new BehaviorSubject<Array<GeneralUser>>([]);

    constructor(
        private entityService: EntityService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private moduleStoreService: ModuleStoreService,
        private loggedUserStoreService: LoggedUserStoreService
    ) {
        this.helpeds$ = this._helpeds.asObservable();
        this.currentHelped$ = this._currentHelped.asObservable();
        const storedUser = localStorage.getItem(LocalStorageKeys.helpeds);
        const storedCurrentHelped = localStorage.getItem(LocalStorageKeys.currentHelped);
        this._helpeds.next(storedUser ? (JSON.parse(storedUser).map(u => new GeneralUser(u))) : []);
        this._currentHelped.next(storedCurrentHelped ? (new GeneralUser(JSON.parse(storedCurrentHelped))) : undefined);
    }

    getCurrentHelped(): GeneralUser | undefined {
        return this._currentHelped.getValue();
    }
    getHelped(userID: GeneralUser['userID']): GeneralUser | undefined {
        return this.getHelpeds().find(u => u.userID === userID);
    }
    getCurrentHelpedID(): string | undefined {
        return this.getCurrentHelped()?.getUserID();
    }
    getHelpeds(): Array<GeneralUser> {
        return this._helpeds.getValue();
    }
    getHelpedsObs(): Observable<Array<GeneralUser>> {
        return this.helpeds$;
    }
    addGroupHelped(user: IGeneralUser & Required<Pick<IGeneralUser, 'group'>>) {
        return this.entityService.addGroupHelped(user).pipe(
            map(returnUser => {
                this._addHelped(new GeneralUser(returnUser));
                this.toastr.success(this.translate.instant('COMMON.TOAST.ADD_USER_SUCCESS'));
            }));
    }
    fetchHelpeds() {
        const loggedUser = this.loggedUserStoreService.getLoggedUser();
        const event = loggedUser.group ? { groupID: loggedUser.getGroupID() } : { userID: loggedUser.getUserID() }
        return this.entityService.getHelpedsOf(event).pipe(
            tap((helpeds) => this.setHelpeds(helpeds)));
    }
    setHelpeds(users: Array<IGeneralUser>) {
        this._helpeds.next(users.map(u => new GeneralUser(u)));
        const helpeds = this.getHelpeds();
        if (helpeds.length && !helpeds.find(helped => helped.isB2B())) {
            this.selectHelped(helpeds[0].getUserID());
        }
        localStorage.setItem(LocalStorageKeys.helpeds, JSON.stringify(this._helpeds.getValue()));
    }
    deleteHelped(userID: string) {
        return this.moduleStoreService.releaseModules(userID).pipe(
            switchMap(_ => this.entityService.deleteGroupUser(userID)),
            map(_ => {
                const filteredList = this.getHelpeds().filter(u => u.getUserID() !== userID);
                this._helpeds.next(filteredList);
                if (this.getCurrentHelpedID() === userID) {
                    this._resetCurrentHelped();
                }
                this.toastr.success(this.translate.instant('COMMON.TOAST.DELETE_USER_SUCCESS'));
            })
        );
    }

    selectHelped(userID: string) {
        if (this.getHelpeds().find(h => h.getUserID() === userID)) {
            this._currentHelped.next(this.getHelpeds().find(h => h.getUserID() === userID));
            localStorage.setItem(LocalStorageKeys.currentHelped, JSON.stringify(this._currentHelped.getValue()));
        }
    }

    updateHelped(user: Partial<GeneralUser> & Pick<GeneralUser, 'userID'>): Observable<void> {
        return this.entityService.updateGeneralUser(user).pipe(
            map(() => {
                this._updateHelped(user);
            })
        );
    }
    clean() {
        this._resetCurrentHelped();
        this._resetHelpeds();
    }

    private _resetCurrentHelped() {
        this._currentHelped.next(undefined);
        localStorage.removeItem(LocalStorageKeys.currentHelped);
    }
    private _resetHelpeds() {
        this._helpeds.next([]);
        localStorage.removeItem(LocalStorageKeys.helpeds);
    }

    private _addHelped(user: GeneralUser) {
        const helpeds = this.getHelpeds();
        helpeds.push(user);
        this.setHelpeds(helpeds);
    }

    private _updateHelped(user: Partial<GeneralUser>) {
        const helpeds = this.getHelpeds().map(h => {
            if (h.userID === user.userID) {
                Object.keys(user).forEach(key => {
                    if (user[key] !== undefined && key !== 'userID') {
                        h[key] = user[key];
                    }
                });
            }
            return h;
        });
        this.setHelpeds(helpeds);
        this.selectHelped(this.getCurrentHelpedID());
    }
}
