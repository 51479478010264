import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Alert, Constants, GeneralUser, GeneralModule, GeneralGroup } from '@co-assist/library';
import { AlertDetails, AlertHistory, AlertService } from '../data/alert.service';
import { dateToNextMidnight } from 'app/models/Basics';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { HelpedStoreService } from './helped-store.service';
import { ModuleStoreService } from './module-store.service';

enum LocalStorageKeys {
    alertsByUserID = 'alertsByUserID'
}
@Injectable({
    providedIn: 'root'
})
export class AlertStoreService {
    readonly activerAlerts$: Observable<Array<Alert>>;
    private readonly _activerAlerts = new BehaviorSubject<Array<Alert>>([])

    readonly alertsByUserID$: Observable<AlertHistoryStorage>;
    private readonly _alertsByUserID = new BehaviorSubject<AlertHistoryStorage>({ alerts: new Map(), lastUpdate: undefined, latestTimestamp: undefined });
    private refreshPeriod = 5 * 60 * 1000;
    constructor(
        private _alertService: AlertService,
        private _toastr: ToastrService,
        private _translate: TranslateService,
        private _helpedStoreService: HelpedStoreService,
        private _moduleStoreService: ModuleStoreService
    ) {
        this.alertsByUserID$ = this._alertsByUserID.asObservable();
        this.activerAlerts$ = this._activerAlerts.asObservable();
    }
    // ########## Active alerts ##########

    fetchActiveAlerts(event: { userID: GeneralUser['userID'] } | { groupID: GeneralGroup['groupID'] }) {
        return this._alertService.getActiveAlerts(event).pipe(
            map((alerts: Array<Alert>) => {
                this._activerAlerts.next(alerts);
                return alerts;
            })
        );
    }

    fetchSingleAlert(event: { alertID }) {
        return this._alertService.getAlert(event).pipe(
            map((alert: Alert) => {
                this._activerAlerts.next([alert]);
                return alert;
            })
        )
    }
    getActiveAlerts(): Observable<Array<Alert>> { return this.activerAlerts$; }
    getActiveAlert(alertID: string): Observable<Alert> { return this.activerAlerts$.pipe(map(alerts => alerts.find(a => a.alertID === alertID))); }

    handleAlert(event: HandleEvent): Observable<Alert> {
        return this._alertService.handleAlert(event).pipe(
            map(_ => {
                this._toastr.success(
                    this._translate.instant('COMMON.TOAST.ALERT_HANDLED'),
                    this._translate.instant('COMMON.TOAST.SUCCESS_TITLE'));
                const handleEvent = {
                    alertID: event.alertID,
                    handleInfo: {
                        displayName: event.helperDisplayName,
                        handleBy: Constants.Alert.HandleInfo.By.APP,
                        language: "fr",
                        timeMs: Date.now(),
                        userID: event.helperID
                    },
                    status: Constants.Alert.Status.HANDLED

                }
                return this._updateActiveAlert(handleEvent);
            })
        );
    }

    acquitAlert(event: AcquitEvent): Observable<Alert> {
        return this._alertService.acquitAlert(event).pipe(
            map(_ => {
                this._toastr.success(
                    this._translate.instant('COMMON.TOAST.ALERT_ACQUITTED'),
                    this._translate.instant('COMMON.TOAST.SUCCESS_TITLE'));
                const acquitEvent = {
                    alertID: event.alertID,
                    acquitInfo: {
                        displayName: event.helperDisplayName,
                        acquitBy: Constants.Alert.AcquitInfo.By.APP,
                        language: "fr",
                        timeMs: Date.now(),
                        userID: event.helperID
                    },
                    status: Constants.Alert.Status.ACQUITTED
                }
                return this._updateActiveAlert(acquitEvent);
            })
        );
    }

    _updateActiveAlert(event): Alert {
        const alerts = this._activerAlerts.getValue();
        const alertToUpdate = alerts.find(a => a.alertID === event.alertID);
        for (const field in event) {
            const key = field;
            if (event[key]) { alertToUpdate[key] = event[key]; }
        }
        this._activerAlerts.next(alerts);
        return alertToUpdate;
    }


    // ##########  Alert History ##########

    getLast7daysAlerts(): Observable<Map<GeneralUser['userID'], Array<AlertHistory>>> {
        const sevenDaysAgo = dateToNextMidnight(Date.now() - 7 * 24 * 3600 * 1000);
        return this.alertsByUserID$.pipe(
            map(alertsMap => {
                const lastSevenDaysMap = new Map();
                alertsMap.alerts.forEach((value, key) => {
                    lastSevenDaysMap.set(key, value.filter(v => v.start >= sevenDaysAgo))
                })
                return lastSevenDaysMap;
            })
        );
    }


    addAlertDetails(event: { alertID: Alert['alertID'], userID: GeneralUser['userID'], comment?: string, qualification?: Constants.Alert.Qualification }): Observable<void> {
        return this._alertService.updateAlertDetails(event).pipe(
            map(_ => {
                this._toastr.success(
                    this._translate.instant('COMMON.TOAST.ALERT_UPDATED'),
                    this._translate.instant('COMMON.TOAST.SUCCESS_TITLE'));
                this._alertsByUserID.getValue().alerts.get(event.userID).forEach(a => { if (a.alertID === event.alertID) { a.details = new AlertDetails({ comment: event.comment, qualification: event.qualification }) } })
                this._alertsByUserID.next(this._alertsByUserID.getValue());
            })
        );
    }
    getAlert(userID: GeneralUser['userID'], alertID: AlertHistory['alertID'], refresh?: boolean): Observable<AlertHistory> {
        let alert = this.getAlerts(userID).find(a => a.alertID === alertID);
        if (alert && !refresh) {
            return of(alert);
        } else {
            return this._alertService.getAlertHistory({ userID, alertID }).pipe(
                map(alertHistory => {
                    if (alertHistory.length !== 1) { return; }
                    return this._updateAlert(userID, alertHistory[0]);
                })
            );
        }
    }
    getAlerts(userID: GeneralUser['userID']): Array<AlertHistory> {
        return this._alertsByUserID.getValue().alerts?.get(userID) ?? [];
    }

    /**
     * Launched only once, not usefull to keep the data up to date
     * @param userID
     * @param from
     * @param to
     * @returns
     */
    fetchAlerts(timestamp: number, userID?: GeneralUser['userID'], forceRefresh?: boolean): Observable<Array<AlertHistory>> {
        if (userID) {
            if (this.shouldRefresh(timestamp) || forceRefresh) {
                return this._alertService.getAlertHistory({ userID, timestamp })
                    .pipe(
                        map(alerts => { this._setAlerts(userID, alerts, timestamp); return alerts; })
                    );
            } else {
                return this.alertsByUserID$.pipe(map(a => a.alerts.get(userID).filter(a => a.start >= timestamp)));
            }
        } else {
            const helpeds = this._helpedStoreService.getHelpeds();
            if (helpeds.length && (this.shouldRefresh(timestamp) || forceRefresh)) {
                return this._alertService.getAlertsHistory({ 'userIDs': this._helpedStoreService.getHelpeds().map(h => h.getUserID()), timestamp })
                    .pipe(
                        map((alertsByUser: Map<GeneralUser['userID'], Array<AlertHistory>>) => {
                            const allAlerts = [];
                            alertsByUser.forEach((alerts, _userID) => {
                                this._setAlerts(_userID, alerts, timestamp);
                                allAlerts.push(...alerts);
                            });
                            return allAlerts;
                        })
                    );
            } else {
                const allAlerts: Array<AlertHistory> = [];
                this._alertsByUserID.getValue().alerts.forEach(a => {
                    allAlerts.push(...a.filter(al => al.start >= timestamp))
                });
                return of(allAlerts);
            }
        }
    }

    shouldRefresh(timestamp: number): boolean {
        const isUndefined = this._alertsByUserID.getValue().lastUpdate === undefined;
        const hasNotBeenRecentlyRefreshed = this._alertsByUserID.getValue().lastUpdate < (Date.now() - this.refreshPeriod);
        const timestampIsEarlier = this._alertsByUserID.getValue().latestTimestamp > (timestamp + this.refreshPeriod);
        // console.log('shouldRefresh pour : ' + new Date(timestamp).toISOString(), 'pour ', isUndefined || hasNotBeenRecentlyRefreshed || timestampIsEarlier, ' : ', isUndefined, hasNotBeenRecentlyRefreshed, timestampIsEarlier)
        return isUndefined || hasNotBeenRecentlyRefreshed || timestampIsEarlier;
    }

    clean() {
        this._resetData();
    }

    // == HELPERS ===
    isWaitingForHandling(status: Constants.Alert.Status): boolean {
        return [
            Constants.Alert.Status.STARTED,
            Constants.Alert.Status.NOTIFYING,
            Constants.Alert.Status.TRYING_NEW_NOTIFICATION,
            Constants.Alert.Status.WAITING_FOR_RESPONSE
        ].includes(status);
    }

    isWaitingForAcknowledgement(status: Constants.Alert.Status): boolean {
        return Constants.Alert.Status.HANDLED === status;
    }
    isWaitingForAcknowledgementInSitu(deviceType: GeneralModule['deviceType'], alertType: Constants.Alert.AlertType, status: Constants.Alert.Status): boolean {
        if (deviceType && Constants.Alert.Status.HANDLED === status) {
            return !this._moduleStoreService.getDeviceProfile(deviceType).acknowledgeableAlert.includes(alertType)
        }
        return false;
    }

    private _updateAlert(userID: GeneralUser['userID'], alert: AlertHistory): AlertHistory {
        const existing = this._alertsByUserID.getValue();
        let alertsOf = existing.alerts.get(userID) ?? [];
        if (alertsOf.length) {
            alertsOf.map(a => alert.alertID === a.alertID ? alert : a);
        } else {
            alertsOf.push(alert);
        }
        existing.alerts.set(userID, [...alertsOf]);
        this._alertsByUserID.next(existing);
        return alert;
    }

    private _setAlerts(userID: GeneralUser['userID'], alerts: Array<AlertHistory>, latestTimestamp: number): void {
        const existing = this._alertsByUserID.getValue();
        const alertsOf = existing.alerts.get(userID) ?? [];
        let newAlertsOf = [];
        // we must filter/overwrite existing values: newest data is safer
        if (alertsOf.length && alertsOf.length === alerts.length) {
            newAlertsOf = alertsOf.map(a => {
                const alert = alerts.find(al => al.alertID === a.alertID);
                return alert ?? a;
            });
        } else {
            newAlertsOf = alerts;
        }
        existing.alerts.set(userID, newAlertsOf);
        existing.lastUpdate = Date.now();
        existing.latestTimestamp = latestTimestamp;
        // console.log('Enregistrement de latestTimestamp: ' + new Date(latestTimestamp).toISOString(), 'pour ', userID)
        this._alertsByUserID.next(existing);
    }

    private _resetData() {
        this._alertsByUserID.next({ alerts: new Map<GeneralUser['userID'], Array<AlertHistory>>(), lastUpdate: undefined, latestTimestamp: undefined });
        localStorage.removeItem(LocalStorageKeys.alertsByUserID);
    }
}

export type HandleEvent = {
    alertID: Alert['alertID'],
    handleBy: Constants.Alert.HandleInfo.By,
    helperDisplayName: GeneralUser['displayName'],
    helperName: GeneralUser['name'],
    helperID: GeneralUser['userID'],
    userID: GeneralUser['userID']
}
export type AcquitEvent = {
    alertID: Alert['alertID'],
    acquitBy: Constants.Alert.AcquitInfo.By,
    helperDisplayName: GeneralUser['displayName'],
    helperID: GeneralUser['userID'],
    userID: GeneralUser['userID']
};

export type AlertHistoryStorage = {
    alerts: Map<GeneralUser['userID'],
        Array<AlertHistory>>,
    lastUpdate: number,
    latestTimestamp: number
};
