import { TIME_MODE } from "./Group";
import PlaceResult = google.maps.places.PlaceResult;
import { Constants } from "@co-assist/library";

/**
 * Time part
 */
export const DAY_IN_MIN: number = 1440;
export const DAY_IN_MS: number = 86400000;
export const NOW: number = new Date().getTime();
export const ONE_WEEK_AGO: number = NOW - 7 * DAY_IN_MS;
export const TWO_WEEKS_AGO: number = NOW - 14 * DAY_IN_MS;
const now = new Date();
const start = new Date(now.getFullYear(), 0, 0);
const dayNumberOfTheYear = Math.floor((now.getTime() - start.getTime()) / Constants.Time.DAY_IN_MS);

export const DURATIONS: Array<IMatTuple> = [
    { viewValue: 'RANGE.SELECT_ONE_HOUR', value: 0.04 },
    { viewValue: 'RANGE.SELECT_SIX_HOURS', value: 0.25 },
    { viewValue: 'RANGE.SELECT_ONE_DAY', value: 1 },
    { viewValue: 'RANGE.SELECT_TWO_DAY', value: 2 },
    { viewValue: 'RANGE.SELECT_ONE_WEEK', value: 7 },
    { viewValue: 'RANGE.SELECT_TWO_WEEK', value: 14 },
    { viewValue: 'RANGE.SELECT_ONE_MONTH', value: 31 },
    { viewValue: 'RANGE.SELECT_SIX_MONTH', value: 183 },
    { viewValue: 'RANGE.BETWEEN_TWO_DATES', value: 0 }];
export const ALERT_DURATIONS: Array<IMatTuple> = [
    { viewValue: 'RANGE.SELECT_ONE_WEEK', value: 7 },
    { viewValue: 'RANGE.SELECT_TWO_WEEK', value: 14 },
    { viewValue: 'RANGE.SELECT_ONE_MONTH', value: 31 },
    { viewValue: 'RANGE.SELECT_SIX_MONTH', value: 183 },
    { viewValue: 'RANGE.CURRENT_YEAR', value: dayNumberOfTheYear - 1 },
    { viewValue: 'RANGE.BETWEEN_TWO_DATES', value: 0 }
]
export interface IRange {
    startTimestamp: number;
    endTimestamp: number;
}

export interface IMatTuple<T=string | number> {
    viewValue: string;
    value: T;
}
/**
 * Get the timestamp of the last midnight of the @param date in current timezone
 */
export function dateToLastMidnight(date: Date | number = NOW) {
    const offsetDate: Date = new Date(date);
    return offsetDate.setHours(0, 0, 0, 0);
}

/**
 * Get the timestamp of the last hour of the @param date in current timezone
 */
export function dateToLastHour(date: Date | number = NOW) {
    const offsetDate: Date = new Date(date);
    return offsetDate.setMinutes(0, 0, 0);
}

/**
 * Get the timestamp of the next midnight of the @param date in current timezone
 */
export function dateToNextMidnight(date: Date | number = NOW) {
    const offsetDate: Date = new Date(date);
    return offsetDate.setHours(24, 0, 0, 0);
}

export function getAddressFromPlaceResult(place: PlaceResult) {
    const componentForm = {
        street_number: { type: 'short_name', value: 'houseNumber', data: null },
        route: { type: 'long_name', value: 'street', data: null },
        locality: { type: 'long_name', value: 'city', data: null },
        country: { type: 'short_name', value: 'countryCode', data: null },
        postal_code: { type: 'short_name', value: 'zip', data: null },
    };
    for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
            componentForm[addressType].data = place.address_components[i][componentForm[addressType].type];
        }
    }
    return componentForm;
}

export function addDay(daysToAdd: number, time: number): number {
    return (daysToAdd * 24 * 60) + time;
}

export const daysOfTheWeek: Array<{ number: number, i18nKey: string }> = [
    { number: 1, i18nKey: 'MONDAY' },
    { number: 2, i18nKey: 'TUESDAY' },
    { number: 3, i18nKey: 'WEDNESDAY' },
    { number: 4, i18nKey: 'THURSDAY' },
    { number: 5, i18nKey: 'FRIDAY' },
    { number: 6, i18nKey: 'SATURDAY' },
    { number: 0, i18nKey: 'SUNDAY' }
];

export function getDays() {
    return daysOfTheWeek.map(elem => elem.number);
}

export function getLiteralKey(number: number) {
    return 'COMMON.DAYS.' + daysOfTheWeek.find(data => data.number === number).i18nKey;
}

export function fromMinToString(number: number): { value: number, viewValue: string } {
    return {
        viewValue: Math.floor(number / 60).toString().padStart(2, '0') + ':' + (number % 60).toString().padStart(2, '0'),
        value: number
    };
}

export function getHoursListOfADay(step: number = 15): Array<{ value: number, viewValue: string }> {
    const time: Array<{ value: number, viewValue: string }> = [];
    for (let index = 0; index <= DAY_IN_MIN; index += step) {
        time.push(fromMinToString(index));
    }
    return time;
}

export function monthDiff(from:Date, to:Date) {
    return to.getMonth() - from.getMonth() +  (12 * (to.getFullYear() - from.getFullYear()))
}


export const booleanResponse: Array<{ value: boolean, viewValue: string }> = [
    { value: true, viewValue: 'COMMON.YES' },
    { value: false, viewValue: 'COMMON.NO' },
];

export const offsets = [
    { name: '-12', value: -12 },
    { name: '-11', value: -11 },
    { name: '-10', value: -10 },
    { name: '-9', value: -9 },
    { name: '-8', value: -8 },
    { name: '-7', value: -7 },
    { name: '-6', value: -6 },
    { name: '-5', value: -5 },
    { name: '-4', value: -4 },
    { name: '-3', value: -3 },
    { name: '-2', value: -2 },
    { name: '-1', value: -1 },
    { name: '0', value: 0 },
    { name: '+1', value: 1 },
    { name: '+2', value: 2 },
    { name: '+3', value: 3 },
    { name: '+4', value: 4 },
    { name: '+5', value: 5 },
    { name: '+6', value: 6 },
    { name: '+7', value: 7 },
    { name: '+8', value: 8 },
    { name: '+9', value: 9 },
    { name: '+10', value: 10 },
    { name: '+11', value: 11 },
    { name: '+12', value: 12 }
];

export const minAvailableDisplays = [
    { value: TIME_MODE.ANALOG, name: 'WATCH_HANDS' },
    { value: TIME_MODE.DIGITAL, name: 'WATCH_NUMERIC' }
];
