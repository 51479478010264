import { NgModule } from "@angular/core";
import { LoadingComponent } from "./loading/loading.component";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatBadgeModule } from "@angular/material/badge";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { DiscountCodeComponent } from "./discount-code/discount-code.component";
import { PhonePipe } from "./../../util/phone.pipe";
import { PhoneComponent } from "./phone/phone.component";
import { HighchartsChartModule } from "highcharts-angular";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AddressAutocompleteComponent } from "./address-autocomplete/address-autocomplete.component";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { CivilityRadioButtonComponent } from "./civility-radio-button/civility-radio-button.component";

const angularMaterialModules = [
    MatAutocompleteModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatTooltipModule,
];
const angularModules = [
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
];
@NgModule({
    imports: [
        angularModules,
        angularMaterialModules,
        TranslateModule,
        CommonModule,
        HighchartsChartModule,
        LoadingComponent,
    ],
    declarations: [
        DiscountCodeComponent,
        PhonePipe,
        PhoneComponent,
        AddressAutocompleteComponent,
        CivilityRadioButtonComponent,
    ],
    exports: [
        LoadingComponent,
        DiscountCodeComponent,
        TranslateModule,
        angularMaterialModules,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        PhonePipe,
        PhoneComponent,
        FlexLayoutModule,
        AddressAutocompleteComponent,
        PhoneComponent,
        CivilityRadioButtonComponent,
    ],
})
export class MainComponentModule { }
