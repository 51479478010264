import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { EntityService } from '../data/entity.service';
import { map } from 'rxjs/operators';
import { RedirectLink } from 'app/services/utilities/constants.service';
import { GeneralUser, GeneralGroup, IGeneralGroup, IGeneralUser } from '@co-assist/library';
enum LocalStorageKeys {
    loggedUser = 'loggedUser',
    loggedGroup = 'loggedGroup',
    loggedAdmin = 'loggedAdmin',
    loggedUserStatus = 'loggedUserStatus'
}
export enum AccessStatus {
    group_admin = 'group_admin',
    group_helper = 'group_helper',
    self = 'self',
    help = 'help',
    co_assist_admin = 'co_assist_admin',
    group_helped = 'group_helped'

}
@Injectable({
    providedIn: 'root'
})
export class LoggedUserStoreService {
    readonly currentLoggedUserStatus$: Observable<AccessStatus>;
    readonly loggedUser$: Observable<GeneralUser>;
    readonly loggedGroup$: Observable<GeneralGroup>;
    readonly loggedAdmin$: Observable<GeneralUser>;
    private readonly _loggedUserStatus = new BehaviorSubject<AccessStatus>(undefined);
    private readonly _loggedUser = new BehaviorSubject<GeneralUser>(undefined);
    private readonly _loggedGroup = new BehaviorSubject<GeneralGroup>(undefined);
    private readonly _loggedAdmin = new BehaviorSubject<GeneralUser>(undefined);

    constructor(
        private entityService: EntityService
    ) {
        this.currentLoggedUserStatus$ = this._loggedUserStatus.asObservable();
        this.loggedUser$ = this._loggedUser.asObservable();
        this.loggedGroup$ = this._loggedGroup.asObservable();
        this.loggedAdmin$ = this._loggedAdmin.asObservable();

        const storedLoggedUserStatus = localStorage.getItem(LocalStorageKeys.loggedUserStatus);
        if (storedLoggedUserStatus) {
            this._loggedUserStatus.next(JSON.parse(storedLoggedUserStatus) as AccessStatus);
        }
        const storedAdmin = localStorage.getItem(LocalStorageKeys.loggedAdmin);
        if (storedAdmin) {
            this._loggedAdmin.next(new GeneralUser(JSON.parse(storedAdmin)));
        }
        const storedUser = localStorage.getItem(LocalStorageKeys.loggedUser);
        if (storedUser) {
            this._loggedUser.next(new GeneralUser(JSON.parse(storedUser)));
        }
        const storedGroup = localStorage.getItem(LocalStorageKeys.loggedGroup);
        if (storedGroup) {
            this._loggedGroup.next(new GeneralGroup(JSON.parse(storedGroup)));
        }
    }
    setCoAssistAdmin(user: IGeneralUser, status: Array<string>): void {
        const COASSIST_ADMIN_STATUS: string = 'co_assist_admin';
        if (status.includes(COASSIST_ADMIN_STATUS)) {
            this._loggedAdmin.next(new GeneralUser(user));
            localStorage.setItem(LocalStorageKeys.loggedAdmin, JSON.stringify(this._loggedAdmin.getValue()));
        }
    }
    isCoAssistAdmin(): boolean {
        return !!this._loggedAdmin.getValue();
    }
    getCoAssistAdmin(): GeneralUser {
        return this._loggedAdmin.getValue() ? new GeneralUser(this._loggedAdmin.getValue()) : undefined;
    }
    getContentRoute(): string { return this.getCoAssistAdmin() ? RedirectLink.ADMIN : RedirectLink.FIRST_LOGIN; }
    getAdministrationRoute(): string { return RedirectLink.ADMIN; }
    getTelemetryRoute(): string { return RedirectLink.TELEMETRY; }
    getHubRoute(): string { return RedirectLink.HUB; }
    // Group
    getLoggedGroup(): GeneralGroup | undefined {
        return this._loggedGroup.getValue() ? new GeneralGroup(this._loggedGroup.getValue()) : undefined;
    }
    setLoggedGroup(group: IGeneralGroup): void {
        if (group) {
            this._loggedGroup.next(new GeneralGroup(group));
            localStorage.setItem(LocalStorageKeys.loggedGroup, JSON.stringify(this._loggedGroup.getValue()));
        }
    }
    updateLoggedGroup(data: { groupID: string, dataToUpdate: Partial<GeneralGroup> }): Observable<void> {
        const loggedGroup = this.getLoggedGroup();
        return this.entityService.updateGroup(data).pipe(
            map(() => {
                loggedGroup.update(data.dataToUpdate);
                this.setLoggedGroup(loggedGroup);
            }));
    }
    fetchGroup$(groupID: string): Observable<GeneralGroup> {
        return this.entityService.getGroup({ groupID }).pipe(
            map(group => {
                this.setLoggedGroup(group);
                return this.getLoggedGroup();
            })
        );
    }
    getAvailablesActionSetNames(): Array<{ id: string, name: string }> {
        const actionSetNames = this._loggedGroup.getValue().actionSetNames;
        return actionSetNames.map(tuple => {
            return { id: tuple.id, name: tuple.name }
        });
    }

    getActionSetNameByID(id: string): string {
        const actionSetNames = this._loggedGroup.getValue().actionSetNames;
        return actionSetNames.find(as => as.id === id)?.name ?? 'Profil par défaut';
    }
    addOrUpdateName$(id: string, name: string): Observable<boolean> {
        const actionSetNameToUpdate = this._loggedGroup.getValue().actionSetNames.find(as => as.id === id);
        if (actionSetNameToUpdate) {
            actionSetNameToUpdate.name = name;
        } else {
            this._loggedGroup.getValue().actionSetNames.push({ id, name })
        }
        const actionSetNames = this._loggedGroup.getValue().actionSetNames;
        return this.updateLoggedGroup({ groupID: this._loggedGroup.getValue().getGroupID(), dataToUpdate: { actionSetNames } }).pipe(
            map(_ => true)
        )
    }
    removeName$(id: string): Observable<boolean> {
        const actionSetNameToUpdate = this._loggedGroup.getValue().actionSetNames.filter(as => as.id !== id);
        return this.updateLoggedGroup({ groupID: this._loggedGroup.getValue().getGroupID(), dataToUpdate: { actionSetNames: actionSetNameToUpdate } })
            .pipe(
                map(_ => true)
            )
    }
    // User
    getLoggedUser(): GeneralUser | undefined {
        return this._loggedUser.getValue() ? new GeneralUser(this._loggedUser.getValue()) : undefined;
    }
    setLoggedUser(user: IGeneralUser, status?: Array<string>): void {
        this.setLoggedUserStatus(status);
        if (user) {
            this._loggedUser.next(new GeneralUser(user));
            localStorage.setItem(LocalStorageKeys.loggedUser, JSON.stringify(this._loggedUser.getValue()));
        }
    }
    updateLoggedUser(user: GeneralUser): Observable<void> {
        const loggedUser = this.getLoggedUser();
        return this.entityService.updateGeneralUser(user)
            .pipe(
                map(() => {
                    loggedUser.update(user);
                    this.setLoggedUser(loggedUser);
                }));
    }
    // LoggedUserStatus
    getLoggedUserStatus(): AccessStatus | undefined {
        return this._loggedUserStatus.getValue();
    }
    isLoggedUserAnHelper(): boolean {
        return [AccessStatus.group_helper, AccessStatus.help, AccessStatus.group_admin].includes(this.getLoggedUserStatus());
    }
    setLoggedUserStatus(status: Array<string>): void {
        if (Array.isArray(status)) {
            if (status.includes(AccessStatus.group_admin)) {
                this._loggedUserStatus.next(AccessStatus.group_admin);
            } else if (status.includes(AccessStatus.group_helped)) {
                this._loggedUserStatus.next(AccessStatus.group_helped);
            } else if (status.includes(AccessStatus.group_helper)) {
                this._loggedUserStatus.next(AccessStatus.group_helper);
            } else if (status.includes(AccessStatus.help)) {
                this._loggedUserStatus.next(AccessStatus.help);
            } else if (status.includes(AccessStatus.self)) {
                this._loggedUserStatus.next(AccessStatus.self);
            } else {
                this._loggedUserStatus.next(undefined);
            }
            localStorage.setItem(LocalStorageKeys.loggedUserStatus, JSON.stringify(this._loggedUserStatus.getValue()));
        }
    }
    deleteUser() {
        this._loggedUser.next(undefined);
        localStorage.removeItem(LocalStorageKeys.loggedUser);
    }
    clean() {
        this.resetLoggedUser();
        this.resetLoggedGroup();
        this._resetCoAssistAdmin();
        this._resetLoggedUserStatus();
    }
    resetLoggedUser() {
        this._loggedUser.next(undefined);
        localStorage.removeItem(LocalStorageKeys.loggedUser);
    }
    resetLoggedGroup() {
        this._loggedGroup.next(undefined);
        localStorage.removeItem(LocalStorageKeys.loggedGroup);
    }
    private _resetCoAssistAdmin() {
        this._loggedAdmin.next(undefined);
        localStorage.removeItem(LocalStorageKeys.loggedAdmin);
    }
    private _resetLoggedUserStatus() {
        this._loggedUserStatus.next(undefined);
        localStorage.removeItem(LocalStorageKeys.loggedUserStatus);
    }
}
