<app-page-layout [displayCustomerService]="false">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title class="center">
                            {{ 'PASSWORD_RESET.NEW_PASSWORD_FOR' | translate:{arg:loginID} }}
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="row">
                            <ng-container *ngIf="isLoading; else display">
                                <app-loading></app-loading>
                            </ng-container>
                            <ng-template #display>
                                <ng-container *ngIf="hasBeenReset; else displayForm">
                                    <ng-container *ngTemplateOutlet="successMessage"></ng-container>
                                </ng-container>
                                <ng-template #displayForm>
                                    <ng-container *ngTemplateOutlet="errorMessage"></ng-container>
                                    <form [formGroup]="form">
                                        <mat-form-field class="col-md-12">
                                            <mat-label>{{'COMMON.USER_NAME_PLACEHOLDER' | translate}}</mat-label>
                                            <input matInput formControlName="loginID" type="text"
                                                [placeholder]="'COMMON.USER_NAME_PLACEHOLDER' | translate" name="first">
                                            <mat-error>{{'VALIDATION.REQUIRED'|translate}}</mat-error>
                                        </mat-form-field>
                                        <div formGroupName="passwordGroup">
                                            <mat-form-field class="col-md-12">
                                                <input name="password" matInput autocomplete="new-password"
                                                    type="password" formControlName="password"
                                                    [placeholder]="'REGISTRATION.STEP.ACCOUNT_CREATION.PASSWORD' | translate"
                                                    class="full-width" [type]="hide ? 'password' : 'text'">
                                                <mat-icon matSuffix (click)="hide = !hide">
                                                    {{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                                                <mat-error>{{ getPasswordError() | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field class="col-md-12">
                                                <input name="confirmPassword" matInput autocomplete="new-password"
                                                    type="password" formControlName="confirmPassword"
                                                    [errorStateMatcher]="matcher"
                                                    [placeholder]="'REGISTRATION.STEP.ACCOUNT_CREATION.CONFIRM_PASSWORD' | translate"
                                                    class="full-width" [type]="hide ? 'password' : 'text'">
                                                <mat-icon matSuffix (click)="hide = !hide">
                                                    {{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                                                <mat-error>
                                                    {{errors.confirmPassword | translate}}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-12">
                                            <button mat-raised-button color="primary" class="full-width"
                                                [disabled]="isLoading" (click)="submit()">
                                                {{'COMMON.CONFIRM' | translate}}
                                            </button>
                                        </div>
                                    </form>
                                </ng-template>
                            </ng-template>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</app-page-layout>
<ng-template #errorMessage>
    <div class="col-md-12" *ngIf="errorMatcher.isError()">
        <div class="alert custom-danger text-left" role="alert">
            <p class="error">
                <mat-icon class="danger">error</mat-icon>
                <span *ngFor="let error of errorMatcher.logError">{{error.message | translate:{arg:error.arg} }}</span>
            </p>
        </div>
    </div>
</ng-template>
<ng-template #successMessage>
    <div class="col-md-12">
        <div class="alert alert-success" role="alert">
            <span>{{'PASSWORD_RESET.RESET_SUCCESS' |translate }}</span>
            <p> {{'COMMON.GO_TO_LOGIN' | translate }}</p>
        </div>
    </div>
</ng-template>
