import { ModuleStatesInfo } from './../../models/Module';
import { Injectable } from '@angular/core';
import { Constants, Entity, ActionSet, GeneralUser, GeneralGroup, Group, GeneralModule, ModuleSetting, GeneralGh5200, ALERT, GeneralEv07b, Event } from '@co-assist/library';
import { Observable, BehaviorSubject, of, combineLatest, forkJoin, Cons } from 'rxjs';
import { Module } from 'app/models/Module';
import { map, switchMap } from 'rxjs/operators';
import { ModuleService } from 'app/services/data/module.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LoggedUserStoreService } from './logged-user-store.service';
import { ActionSetStoreService } from './action-set-store.service';
enum LocalStorageKeys {
    modules = 'modules'
}
@Injectable({
    providedIn: 'root'
})
export class ModuleStoreService {
    readonly modules$: Observable<Array<Module>>;
    private readonly _modules = new BehaviorSubject<Array<Module>>([]);

    constructor(
        private moduleService: ModuleService,
        private actionSetStoreService: ActionSetStoreService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private loggedUserStoreService: LoggedUserStoreService
    ) {
        this.modules$ = this._modules.asObservable();
    }

    getModule(moduleID: GeneralModule['moduleID']): Module {
        return this._modules.getValue().find(module => module.getModuleID() === moduleID)
    }
    getModule$(moduleID: GeneralModule['moduleID']): Observable<Module> {
        return this.modules$.pipe(
            map(modules => modules.find(m => m.getModuleID() === moduleID))
        )
    }
    getModules(moduleIDs: Array<GeneralModule['moduleID']>): Array<Module> {
        return this._modules.getValue()?.filter(m => moduleIDs.includes(m.getModuleID()));
    }
    getAllModules(): Array<Module> {
        return this._modules.getValue();
    }
    getAllModules$(): Observable<Array<Module>> {
        return this.modules$;
    }
    getModuleByUserID(userID: GeneralUser['userID']): Array<Module> {
        return this._modules.getValue().filter(module => module.getWearer() === userID)
    }
    getModulesByWearer(userID: string, deviceType?: Constants.Entity.DeviceType): Array<Module> {
        return this._modules.getValue().filter(module => module.getWearer() === userID && module.getDeviceType() === deviceType);
    }
    getModulesByWearer$(userID: string, deviceType?: Constants.Entity.DeviceType): Observable<Array<Module>> {
        return this.modules$.pipe(
            map(modules => modules.filter(module => module.getWearer() === userID && (deviceType ? module.getDeviceType() === deviceType : true))));
    }
    getModulesAndStatesByWearer(userID: GeneralUser['userID'], deviceType?: Constants.Entity.DeviceType): Observable<Array<Module>> {
        const modules = this._modules.getValue()
            .filter(module => module.getWearer() === userID && (deviceType ? module.getDeviceType() === deviceType : true))
            .map(module => module.getModuleID());
        return this.fetchModulesState(modules).pipe(
            switchMap(_ => this.getModulesByWearer$(userID, deviceType))
        );
    }
    getLastValueState(moduleID: string): Observable<Event.Module.ModuleStateResponse['lastValue']> {
        return this.modules$.pipe(
            switchMap(modules => {
                const module = modules.find(m => m.getModuleID() === moduleID);
                if (module && (module.states as any)?.lastValue) { return of((module.states as any)?.lastValue) }
                else { return this.fetchLastValueState(module.getModuleID(), module.getDeviceType()) }
            })
        )
    }
    fetchLastValueState(moduleID: string, deviceType: Constants.Entity.DeviceType): Observable<Event.Module.ModuleStateResponse['lastValue']> {
        return this.moduleService.getModuleState({ moduleID, deviceType }).pipe(
            map(states => {
                const modules = this._modules.getValue();
                modules.find(m => m.getModuleID() === moduleID).setStates(states);
                this._modules.next(modules);
                return states['lastValue'];
            })
        )
    }
    setModules(modules: Array<Module>) {
        const dbCopy = this._modules.getValue();
        modules.forEach(mod => {
            const index = dbCopy.findIndex(m => m.getModuleID() === mod.getModuleID());
            if (index >= 0) {
                dbCopy[index] = new Module(mod);
            } else {
                dbCopy.push(new Module(mod));
            }
        });
        this._modules.next(dbCopy);
    }
    getModulesByDeviceType(deviceType: Entity.DeviceType): Observable<Array<Module>> {
        return this.modules$.pipe(
            map(modules => modules.filter(module => module.getDeviceType() === deviceType)));
    }

    getModulesByDeviceTypes(deviceTypes: Array<Entity.DeviceType>): Observable<Array<Module>> {
        return this.modules$.pipe(
            map(modules => modules.filter(module => deviceTypes.includes(module.getDeviceType()))));
    }

    getDeviceTypes(): Observable<Array<Entity.DeviceType>> {
        return this.modules$.pipe(
            map(modules => {
                const deviceTypes: Array<Entity.DeviceType> = [];
                Object.values(Constants.Entity.DeviceType).forEach(deviceType => {
                    if (modules.find(m => m.getDeviceType() === deviceType)) {
                        deviceTypes.push(deviceType);
                    }
                });
                return deviceTypes;
            })
        );
    }

    getMapSettingTypeDeviceTypes(): Observable<Array<Group.Setting>> {
        return of(this.getDeviceProfile(Constants.Entity.DeviceType.CAW).availableSettings); //.filter(s => s !== Group.Setting.REAL_TIME_TRACKER));
    }

    getMapAlertTypeDeviceTypes(): Observable<{ [key in Constants.Alert.AlertType]: Array<Constants.Entity.DeviceType> }> {
        return this.getDeviceTypes().pipe(
            map(deviceTypes => {
                const mappingDeviceTypeAlertTypes: any /*{ [key in Constants.Alert.AlertType]: Array<Constants.Entity.DeviceType> }*/ = {};
                for (let deviceType of deviceTypes) {
                    let deviceProfile = this.getDeviceProfile(deviceType);
                    let alertTypes = deviceProfile.availableAlerts;
                    alertTypes = alertTypes.filter(alertType => !deviceProfile.filteredB2BConfigAlerts?.includes(alertType));
                    for (let alertType of alertTypes) {
                        if (!mappingDeviceTypeAlertTypes[alertType]) {
                            mappingDeviceTypeAlertTypes[alertType] = [deviceType];
                        } else {
                            mappingDeviceTypeAlertTypes[alertType].push(deviceType);
                        }
                    }
                }
                return mappingDeviceTypeAlertTypes;
            })
        );
    }

    getAssociatedModules(): Observable<Array<Module>> {
        return this.modules$.pipe(
            map(modules => modules.filter(module => module.getWearer())));
    }

    getModuleToBeCharged() {
        return this.getAssociatedModules().pipe(
            map(modules => {
                return modules
                    .filter(m => m.getDeviceType() === Constants.Entity.DeviceType.CAW && (m.states?.batteryLevel?.value as number) <= 15)
                    .map(m => ({ moduleID: m.getModuleID(), userID: m.getWearer(), batteryLevel: m.states.batteryLevel.value, lastUpdate: m.states.batteryLevel.lastUpdate }))
                    .sort((a, b) => (b.batteryLevel as number) - (a.batteryLevel as number));
            })
        );
    }

    getFreeModules(): Observable<Array<Module>> {
        return this.modules$.pipe(
            map(modules => modules.filter(module => !module.getWearer())));
    }
    getFunctionnalAssociatedModules(): Observable<Array<Module>> {
        return this.modules$.pipe(
            map(modules => modules.filter(m => m.isFunctionnal() && m.getWearer())
            ));
    }
    async fetchModulesByUserID(userID: string, deviceType?: Constants.Entity.DeviceType): Promise<void> {
        this.setModules((await this.moduleService.getModulesByWearer({ userID, deviceType }).toPromise())?.map(m => new Module(m)) || []);
    }
    fetchModulesByUserID$(userID: string, deviceType?: Constants.Entity.DeviceType): Observable<void> {
        return this.moduleService.getModulesByWearer({ userID, deviceType }).pipe(
            map(modules => this.setModules(modules.map(m => new Module(m)))
            )
        );
    }
    async fetchModulesByGroupID(groupID: string, deviceType?: Constants.Entity.DeviceType, update: boolean = true): Promise<void> {
        const modulesAlreadyPopulate: boolean = this.getAllModules().length > 0;
        if (modulesAlreadyPopulate && !update) { return; }
        this.setModules((await this.moduleService.getModulesByGroupID({ groupID, deviceType }).toPromise())?.map(m => new Module(m)) || []);
    }
    fetchModulesByGroupID$(groupID: string, deviceType?: Constants.Entity.DeviceType, update: boolean = true): Observable<void> {
        const modulesAlreadyPopulate: boolean = this.getAllModules().length > 0;
        if (modulesAlreadyPopulate && !update) { return; }
        return this.moduleService.getModulesByGroupID({ groupID, deviceType }).pipe(
            map(modules => this.setModules(modules.map(m => new Module(m)))
            )
        );
    }

    sortAlertSettings(moduleID: string, deviceType: Entity.DeviceType, settings: any[] = []): Array<ActionSet> {
        const alertTypes: Array<ALERT.AlertType> = this.getDeviceProfile(deviceType).availableAlerts.filter(alert => !this.getDeviceProfile(deviceType).stickyAlerts.includes(alert));
        let sortedSettings: Array<ActionSet> = [];
        alertTypes.forEach((alertType: ALERT.AlertType) => {
            let itemSorted = settings.filter((item: any) => item.alertType === alertType);
            if (itemSorted.length) {
                sortedSettings = sortedSettings.concat(itemSorted);
            } else {
                sortedSettings.push(this.actionSetStoreService.generateNewActionSet(moduleID, alertType));
            }
        });
        return sortedSettings;
    }

    fetchModulesState(moduleIDs: Array<string>, update: boolean = true): Observable<boolean> {
        const _modules = this.getAllModules();
        const unWantedModuleIDs = _modules.filter(m => !this.getDeviceProfile(m.getDeviceType()).isAssociableToUser).map(m => m.getModuleID());
        const modules = moduleIDs.filter(moduleID => !unWantedModuleIDs.includes(moduleID))
            .map(id => _modules.find(m => m.getModuleID() === id));
        const alreadyHaveModuleState: boolean = modules.filter(m => m.states).length === modules.length;
        if (!update && alreadyHaveModuleState) { return of(true); }
        return modules.length ? combineLatest(
            modules
                .filter(m => m.getDeviceType() !== Constants.Entity.DeviceType.BSSigfox)
                .map(m => this.moduleService.getModuleState({ moduleID: m.getModuleID(), deviceType: m.getDeviceType() }))
        ).pipe(
            map((modulesState: Array<ModuleStatesInfo>) => {
                modules.map((module, index) => {
                    module.states = modulesState[index];
                });
                this._modules.next(
                    _modules
                        .map(m => modules.find(mod => mod.getModuleID() === m.getModuleID()) || m));
                return true;
            })
        ) : of(true);
    }

    assignModule(moduleID: string, userID: string): Observable<void> {
        return this.moduleService.assignModule({ moduleID, userID }).pipe(
            map(() => {
                this.setModules(this.getAllModules().map(m => {
                    if (m.getModuleID() === moduleID) {
                        m.assign(userID);
                    }
                    return m;
                }));
                this.toastr.success(this.translate.instant('GROUP_ASSOCIATIONS.TOAST.ASSIGN.SUCCESSFUL_ASSIGNMENT'));
            }));
    }

    releaseModules(userID: GeneralUser['userID']): Observable<boolean> {
        const associatedModules: Array<Observable<void>> = this.getAllModules()
            .filter(m => m.getWearer() === userID)
            .map(m => this.moduleService.releaseModule({ moduleID: m.getModuleID(), deviceType: m.getDeviceType() }));
        return associatedModules.length ? combineLatest(associatedModules).pipe(
            map(() => {
                this.toastr.success(this.translate.instant('GROUP_ASSOCIATIONS.TOAST.ASSIGN.SUCCESSFUL_UNASSIGNMENT'));
                return true;
            })
        ) : of(true);
    }

    copySettings(userID: GeneralUser['userID'], moduleID: GeneralModule['moduleID'], deviceTypes: Array<GeneralModule['deviceType']>, copySettingsFrom: GeneralUser['userID'] | GeneralGroup['groupID'], moduleIDSource?: GeneralModule['moduleID']) {
        const settings = this.settingsListCompute(deviceTypes);
        let groupSettingsToUpdate = [];
        if (userID !== copySettingsFrom) {
            groupSettingsToUpdate = settings.map(({ name }: { name: string, label: string }) => name);
        }
        return this.actionSetStoreService.copySettings(copySettingsFrom, deviceTypes, groupSettingsToUpdate, [userID], [moduleID], moduleIDSource);
    }

    releaseModule(userID: string, moduleID: GeneralModule['moduleID'], saveConfiguration: boolean = false) {
        const module = this.getAllModules().find(m => m.getWearer() === userID && m.getModuleID() === moduleID);
        if (module) {
            (saveConfiguration ? this.actionSetStoreService.backupActionSetsRemovingOlders(userID, moduleID) : of([]))
                .subscribe(() => {
                    this.moduleService.releaseModule({ moduleID: module.getModuleID(), deviceType: module.getDeviceType() }).subscribe(() => {
                        this.setModules(this.getAllModules().map(m => {
                            if (m.getModuleID() === module.getModuleID()) {
                                m.release();
                            }
                            return m;
                        }));
                        this.toastr.success(this.translate.instant('GROUP_ASSOCIATIONS.TOAST.ASSIGN.SUCCESSFUL_UNASSIGNMENT'));
                    });
                });
        }
    }

    externalProbePlugged(moduleID: GeneralModule['moduleID'], deviceType: GeneralModule['deviceType'], state: boolean): Observable<void> {
        if (deviceType === Entity.DeviceType.LHT65NLR)
            return this.updateGeneralModule({ moduleID, deviceType }, { onExternalProbe: state });
    }

    activateModule(data: { moduleID: GeneralModule['moduleID'], deviceType: GeneralModule['deviceType'] }): Observable<void> {
        return this.updateGeneralModule(data, { inactive: false });
    }

    private updateGeneralModule(device: { moduleID: GeneralModule['moduleID'], deviceType: GeneralModule['deviceType'] }, data: Object): Observable<void> {
        //@ts-ignore
        return this.moduleService.updateGeneralModule({ ...device, ...data }).pipe(
            map(() => {
                this.setModules(this.getAllModules().map(m => {
                    if (m.getModuleID() === device.moduleID) {
                        m.updateGeneral(data);
                    }
                    return m;
                }));
                this.toastr.success(this.translate.instant('COMMON.TOAST.SUCCESS_TITLE'));
            })
        );
    }

    /**
     * @param displaySuccessToast set to false to avoid success toast spam
     */
    updateModuleSetting(moduleID: string, settingName: Constants.Module.SettingName, settingValue: any, displaySuccessToast = true): Observable<void> {
        return this.moduleService.updateModuleSetting({
            moduleID,
            name: settingName,
            value: settingValue,
            updatedToModule: false
        }).pipe(
            map(() => {
                this.updateInternalModuleSetting(moduleID, settingName, settingValue, displaySuccessToast);
            })
        );
    }
    updateInternalModuleSetting(moduleID: string, settingName: Constants.Module.SettingName, settingValue: any, displaySuccessToast = true): void {
        this.setModules(this.getAllModules().map(m => {
            if (m.getModuleID() === moduleID) {
                const setting = m.settings.find(setting => setting.name === settingName);
                setting.value = settingValue;
                setting.updatedToModule = false;
            }
            return m;
        }));
        if (displaySuccessToast) {
            this.toastr.success(this.translate.instant('COMMON.TOAST.SUCCESS_TITLE'));
        }
    }
    resetCAWModule(group: GeneralGroup, module: Module): Observable<void[]> {
        let clockModeValue = 0;
        let dateModeValue = 0;
        if (group.time_mode === Group.TimeMode.DIGITAL) {
            clockModeValue = 1;
            dateModeValue = 0;
        } else if (group.time_mode === Group.TimeMode.DIGITAL_DDMM) {
            clockModeValue = 1;
            dateModeValue = 1;
        }
        const settingsToUpdate: Array<ModuleSetting> = module.settings.reduce((settings, setting) => {
            switch (setting.name) {
                case Constants.Module.settingByName.clock_mode_analogDigit.name:
                    settings.push({ ...setting, value: clockModeValue, updatedToModule: false });
                    break;
                case Constants.Module.settingByName.date_mode.name:
                    settings.push({ ...setting, value: dateModeValue, updatedToModule: false });
                    break;
                case Constants.Module.settingByName.utc_time_zone.name:
                    settings.push({ ...setting, value: group.utc_time_zone, updatedToModule: false });
                    break;
                case Constants.Module.settingByName.real_time_tracking.name:
                case Constants.Module.settingByName.button.name:
                case Constants.Module.settingByName.fall.name:
                    settings.push({ ...setting, value: 0, updatedToModule: false });
                    break;
            }
            return settings;
        }, []);
        return forkJoin(settingsToUpdate.map(s =>
            this.updateModuleSetting(s.moduleID, s.name, s.value, false))
        );
    }

    clean() {
        this._resetModules();
    }

    getDeviceTypesByAlertType(alertType: Constants.Alert.AlertType): Observable<Array<Entity.DeviceType>> {
        return this.getDeviceTypes().pipe(
            map(deviceTypes => {
                return deviceTypes.filter(d => this.getDeviceProfile(d).availableAlerts.includes(alertType));
            })
        );
    }

    getDisctinctAlertTypes(filteredB2BConfigAlerts?: boolean): Array<Constants.Alert.AlertType> {
        return this.getDistinctDeviceTypes().reduce((acc, deviceType) => {
            const deviceProfile = this.getDeviceProfile(deviceType);
            let alertTypes = deviceProfile.availableAlerts;
            if (filteredB2BConfigAlerts) {
                alertTypes = alertTypes.filter(alertType => !deviceProfile.filteredB2BConfigAlerts?.includes(alertType));
            }
            alertTypes.forEach(alertType => {
                if (!acc.includes(alertType)) {
                    acc.push(alertType);
                }
            });
            return acc;
        }, [])
    }

    getDistinctDeviceTypes(): Array<Entity.DeviceType> {
        return this.getAllModules().reduce((acc, module) => {
            if (!acc.includes(module.getDeviceType())) {
                acc.push(module.getDeviceType());
            }
            return acc;
        }, [])
    }

    getDistinctDeviceTypesWithAlerts(): Array<Entity.DeviceType> {
        return this.getDistinctDeviceTypes().reduce((acc, deviceType) => {
            const deviceProfile = this.getDeviceProfile(deviceType);
            if (deviceProfile.isAlertConfigurationEnable && deviceProfile.availableAlerts.length) {
                acc.push(deviceType);
            }
            return acc;
        }, [])
    }

    getDeviceProfile(deviceType: Entity.DeviceType, moduleGeneral?: GeneralModule) {
        const unavailableAlerts = this.loggedUserStoreService.getLoggedGroup()?.unavailableAlerts ?? [];
        const unavailableModuleSettings = this.loggedUserStoreService.getLoggedGroup()?.unavailableModuleSettings ?? [];
        const groupAcknowledgeableAlerts = this.loggedUserStoreService.getLoggedGroup()?.acknowledgeableAlerts ?? [];
        switch (deviceType) {
            case Entity.DeviceType.CAW:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: true,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.CAW, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.CAW, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.CAW, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.CAW)
                };
            case Entity.DeviceType.CAB:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.CAB, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.CAB, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.CAB, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.CAB)
                };
            case Entity.DeviceType.BSSigfox:
                return {
                    isAssociableToUser: false,
                    isAlertConfigurationEnable: false,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.BSSigfox, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.BSSigfox, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.BSSigfox, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.BSSigfox)
                };
            case Entity.DeviceType.BSLora:
                return {
                    isAssociableToUser: false,
                    isAlertConfigurationEnable: false,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.BSLora, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.BSLora, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.BSLora, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.BSLora)
                };
            case Entity.DeviceType.SP4MAG:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.SP4MAG, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.SP4MAG, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.SP4MAG, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.SP4MAG)
                };
            case Entity.DeviceType.SP4BUT:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.SP4BUT, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.SP4BUT, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.SP4BUT, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.SP4BUT)
                };
            case Entity.DeviceType.I870SF:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.I870SF, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.I870SF, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.I870SF, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.I870SF)
                };
            case Entity.DeviceType.I870LR:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.I870LR, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.I870LR, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.I870LR, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.I870LR)
                };
            case Entity.DeviceType.GH5200:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: true,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.GH5200, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.GH5200, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: (moduleGeneral as GeneralGh5200)?.phoneNumber ? [ALERT.AlertType.BUTTON_CALL] : [],
                    stickySettings: [Group.Setting.REAL_TIME_TRACKER],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.GH5200, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.GH5200)
                };
            case Entity.DeviceType.TMT250:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: true,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.TMT250, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.TMT250, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [Group.Setting.REAL_TIME_TRACKER],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.TMT250, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.TMT250)
                };
            case Entity.DeviceType.EV07B:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: true,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.EV07B, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.EV07B, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: (moduleGeneral as GeneralEv07b)?.phoneNumber ? [ALERT.AlertType.BUTTON_CALL] : [],
                    stickySettings: [Group.Setting.REAL_TIME_TRACKER],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.EV07B, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.EV07B)
                };
            case Entity.DeviceType.SMARTPHONE:
                return {
                    isAssociableToUser: false,
                    isAlertConfigurationEnable: false,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.SMARTPHONE, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.SMARTPHONE, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.SMARTPHONE, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.SMARTPHONE)
                };
            case Entity.DeviceType.CAU:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.CAU, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.CAU, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.CAU, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.CAU)
                };
            case Entity.DeviceType.CALAU:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.CALAU, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.CALAU, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.CALAU, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.CALAU)
                };
            case Entity.DeviceType.ARF8171BA:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.ARF8171BA, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.ARF8171BA, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.ARF8171BA, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.ARF8171BA)
                };
            case Entity.DeviceType.ARF8171BADOOR:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.ARF8171BADOOR, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.ARF8171BADOOR, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.ARF8171BADOOR, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.ARF8171BADOOR)
                };
            case Entity.DeviceType.ARF8171BASMOKE:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.ARF8171BASMOKE, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.ARF8171BASMOKE, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.ARF8171BASMOKE, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.ARF8171BASMOKE)
                };
            case Entity.DeviceType.CALDC:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.CALDC, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.CALDC, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT, Constants.Module.STATETYPE.DC_STATE],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.CALDC, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.CALDC)
                };
            case Entity.DeviceType.CALDCSMOKE:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.CALDCSMOKE, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.CALDCSMOKE, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT, Constants.Module.STATETYPE.DC_STATE],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.CALDCSMOKE, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.CALDCSMOKE)
                };
            case Entity.DeviceType.CALDS:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.CALDS, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.CALDS, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.CALDS, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.CALDS)
                };
            case Entity.DeviceType.CALSB:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.CALSB, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.CALSB, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.CALSB, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.CALSB)
                };
            case Entity.DeviceType.LDS02DS:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.LDS02DS, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.LDS02DS, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.LDS02DS, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.LDS02DS)
                };
            case Entity.DeviceType.LDS02SB:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.LDS02SB, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.LDS02SB, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.LDS02SB, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.LDS02SB)
                };
            case Entity.DeviceType.VH2BBUS01:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.VH2BBUS01, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.VH2BBUS01, unavailableModuleSettings),
                    filteredB2BConfigAlerts: [ALERT.AlertType.ZONE_EXIT],
                    availableStates: [Constants.Module.STATETYPE.CONNECTIVITY, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.VH2BBUS01, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.VH2BBUS01),
                    inOutLocalisation: true
                };
            case Entity.DeviceType.LA66USB:
                return {
                    isAssociableToUser: false,
                    isAlertConfigurationEnable: false,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.LA66USB, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.LA66USB, unavailableModuleSettings),
                    availableStates: [],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.LA66USB, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.LA66USB)
                };
            case Entity.DeviceType.CALP:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.CALP, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.CALP, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.CALP, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.CALP)
                };
            case Entity.DeviceType.CALT:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.CALT, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.CALT, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.CALT, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.CALT)
                };
            case Entity.DeviceType.CALWB:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.CALWB, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.CALWB, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.BATTERY_LEVEL, Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.CALWB, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.CALWB)
                };
            case Entity.DeviceType.ZEPRLR:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.ZEPRLR, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.ZEPRLR, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.ZEPRLR, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.ZEPRLR)
                };
            case Entity.DeviceType.ZEECDMINOLR:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.ZEECDMINOLR, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.ZEECDMINOLR, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.ZEECDMINOLR),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.ZEECDMINOLR)
                };
            case Entity.DeviceType.LHT65NLR:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.LHT65NLR, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.LHT65NLR, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.LHT65NLR, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.LHT65NLR)
                };
            case Entity.DeviceType.X865LS:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.X865LS, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.X865LS, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.X865LS, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.X865LS)
                };
            case Entity.DeviceType.X855LS:
                return {
                    isAssociableToUser: true,
                    isAlertConfigurationEnable: true,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.X855LS, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.X855LS, unavailableModuleSettings),
                    availableStates: [Constants.Module.STATETYPE.IN_DEFAULT],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.X855LS, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.X855LS)
                };
            case Entity.DeviceType.NETWORKDEVICE:
                return {
                    isAssociableToUser: false,
                    isAlertConfigurationEnable: false,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: Constants.Module.getAlertTypes(Entity.DeviceType.NETWORKDEVICE, unavailableAlerts),
                    availableSettings: Constants.Module.getConfigurableSettings(Entity.DeviceType.NETWORKDEVICE, unavailableModuleSettings),
                    availableStates: [],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: Constants.Module.getAcknowledgeableAlertTypes(Entity.DeviceType.NETWORKDEVICE, groupAcknowledgeableAlerts),
                    delayableAlerts: Constants.Module.getDelayableAlertTypes(Entity.DeviceType.NETWORKDEVICE)
                };
            default:
                return {
                    isAssociableToUser: false,
                    isAlertConfigurationEnable: false,
                    isDeviceConfigurationEnable: false,
                    availableAlerts: [],
                    availableSettings: [],
                    stickyAlerts: [],
                    stickySettings: [],
                    acknowledgeableAlert: [],
                    delayableAlerts: []
                };
        }
    }

    settingsListCompute(deviceTypes: Array<Entity.DeviceType>): Array<{ name: string, label: string }> {
        let alertsAndSettings: Array<{ name: string, label: string }> = [];
        for (let deviceType of deviceTypes) {
            const deviceProfile = this.getDeviceProfile(deviceType);
            deviceProfile.availableAlerts.forEach(type => alertsAndSettings.push({ name: type, label: type }));
            deviceProfile.availableSettings.forEach(type => alertsAndSettings.push({ name: type, label: 'DEVICES.SETTINGS.' + type }));
        }
        return alertsAndSettings;
    }

    private _resetModules() {
        this._modules.next([]);
        localStorage.removeItem(LocalStorageKeys.modules);
    }
}

