import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'app/services/utilities/cookie.service';

@Injectable({
    providedIn: 'root'
})
export class UpdateService {
    constructor(
        private swUpdate: SwUpdate,
        private snackbar: MatSnackBar,
        private translate: TranslateService,
        private cookie: CookieService
    ) {
        this.swUpdate.versionUpdates.subscribe(evt => {
            switch (evt.type) {
                case 'VERSION_DETECTED':
                    console.log(`Downloading new app version: ${evt.version.hash}`);
                    break;
                case 'VERSION_READY':
                    console.log(`Current app version: ${evt.currentVersion.hash}`);
                    console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
                    this.snackbar.open(
                        this.translate.instant('COMMON.NEED_UPDATE'),
                        this.translate.instant('COMMON.APPLY')
                    ).onAction().subscribe(() => {
                        // clear SubscriptionInfos
                        if (this.cookie.get('registerState')) {
                            this.cookie.delete('registerState');
                        }
                        window.location.reload();
                    });
                    break;
                case 'VERSION_INSTALLATION_FAILED':
                    console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                    break;
            }
        });
        // Event emitted when the version of the app used is in a broken state that cannot be recovered
        this.swUpdate.unrecoverable.subscribe(_ => {
            this.snackbar.open(
                this.translate.instant('COMMON.NEED_REFRESH')
            );
            // clear SubscriptionInfos
            if (this.cookie.get('registerState')) {
                this.cookie.delete('registerState');
            }
            setTimeout(() => {
                window.location.reload();
            }, 10000);
        });
    }
}
