import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-dialog-confirmation',
    templateUrl: 'dialog-confirmation.component.html',
    styleUrls: ['dialog-confirmation.component.scss']
})
export class DialogConfirmationComponent implements OnInit, AfterViewInit {
    titleDialog: any;
    textDialog: any;
    isConfirmation: boolean;
    isQuestion: boolean;
    isJustified: boolean;
    width: number;
    private bodyWidth: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogConfirmationComponent>
    ) { }

    ngOnInit() {
        this.titleDialog = this.data.title;
        this.textDialog = this.data.content;
        this.isConfirmation = this.data.isConfirmation;
        this.isQuestion = this.data.isQuestion ?? false;
        this.isJustified = this.data.isJustified ?? true;
        this.backgroundAutoSize();
    }

    ngAfterViewInit() {
        this.backgroundAutoSize();
    }

    backgroundAutoSize() {
        const additionalWidth = this.data.larger ? 200 : 0;
        this.bodyWidth = (document.body.offsetWidth);
        if (this.bodyWidth < 1270 && this.bodyWidth >= 800) {
            this.width = 400 + additionalWidth;
        } else if (this.bodyWidth < 800 && this.bodyWidth >= 600) {
            this.width = 300 + additionalWidth;
        } else if (this.bodyWidth < 600 && this.bodyWidth >= 400) {
            this.width = 200 + additionalWidth;
        } else if (this.bodyWidth < 400) {
            this.width = 200 + additionalWidth;
        } else {
            this.width = 400 + additionalWidth;
        }
    }
}
